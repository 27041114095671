/**
 * Breadcrumb organism
 */

.o-breadcrumb {
    $block: &;

    position: relative;

    &--greenBackground {
        color: color('white');
    }

    &::before,
    &::after {
        bottom: 0;
        content: '';
        display: block;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity speed() easing();
        width: gutter();
        z-index: 1;
    }

    &::before {
        background: linear-gradient(to right, rgba(color('white'), 1), rgba(color('white'), 0));
        left: 0;
    }

    .o-propertyTypeHero &::before,
    &--greenBackground::before {
        background: linear-gradient(to right, rgba(color('primary'), 1), rgba(color('primary'), 0)) !important;
    }

    &::after {
        background: linear-gradient(to left, rgba(color('white'), 1), rgba(color('white'), 0));
        right: 0;
    }

    .o-propertyTypeHero &::after,
    &--greenBackground::after {
        background: linear-gradient(to left, rgba(color('primary'), 1), rgba(color('primary'), 0)) !important;
    }

    &.isOverflownLeft {
        &::before {
            opacity: 1;
        }
    }

    &.isOverflownRight {
        &::after {
            opacity: 1;
        }
    }

    &__wrapper {
        overflow-x: auto;
    }

    &__list {
        display: inline-flex;
        flex-wrap: nowrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__listItem {
        align-items: center;
        display: flex;
        margin: 0;
        white-space: nowrap;
    }

    &__link {
        text-decoration: none;

        #{$block}--greenBackground & {
            color: color('white');
        }
    }

    &__text {
        color: color('secondary');
    }
}
