/**
 * Property Type List organism
 */

.o-propertyTypeList {
    display: flex;
    margin-bottom: 18rem;
    width: 100%;

    &__card {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: gutter();
        }
    }

    &__fallback {
        margin-bottom: gutter('xxxl');
    }

    &__fallbackDescription {
        margin-bottom: gutter('lg');
    }

    &__list {
        margin-bottom: -18rem;
    }

    &__seeAll {
        display: flex;
    }

    &__wrapper {
        margin-bottom: -18rem;
    }

    &__seeAllButton {
        margin-left: auto;

        @include breakpoint('md', 'down') {
            text-align: center;
            width: 100%;
        }

        &--alignLeft {
            margin-left: 0;
        }
    }
}
