/**
 * Mixins
 */

@mixin aspect-ratio($width, $height) {
    $aspect: ($height / $width * 100);

    padding-bottom: #{$aspect + '%'};
}

@mixin breakpoint($breakpoint, $direction: 'up') {
    @if ($direction == 'up') {
        @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    } @else if ($direction == 'down') {
        @media (max-width: #{map-get-next($breakpoints, $breakpoint) - 0.02}) {
            @content;
        }
    } @else {
        @error 'ERROR: The specified "direction" value is not supported for the breakpoint mixin.';
    }
}

@mixin owl($measure: 2rem) {
    & > * + * {
        margin-top: $measure;
    }
}

@mixin lora() {
    font-family: font-family('secondary');
    font-weight: font-weight('regular');
}

@mixin breakpoint-max-width($breakpoint, $direction: 'down') {
    @include breakpoint($breakpoint, $direction) {
        margin: 0 auto;
        max-width: map-get($grid-containers, $breakpoint);
        padding: 0 #{$grid-gutter / 2};
        width: 100%;
    }
}

@mixin hover-focus {
    &:hover {
        @media (hover: hover) {
            @content;
        }
    }
    &:focus-visible {
        @content;
    }
}

@mixin focus {
    &:focus-visible {
        @content;
    }
}
