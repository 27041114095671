/**
 * Table atom
 */

table {
    border: 1px solid color('gray');
    border-collapse: collapse;
    margin: gutter('sm') 0;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

td {
    padding: gutter('xxs');
}
