/**
 * Property Card organism
 */

//  TODO: Add box-shadow
.o-propertyCard {
    $block: &;

    align-items: flex-end;
    display: flex;
    flex-direction: column;
    position: relative;

    &__wrapper {
        background-color: color('white');
        // box-shadow: box-shadow('small');
        color: color('black');
        display: flex;

        #{$block}.hasOpenHouse & {
            border-radius: border-radius('large') border-radius('large') 0 0;
        }
    }

    &__link {
        border-radius: border-radius('large');
        box-shadow: box-shadow('small');
        overflow: hidden;
        width: 100%;
    }

    &__propertyCount {
        align-self: flex-end;
        background-color: #fff;
        border-radius: 25px;
        bottom: 100%;
        box-shadow: box-shadow('small');
        color: #000;
        font-size: font-size('label');
        line-height: 1;
        margin-bottom: gutter('xs');
        padding: 1.5rem gutter('xs') 1.5rem gutter('xs');
        position: absolute;
    }

    &__comingSoon {
        background-color: color('paperYellow');
        padding: 0.782rem 1.75rem;
    }

    &__imageWrapper {
        flex: 0 0 auto;
        min-height: 15rem;
        position: relative;
        width: 15rem;
    }

    &__image {
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__content {
        background-color: color('white');
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        gap: gutter('xxs');
        justify-content: space-between;
        padding: gutter('xs');
        position: relative;
    }

    &__text {
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;
    }

    &__address {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        line-height: 1.25;
    }

    &__label {
        align-items: center;
        display: flex;
        font-size: font-size('small');
        margin-top: auto;

        &::before {
            background-color: color('blue');
            border-radius: 100%;
            content: '';
            display: block;
            height: 6px;
            margin-bottom: 2px;
            margin-right: 3px;
            width: 6px;
        }

        &--underSale::before {
            background-color: color('orange');
        }
    }

    &__facts {
        align-items: flex-end;
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-start;
        margin-top: auto;
        padding-right: 5rem;
        gap: gutter('xs');
    }

    &__fact {
        margin: 0;
    }

    &__icon {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
    }

    &__tag {
        background-color: rgba(color('black'), 0.5);
        border-radius: border-radius();
        bottom: gutter('xs');
        color: rgba(color('white'), 0.5);
        left: gutter('xs');
        padding: #{gutter('xxs') / 2} gutter('xxs');
        position: absolute;
        z-index: 1;

        &--liebhaver {
            background-color: color('liebhaver');
            color: color('liebhaver-gold');
        }
    }

    &__favorite {
        padding: gutter('xs');
        transition: color speed() easing();

        [screen-reader] &:focus,
        [mouse] &:hover {
            color: color('secondary');
        }
    }

    &__openHouse {
        background-color: color('paperYellow');
        border-radius: 0 0 border-radius() border-radius();
        color: color('black');
        height: gutter();
        padding: gutter('xxs');
        width: 100%;
    }

    &.isSold {
        #{$block}__wrapper {
            color: color('gray-dark');
        }

        #{$block}__tag {
            background-color: color('logoRed');
            color: color('white');
        }

        #{$block}__image {
            opacity: 0.5;
        }

        #{$block}__label {
            color: color('black');

            &::before {
                background-color: color('logoRed');
            }
        }
    }
}
