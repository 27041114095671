/**
 * Frontpage template
 */

.t-frontpage {
    margin-top: gutter();

    @include breakpoint('sm', 'down') {
        margin-top: gutter('xs');
    }
}
