/**
 * Footer organism
 */

.o-footer {
    $block: &;

    background-color: color('white');
    margin-top: gutter('md');
    padding-bottom: gutter();

    @include breakpoint('md') {
        margin-top: 8rem;
    }

    &__title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        transition: color speed() easing();

        [mouse] &:hover {
            color: color('secondary');
            text-decoration: none;
            .m-button {
                background-color: color('secondary:hover');
            }
        }
        &:focus-visible {
            color: color('secondary');
            outline: solid 2px #000;
            .m-button {
                background-color: color('secondary:hover');
            }
        }
    }

    &__linksColumn {
        align-items: stretch;
        display: flex;
    }

    &__links {
        align-items: stretch;
        border-bottom: border-width() solid color('black');
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding-bottom: gutter();
        padding-top: gutter();
    }

    &__unorderedList {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__listItem {
        margin: 0;
    }

    &__link {
        $element: &;

        align-items: center;
        color: color('black');
        display: flex;
        font-size: font-size();
        justify-content: space-between;
        line-height: 1.25;
        min-height: 0;
        padding: gutter('xxs') 0;
        text-decoration: none;
        transition: color speed() easing();
        width: 100%;

        @include breakpoint('md') {
            display: inline-block;
            padding: 0;
            width: auto;
        }

        [mouse] &:hover {
            color: color('secondary');
            text-decoration: none;
        }
        &:focus-visible {
            color: color('secondary');
            outline: solid 2px #000;
        }
    }

    &__linkText {
        flex: 1 1 auto;
        padding: 1rem 0;
        text-align: inherit;
        transition: opacity 0.25s ease-in-out;
    }

    &__linkIcon {
        fill: color('black');
        flex: 0 0 auto;
        margin: 0 1rem;
        transition: opacity 0.25s ease-in-out;

        @include breakpoint('md') {
            display: none;
        }

        [screen-reader] #{$block}__link:focus &,
        [mouse] #{$block}__link:hover & {
            fill: color('secondary');
        }
    }

    &__newsletterColumn {
        align-items: stretch;
        display: flex;
    }

    &__newsletter {
        align-items: stretch;
        border-bottom: border-width() solid color('black');
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: gutter();
        padding-top: gutter();
    }

    &__input {
        margin: 0;
    }

    &__disclaimer {
        display: flex;
        flex-direction: column;
        font-size: font-size('small');
        margin-top: gutter();

        @include breakpoint('md') {
            flex-direction: row;
            flex-wrap: nowrap;
            font-size: font-size('label');
            justify-content: space-between;
            margin-top: gutter('lg');
        }

        a {
            color: color('secondary');
            text-decoration-color: currentColor;

            [screen-reader] &:focus,
            [mouse] &:hover {
                color: color('secondary:hover');
                text-decoration-color: currentColor;
            }
        }

        > p:last-child {
            color: color('gray-dark');
            margin-top: gutter('sm');

            @include breakpoint('md') {
                margin-top: 0;
                text-align: right;
            }

            a {
                color: #0000a0;
                text-decoration: underline;
                text-decoration-color: currentColor;

                [screen-reader] &:focus,
                [mouse] &:hover {
                    color: #00f;
                    text-decoration-color: currentColor;
                }
            }
        }
    }
}
