/**
 * Display: Flex utility
 */

.u-flex {
    display: flex !important;
}

@each $key, $value in $grid-containers {
    @for $i from 1 through $grid-columns {
        @include breakpoint($key) {
            .#{$key}\:u-flex {
                display: flex !important;
            }
        }
    }
}

.u-justify-center {
    justify-content: center;
}

.u-justify-start {
    justify-content: flex-start;
}

.u-justify-end {
    justify-content: flex-end;
}

.u-align-center {
    align-items: center;
}

.u-align-start {
    align-items: flex-start;
}

.u-align-end {
    align-items: flex-end;
}
