/**
 * Search Header organism
 */

.o-searchHeader {
    $block: &;

    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    // margin: 0 #{gutter() / 2};

    &__searchButton {
        margin: 0;
        transition-property: background-color, opacity;
        background-color: var(--button-bg-clr, #{color('secondary')});
        color: var(--button-txt-clr, #{color('white')});

        @include hover-focus() {
            background-color: var(
                --button-bg-active-clr,
                #{color('secondary:hover')}
            ) !important;
        }

        @include breakpoint('sm', 'down') {
            justify-content: center;
            padding: 0;
            width: 5rem;
        }
    }

    &__searchButtonText {
        @include breakpoint('sm', 'down') {
            display: none;
        }
    }

    &__searchButtonIcon {
        margin: 0;

        @include breakpoint('md') {
            display: none;
        }
    }

    &__saveButton {
        margin-left: gutter();

        @include breakpoint('sm', 'down') {
            display: none;
        }
    }

    &__content {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-end;

        @include breakpoint('sm', 'down') {
            background-color: color('white');
            box-shadow: #{box-shadow('small')};
            display: grid;
            gap: gutter('xs');
            grid-template-columns: auto 1fr auto;
            height: 10rem;
            justify-content: center;
            left: 0;
            padding: 0 gutter('xs');
            position: fixed;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }

    &__closeButton {
        @include breakpoint('md') {
            display: none;
        }
    }

    &__filtersButton {
        position: relative;

        @include breakpoint('md') {
            display: none;
        }
    }

    &__filtersButtonIndicator {
        background-color: color('secondary');
        border-radius: border-radius('round');
        height: 1rem;
        position: absolute;
        right: -0.75rem;
        top: -0.25rem;
        transition: opacity speed() easing();
        width: 1rem;
    }

    &__formWrapper {
        display: flex;
        flex: 1 1 auto;
        gap: 0.5px;
        justify-content: flex-end;
        position: relative;

        @include breakpoint('md') {
            flex-wrap: wrap;
        }
    }

    &__form {
        display: flex;
        flex: 1 1 auto;
        position: relative;

        @include breakpoint('md') {
            max-width: 70rem;

            &--simple {
                max-width: 56rem;
            }
        }
    }

    &__input {
        flex: 1 1 auto;
    }

    &__reset {
        background-color: color('gray-light');
        bottom: 0;
        color: color('black');
        cursor: pointer;
        padding: 0 2rem;
        position: absolute;
        right: 11rem;
        top: 0;
        transition: color speed() easing();
        width: 3rem;

        @include breakpoint('md') {
            right: 25rem;
            width: 7rem;
        }

        &:hover {
            color: color('secondary');
        }
    }

    &__searchTypes {
        align-items: center;
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow();
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 5rem;
        justify-content: space-between;
        left: 0;
        margin-top: gutter('xxs');
        position: absolute;
        right: -#{gutter('lg')};
        top: 100%;

        @include breakpoint('md') {
            align-items: unset;
            flex-direction: column;
            height: unset;
            justify-content: unset;
            left: unset;
            right: -#{gutter()};
            width: 27rem;
        }
    }

    &__searchType {
        border-bottom-left-radius: unset;
        border-top-left-radius: unset;
        margin-left: 0.5px;
        padding: 0 #{gutter('xs')} 0 #{gutter('xxs')};
        transition: all speed() easing();
        width: 5rem;

        [screen-reader] &:focus,
        [mouse] &:hover,
        &.active,
        + .isFocused {
            background-color: color('white');
            border: border-width() solid color('secondary');
            color: color('secondary');
        }
    }

    &__searchTypeIcon {
        transition: transform speed() easing();

        #{$block}__searchType.active & {
            transform: rotate(180deg);
        }
    }

    &__tags {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        padding: 0 gutter('xs');
        position: absolute;
        top: 0;
    }

    &__tag {
        color: color('black');
    }

    &__suggestion {
        background-color: color('white');
        height: 5rem;
        margin-top: gutter('xxs');
        position: absolute;
        right: 0;
        top: 100%;
        transition-property: box-shadow, color, opacity;
        width: 100%;

        @include breakpoint('md') {
            height: 7.5rem;
            max-width: 56rem;
        }
    }
}
