/**
 * Multi Toggle molecule
 */

.m-multiToggle {
    $block: &;
    $br: 100px;

    background: color('gray-light');
    border-radius: #{$br};
    font-size: 14px;
    padding: 3px;
    position: relative;

    &__fields {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        width: 100%;
        z-index: 1;
    }

    &__fields > label {
        cursor: pointer;
        display: block;
        position: relative;
        width: 100%;
        z-index: 0;

        span {
            align-items: center;
            border-radius: #{$br};
            display: flex;
            height: 26px;
            justify-content: center;
        }

        input {
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;

            + span {
                background-color: transparent;
                color: color('black');
            }

            &:checked + span {
                background: color('secondary');
                color: color('white');

                .luxurious & {
                    background: color('liebhaver-gold');
                    color: color('black');
                }
            }

            &:focus-visible + span {
                box-shadow: 0 0 0 2px #000;
            }
        }
    }

    /* one item */
    &__fields > label:nth-child(1):nth-last-child(1) {
        width: 100%;
    }

    /* two items */
    &__fields > label:nth-child(1):nth-last-child(2),
    &__fields > label:nth-child(2):nth-last-child(1) {
        width: 50%;
    }

    /* three items */
    &__fields > label:nth-child(1):nth-last-child(3),
    &__fields > label:nth-child(2):nth-last-child(2),
    &__fields > label:nth-child(3):nth-last-child(1) {
        width: 33.3333%;
    }

    /* four items */
    &__fields > label:nth-child(1):nth-last-child(4),
    &__fields > label:nth-child(2):nth-last-child(3),
    &__fields > label:nth-child(3):nth-last-child(2),
    &__fields > label:nth-child(4):nth-last-child(1) {
        width: 25%;
    }
}
