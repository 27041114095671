/**
 * Display atom
 */

.a-display {
    @include lora();

    font-size: font-size('display') - 4.75rem;
    line-height: line-height('display');

    @include breakpoint('lg') {
        font-size: font-size('display');
    }

    &--white {
        color: color('white');
    }
}
