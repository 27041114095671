/**
 * Page Hero organism
 */

.o-pageHero {
    $block: &;

    position: relative;

    &__media {
        background-color: color('gray-light');
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-bottom: gutter();
        overflow: hidden;
        position: relative;

        @include breakpoint('md') {
            margin-bottom: 0;
        }

        #{$block}--smallImage.hasImage & {
            // @include aspect-ratio(770, 496);
            height: 26rem;
            margin-bottom: 0;

            @include breakpoint('md') {
                height: 62rem;
            }
        }

        #{$block}--largeImage & {
            height: 27rem;
            left: -#{gutter('sm')};
            right: -#{gutter('sm')};
            width: calc(100% + #{gutter('sm') * 2});

            @include breakpoint('md') {
                height: 50.5rem;
                left: 0;
                right: 0;
                width: 100%;
            }
        }
    }

    &.hasImage {
        .o-pageHero__headline {
            margin-bottom: -2.1rem;
            padding: 0 0 0;

            &::after {
                bottom: -3.6rem;
            }

            @media (max-width: 1023px) {
                margin-bottom: -1rem;

                &::after {
                    bottom: -4.6rem;
                }
            }

            @include breakpoint('sm', 'down') {
                margin-bottom: -1rem;

                &::after {
                    bottom: -1.9rem;
                }
            }
        }
    }

    &.o-pageHero--smallImage {
        .o-pageHero__headline {
            margin-bottom: 0;
            padding-bottom: gutter('xl');
            padding-right: gutter('xs');
        }
    }

    &__headline {
        @include lora();

        padding: 0 gutter('xs') gutter('xxs');
        position: relative;

        &__inner {
            position: relative;
            z-index: 5;
        }

        &::after {
            background-color: color('white');
            bottom: rem(-22);
            content: '';
            height: rem(24);
            left: -50%;
            position: absolute;
            width: 150%;
            z-index: 1;

            @include breakpoint('md') {
                bottom: rem(-44);
                height: rem(46);
                left: 0;
                width: 100%;
            }

            #{$block}__contentContainer &,
            #{$block}__smallImage & {
                display: none;
            }
        }

        #{$block}__contentContainer & {
            margin-bottom: gutter('xs');

            @include breakpoint('md') {
                margin-bottom: gutter('xl');
            }
        }
    }

    &__mediaPicture,
    &__mediaImage,
    &__mediaVideo {
        flex: none;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
    }

    &__mediaImage.o-pageHero__mediaImage--hasVideo {
        @include breakpoint('lg') {
            display: none !important;
        }
    }

    &__mediaImageMobile {
        @include breakpoint('md') {
            display: none;
        }

        + .o-pageHero__mediaImage {
            display: none;

            @include breakpoint('md') {
                display: block;
            }
        }
    }

    &__mediaButton {
        @include breakpoint('md', 'down') {
            display: none;
        }
    }

    &__mediaEmbed {
        display: none;
        height: 110%;
        left: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        @include breakpoint('lg') {
            display: block;
        }

        iframe {
            height: 100%;
            width: 100%;
        }
    }

    &__mediaSource {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;

        #{$block}--largeImage & {
            width: calc(100% + #{gutter('sm') * 2});

            @include breakpoint('lg') {
                width: 100%;
            }
        }
    }

    &__splash {
        bottom: 100%;
        display: none;
        height: 12rem;
        pointer-events: none;
        position: absolute;
        width: 12rem;
        z-index: 1;

        #{$block}.hasImage & {
            display: block;
        }

        #{$block}--smallImage.hasImage & {
            right: gutter();
            top: -#{gutter('xl')};

            @include breakpoint('md') {
                height: 15rem;
                left: #{$grid-gutter / 2};
                top: gutter('lg');
                transform: translateX(-50%);
                width: 15rem;
            }
        }

        #{$block}--largeImage.hasImage & {
            top: 0;
            transform: translateY(-75%);

            @include breakpoint('md') {
                height: 25rem;
                transform: translateY(-50%);
                width: 25rem;
            }
        }

        #{$block}--smallImage.hasImage.hasHeadline & {
            @include breakpoint('sm', 'down') {
                top: -4rem;
            }
        }
    }

    &__content {
        display: grid;
        flex: 1 1 auto;
        gap: 3rem;
        place-content: start;
        place-items: start;
        position: relative;
        text-align: left;

        #{$block}--smallImage.hasImage & {
            padding-bottom: gutter('xxl');

            @include breakpoint('md') {
                padding-top: 18rem;
            }
        }

        #{$block}--largeImage & {
            background-color: color('white');
            padding-right: gutter('sm');
            padding-top: gutter();

            @include breakpoint('md') {
                margin-top: -8rem;
                padding-right: 0; // gutter();
                padding-top: #{gutter() * 2};
            }

            &::before {
                background-color: color('white');
                bottom: 0;
                box-shadow: 17rem 0 0 0 color('white');
                content: '';
                left: -#{gutter('sm')};
                position: absolute;
                right: 0;
                top: 0;
                z-index: 0;

                @include breakpoint('md') {
                    left: grid-offset(12, 3);
                }
            }
        }

        #{$block}--largeImage.hasHeadline.hasImage & {
            padding-top: 0;

            &::before {
                display: none;
            }

            @include breakpoint('md') {
                margin-top: gutter('xxl');
            }
        }

        #{$block}--largeImage.hasImage.hasHeadline.hasSplash & {
            margin-top: gutter('lg');

            @include breakpoint('md') {
                margin-top: gutter('xxl');
            }
        }

        #{$block}--noImage & {
            margin-top: 0;
            padding-top: 0;

            @include breakpoint('sm', 'down') {
                padding-top: 0.5rem;
            }
        }
    }

    &__title {
        //margin-bottom: gutter('sm');
        position: relative;

        &--no-margin {
            margin-bottom: 0;
        }
    }

    &__description {
        position: relative;
    }

    &__contentContainer {
        #{$block}--largeImage.hasImage & {
            padding-left: 5rem;
            position: relative;
            top: -100px;

            @include breakpoint('md') {
                position: static;
            }

            @include breakpoint('sm', 'down') {
                margin-top: -9rem;
                top: 0;
            }
        }

        #{$block}--smallImage.hasImage & {
            > div:first-child {
                display: flex;
                justify-content: flex-end;
                margin-bottom: gutter('sm');
                text-align: right;

                @include breakpoint('md') {
                    margin-bottom: 0;
                }

                @include breakpoint('sm', 'down') {
                    margin-bottom: 0;
                }
            }
        }

        #{$block}--largeImage.hasImage.hasHeadline & {
            padding-top: 6px;
            position: static;

            @include breakpoint('md') {
                padding-top: 0;
            }

            @include breakpoint('sm', 'down') {
                margin-top: 0;
            }
        }

        #{$block}--largeImage.hasImage.hasHeadline.hasSplash & {
            margin-top: -24px;
            position: static;

            @include breakpoint('md') {
                margin-top: 0;
            }
        }
    }

    &__button {
        height: 5rem;
        min-height: 0;
        width: 5rem;

        @include breakpoint('md') {
            height: 7rem;
            width: 7rem;
        }

        @include breakpoint('lg') {
            height: 9rem;
            width: 9rem;
        }

        #{$block}--smallImage.hasImage.hasHeadline & {
            position: absolute;
            right: gutter();
            top: -33rem;
            z-index: 1;

            @include breakpoint('sm', 'down') {
                top: -31rem;
            }

            @include breakpoint('md') {
                float: left;
                position: static;
                transform: none;
            }
        }

        #{$block}--smallImage.hasImage & {
            bottom: 0;
            position: absolute;
            right: 4rem;
            transform: translateY(50%);
            z-index: 1;

            @include breakpoint('md') {
                float: left;
                position: static;
                transform: none;
            }
        }

        #{$block}--smallImage.hasImage.hasSplash & {
            display: none;

            @include breakpoint('md') {
                display: flex;
                position: static;
                transform: none;
            }
        }

        #{$block}--largeImage.hasImage & {
            bottom: 0;
            position: absolute;
            transform: translateY(50%);
            z-index: 1;

            @include breakpoint('md') {
                bottom: unset;
                left: 0;
                position: absolute;
                right: auto;
                transform: translateY(-50%);
            }

            @include breakpoint('sm', 'down') {
                right: 4rem;
            }
        }

        #{$block}--largeImage.hasImage.hasHeadline & {
            bottom: gutter('xs');
            position: absolute;
            z-index: 1;

            @include breakpoint('sm', 'down') {
                bottom: 5rem;
            }

            @include breakpoint('md') {
                bottom: unset;
                left: 0;
                position: absolute;
                right: auto;
                transform: translateY(-50%);
            }
        }
    }

    &__leftItem {
        #{$block}--largeImage & {
            @include breakpoint('md') {
                display: block;
                left: 7rem;
                position: absolute;
                top: 0;
            }
        }

        &--mobileRight {
            @include breakpoint('md', 'down') {
                display: block;
                left: auto;
                position: absolute;
                right: 16rem;
                top: 0;
            }
        }
    }

    &--productIcon {
        #{$block}__mediaImage {
            @include breakpoint('md') {
                max-height: 50rem;
            }
        }
    }

    &--smallImage.hasHeadline .o-pageHero__row {
        position: relative;

        > div:first-child {
            order: 2;

            @include breakpoint('md') {
                order: 1;
            }

            .o-pageHero__content {
                padding-bottom: 0;

                @include breakpoint('md') {
                    padding-bottom: 7rem;
                }

                @include breakpoint('sm', 'down') {
                    margin-top: 4rem;
                }
            }
        }

        > div:last-child {
            order: 1;

            @include breakpoint('md') {
                order: 2;
            }
        }
    }
}
