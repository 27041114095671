/**
 * Hidden Text organism
 */

.o-hiddenText {
    &__wrapper {
        height: auto;
        // max-height: calc(#{gutter('xxxl')} * 4);
        overflow: hidden;
        position: relative;
        transition: max-height 0.35s ease-in;

        // @include breakpoint('md') {
        //     max-height: calc(#{gutter('xxxl')} * 4);
        // }

        &.isRevealed {
            max-height: 100%;

            &::after {
                opacity: 0;
            }
        }

        &.isTruncated {
            max-height: 18rem;

            @include breakpoint('md') {
                max-height: 22rem;
            }

            &::after {
                display: block;
            }

            // Specific overrides for certain modules
            .o-brokerHero__gridDescription & {
                max-height: 48rem;

                @include breakpoint('md') {
                    max-height: 52rem;
                }
            }

            .o-buyerAdviceTestimonialsGrid & {
                max-height: 40rem;

                @include breakpoint('md') {
                    max-height: 30rem;
                }
            }
        }

        &::after {
            background: linear-gradient(0deg, color('white') 15px, rgba(color('white'), 0));
            bottom: 0;
            content: '';
            display: none;
            height: 50%;
            left: 0;
            opacity: 1;
            position: absolute;
            right: 0;
            transition: opacity 0.3s ease-in;
        }
    }

    &__trigger {
        display: none;

        @include lora();

        &.isRevealed {
            margin-top: gutter('sm');
        }
    }
}
