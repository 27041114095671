/**
 * Property Preview organism
*/

.o-neighborhoodMapPreview {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    color: color('black');
    font-family: font-family('primary');
    font-weight: font-weight('medium');
    overflow: hidden;
    padding: gutter('xs');
    position: relative;
    transition: speed() easing();
    transition-property: box-shadow;

    @include breakpoint('md') {
        padding: gutter('sm');
    }

    [screen-reader] &:focus-within,
    [mouse] &:hover,
    &.isActive {
        box-shadow: box-shadow('normal:hover');
    }

    &__displayName {
        border-bottom: solid 2px color('black');
        color: color('black');
        font-size: font-size('lead');
        line-height: line-height('lead');
        padding-bottom: gutter('xs');
    }

    &__propertiesForSale {
        color: color('black');
        font-size: font-size('label');
        margin-top: gutter('xs');
    }

    &__ameneties {
        color: color('gray-dark');
        font-size: font-size('label');
        margin-top: gutter('xxs');
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__actions {
        margin-top: gutter('sm');
    }
}
