/**
 * Ovrlay Promobox - used in overlays on properties, brokers
 */

.o-overlayPromoBox {
    $block: &;

    border-radius: border-radius('large');
    display: flex;
    flex-direction: column;
    gap: gutter('xs');
    margin-bottom: gutter('md');
    padding: gutter('md') gutter('md');
    text-align: center;

    &--Default {
        background-color: color('white');
        border: border-width() solid color('black');
        border-radius: border-radius('none');
        color: color('black');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(0, 0, 0, 0.2) !important;
            color: inherit !important;
        }
    }

    &--DarkGreen {
        background-color: color('green-dark');
        color: color('white');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(255, 255, 255, 0.2) !important;
            color: inherit !important;
        }
    }

    &--MediumGreen {
        background-color: color('green');
        color: color('white');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(255, 255, 255, 0.2) !important;
            color: inherit !important;
        }
    }

    &--greenPastel-light {
        background-color: color('greenPastel-light');
        color: color('black');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(0, 0, 0, 0.2) !important;
            color: inherit !important;
        }
    }

    &--PaperYellowLight {
        background-color: color('paperYellow-light');
        color: color('black');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(0, 0, 0, 0.2) !important;
            color: inherit !important;
        }
    }

    &--LightSand {
        background-color: color('sand-light');
        color: color('black');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(0, 0, 0, 0.2) !important;
            color: inherit !important;
        }
    }

    &--Yellow {
        background-color: color('yellow');
        color: color('black');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(0, 0, 0, 0.2) !important;
            color: inherit !important;
        }
    }

    &--Orange {
        background-color: color('orange');
        color: color('black');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(0, 0, 0, 0.2) !important;
            color: inherit !important;
        }
    }

    &--Pink {
        background-color: color('pink');
        color: color('black');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(0, 0, 0, 0.2) !important;
            color: inherit !important;
        }
    }

    &--PastelGreen {
        background-color: color('greenPastel-light');
        color: color('black');

        #{$block}__tag {
            background-color: transparent !important;
            border-color: rgba(0, 0, 0, 0.2) !important;
            color: inherit !important;
        }
    }

    &--whiteShadow {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow('normal');
        color: color('black');
        transition: box-shadow speed() easing();

        &:has(a:hover),
        &:has(a:focus-visible) {
            box-shadow: box-shadow('normal:hover');
        }
    }

    &__default-text {
        line-height: line-height('small');
    }

    &__buttons {
        margin-top: 1rem;
    }

    /* NOT FOR SALE VARIATION */
    &--notForSale {
        gap: 0;
        margin-bottom: gutter('sm');
        margin-top: gutter('sm');
        padding: gutter('lg');

        @include breakpoint('sm', 'down') {
            margin: 0;
            padding: gutter('md') gutter('sm') gutter('lg');
        }

        #{$block}__headline {
            margin-bottom: gutter('xxs');
        }

        #{$block}__text {
            font-family: font-family('secondary');
        }

        .o-tags {
            margin: gutter('xs') 0 gutter('md');

            &__tag {
                margin: 0.25rem;
            }
        }

        #{$block}__buttons {
            align-items: center;
            display: flex;
            flex-direction: row-reverse;
            gap: gutter('md');
            justify-content: center;
            margin: 0;

            &--break {
                flex-direction: column;
                gap: gutter('xs');
            }

            @include breakpoint('sm', 'down') {
                flex-direction: column;
                gap: gutter('xs');
            }

            .m-button {
                margin: 0;
            }
        }
    }
}
