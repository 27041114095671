/**
 * ABC Box With Input And CTA organism
 */

.o-abcBoxWithInputAndCta {
    background-color: color('primary');
    color: color('white');
    display: grid;
    grid-template-columns: 1fr;
    position: relative;

    @include breakpoint('md') {
        --paddingInline: 9rem;

        gap: var(--paddingInline);
        grid-template-columns: 6fr 4fr;
        padding: 0 var(--paddingInline);
    }

    @include breakpoint('lg') {
        --paddingInline: 11rem;
    }

    &::before {
        bottom: 0;
        content: '';
        display: none;
        max-width: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 75rem;
        z-index: 0;
    }

    &__content {
        padding: gutter('md');
        position: relative;
        z-index: 2;

        @include breakpoint('md') {
            padding: 9rem 0;
        }
    }

    &__image {
        padding: gutter('md');
        position: relative;
        width: 100%;

        @include breakpoint('md') {
            display: grid;
            padding: 0;
            position: relative;
            transform: translateY(-6rem);

            @include breakpoint('md') {
                transform: translateY(-7rem);
            }
        }

        > img {
            display: block;
        }
    }

    &__title {
        margin-bottom: gutter('sm');
    }

    &__description {
        margin-bottom: gutter('sm');

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__buttons {
        margin-top: 3rem;

        @include breakpoint('md') {
            margin-top: 4rem;
        }
    }
}
