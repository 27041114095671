/**
 * Strong atom
 */

b,
strong {
    font-weight: font-weight('bold');
}

.a-strong {
    font-weight: font-weight('bold');
}
