
/**
 * Property Type Hero organism
 */

.o-propertyTypeHero {
    $block: &;

    background-color: color('primary');
    color: color('white');
    margin-bottom: #{gutter('xxxl') * 2 + gutter('xxl')};
    padding-top: gutter();
}
