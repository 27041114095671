/**
 * Textarea atom
 */

textarea {
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    outline: none;

    &:focus {
        outline: inherit;
    }
}

.a-textarea {
    @extend textarea;

    background-color: color('gray-light');
    border: 0;
    border-bottom: border-width() solid color('black');
    border-radius: border-radius('none');
    color: color('black');
    font-size: font-size('label');
    font-weight: font-weight('medium');
    height: 12rem;
    line-height: line-height('label');
    outline: none;
    padding: gutter() gutter('sm') gutter('sm');
    resize: none;
    transition: speed() easing();
    transition-property: border, color;
    width: 100%;

    &:focus {
        border-bottom-color: color('secondary');
    }

    &:-webkit-autofill {
        box-shadow: inset 0 0 0 gutter() color('gray-light');
    }
}
