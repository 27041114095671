/**
 * Favorite Preview organism
 */

.o-favoritePreview {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    color: color('black');
    display: flex;
    flex-direction: column;
    font-family: font-family('primary');
    padding: gutter('xs');
    position: relative;
    transition: box-shadow speed() easing();

    @include breakpoint('md') {
        padding: gutter('sm');
    }

    &--mobile {
        bottom: gutter('sm');
        left: gutter('sm');
        max-height: calc(73.75rem - #{gutter('sm') * 2});
        overflow: hidden;
        position: absolute;
        right: gutter('sm');
        z-index: 5;
    }

    [screen-reader] &:focus-within,
    [mouse] &:hover,
    &.isActive {
        box-shadow: box-shadow('normal:hover');
    }

    &__title {
        flex: 1 0 auto;
        margin-bottom: gutter('xxs');
    }

    &__author {
        flex: 1 0 auto;
        margin-top: gutter('lg');
    }

    &__description {
        overflow-x: hidden;
        overflow-y: scroll;

        @include breakpoint('md') {
            overflow: unset;
        }
    }

    &__icon {
        align-items: center;
        background-color: color('pink-dark');
        border-radius: border-radius('round');
        box-shadow: 0 0 0 0.75rem rgba(color('pink-dark'), 0.2);
        color: color('white');
        display: flex;
        height: 2rem;
        justify-content: center;
        left: #{gutter('sm') + 0.75rem};
        position: absolute;
        top: -1rem;
        width: 2rem;
        z-index: 1;

        svg {
            height: 1rem;
        }
    }
}
