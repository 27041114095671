/**
 * Checkbox molecule
 */

.m-checkbox {
    $block: &;

    align-items: flex-start;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    fill: currentColor;
    position: relative;
    transition: speed() easing();
    transition-property: color, fill;

    &.isDisabled {
        cursor: default;
        pointer-events: none;
    }

    &__field {
        appearance: none;
        border: border-width() solid color('black');
        border-radius: border-radius();
        display: inline-flex;
        flex: 0 0 auto;
        height: 2.5rem;
        transition: speed() easing();
        transition-property: background-color, border-color;
        width: 2.5rem;

        #{$block}--dark & {
            border-color: color('white');
        }

        #{$block}.isChecked & {
            background-color: color('secondary');
            border-color: color('secondary');
        }

        #{$block}.isDisabled & {
            border-color: color('gray');
        }

        #{$block}.isDisabled.isChecked & {
            background-color: color('gray');
        }

        &.hasError {
            border-color: color('logoRed');
        }
    }

    &__label {
        padding-block-start: 2px;
        line-height: line-height('lead');
        margin: 0 gutter('xs');
        pointer-events: none;
        transition: speed() easing();
        transition-property: color, opacity;

        #{$block}.isDisabled & {
            color: color('gray');
        }

        a {
            color: color('secondary');
            pointer-events: all;
            text-decoration: underline;
        }
    }

    &__icon {
        color: color('white');
        height: 100%;
        opacity: 0;
        transition: opacity speed() easing();
        width: 100%;

        #{$block}.isChecked & {
            opacity: 1;
        }
    }
}
