.o-buyerAdviceProductDescription {
    display: grid;
    gap: 6rem;

    &__header {
        display: grid;
        gap: 3rem;

        > * {
            max-width: 60ch;
        }
    }

    &__list {
        display: grid;
        gap: 3rem;
        list-style: none;
        margin: 0;
        padding: 0;

        @media (min-width: 768px) {
            gap: 4rem;
            grid-template-columns: 1fr 1fr;
        }

        > li {
            background-color: color('buyerAdviceYellow');
            color: color('black');
            display: grid;
            gap: 2rem;
            grid-template-areas: "icon" "text";
            padding: 4rem;
            position: relative;

            @media (min-width: 1024px) {
                gap: 5rem;
                grid-template-areas: "icon text";
                grid-template-columns: 120px auto;
                padding: 6rem;
            }

            &.no-icon {
                grid-template-areas: "text";

                @media (min-width: 1024px) {
                    grid-template-areas: "text text";
                }
            }

            .content {
                align-self: start;
                display: grid;
                gap: 2rem;
                grid-area: text;
                grid-template-rows: auto;

                > * {
                    margin: 0;
                }
            }

            > svg,
            > img {
                align-self: start;
                display: block;
                grid-area: icon;
                justify-self: start;
                max-width: 100%;
                width: auto;

                @media (min-width: 1024px) {
                    align-self: start;
                    height: auto;
                    justify-self: center;
                    margin-top: 7px;
                }
            }
        }
    }
}
