/**
 * Toggle molecule
 */

.m-toggle {
    $block: &;

    align-items: center;
    cursor: pointer;
    display: inline-flex;
    height: 2.5rem;
    margin: 0 gutter('sm') 0 0;
    position: relative;
    width: 4rem;

    &::before {
        background-color: color('gray');
        border-radius: border-radius('round');
        content: '';
        height: 1.5rem;
        left: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    &__field {
        background-color: color('secondary');
        border-radius: border-radius('round');
        display: inline-flex;
        flex: 0 0 auto;
        height: 2.5rem;
        left: 0;
        position: absolute;
        top: 0;
        transition: speed() easing();
        transition-property: background-color, border-color, left;
        width: 2.5rem;
        z-index: 2;

        #{$block}.isChecked & {
            background-color: color('black');
            left: calc(100% - 2.5rem);
        }

        #{$block}--dark.isChecked & {
            background-color: color('white');
        }
    }
}
