/**
 * Property Views organism
 */

.o-propertyViews {
    display: grid;
    grid-template-areas: 'content';
    list-style: none;
    margin-bottom: 2rem;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__item {
        align-items: center;
        display: flex;
        grid-area: content;
        margin: 0;
    }

    &__itemIcon {
        margin-right: gutter('xxs');
    }
}
