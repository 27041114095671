/**
 * Radio atom
 */

.a-radio {
    @extend input;

    background-color: color('white');
    border: border-width() solid color('black');
    border-radius: border-radius();
    cursor: pointer;
    height: 2.5rem;
    transition: speed() easing();
    transition-property: background-color, border-color;
    width: 2.5rem;

    &:focus {
        outline: inherit;
    }

    &:checked {
        background-color: color('secondary');
        border-color: transparent;
    }

    &[disabled] {
        border-color: color('gray');

        &:checked {
            background-color: color('gray');
        }
    }
}
