/**
 * Municipality About organism
 */

.o-municipalityAbout {
    $block: &;

    &__title {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: gutter('xs');
        }
    }

    &__description {
        margin-bottom: gutter();

        @include breakpoint('md') {
            margin-bottom: 0;
        }
    }

    &__data {
        @include breakpoint('md') {
            margin-bottom: 0;
            padding-left: gutter('xl');
        }
    }

    &__dataTable--second {
        @include breakpoint('md') {
            margin-bottom: gutter('sm');
            margin-top: gutter('xxxl');
        }
    }

    &__dataSource {
        text-align: end;
    }

    &__dataColumn {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: 0;
        }

        @include breakpoint('md', 'down') {
            td:last-child {
                padding-left: gutter('xxs');
                white-space: nowrap;
            }
        }
    }
}
