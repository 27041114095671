/**
 * This is used for development only. Used with GridOverlay.vue
 */

.grid-overlay {
    bottom: 0;
    display: grid;
    gap: 0 4rem;
    grid-template-columns: repeat(6, minmax(20px, 1fr));
    left: 0;
    margin: 0 auto;
    min-height: 40px;
    padding: 0 gutter('md');
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 11;

    @include breakpoint('md') {
        grid-template-columns: repeat(12, minmax(20px, 1fr));
        padding: 0 gutter('xs');
    }

    @include breakpoint('md') {
        max-width: 120rem;
    }

    @include breakpoint('lg') {
        max-width: 172rem;
    }

    @include breakpoint('xl') {
        max-width: 176rem;
    }

    @include breakpoint('xxl') {
        max-width: 180rem;
    }

    &::before {
        background-color: black;
        color: #fff;
        content: 'xs';
        left: 4rem;
        padding: 2rem;
        position: absolute;
        text-transform: uppercase;
        top: 4rem;

        @include breakpoint('sm') {
            content: 'sm';
        }

        @include breakpoint('md') {
            content: 'md';
        }

        @include breakpoint('lg') {
            content: 'lg';
        }

        @include breakpoint('xl') {
            content: 'xl';
        }

        @include breakpoint('xxl') {
            content: 'xxl';
        }
    }

    > div {
        background-color: blue;
        opacity: 0.1;
        position: relative;
    }

    &-button {
        --distance: #{gutter('xs')};
        bottom: var(--distance);
        left: 2rem;
        position: fixed !important;
        z-index: 11;
        @include breakpoint('md') {
            --distance: #{gutter('sm')};
        }

        body:has(.t-search__searhAgentButton) & {
            left: 30rem;
            display: none;
        }
    }
}
