/**
 * Radios organism
 */

.o-radios {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    justify-content: flex-start;
    margin: -#{gutter('xxs')};

    &__radio {
        margin: gutter('xxs');
    }
}
