/**
 * Search template
 */

.t-search {
    $block: &;

    bottom: 0;
    display: flex;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 10rem;

    &--has-filters {
        @include breakpoint('sm', 'down') {
            display: grid;
            grid-template-rows: min-content auto;

            .o-searchTags {
                box-shadow: box-shadow('small');
                position: relative;
                z-index: 3;
            }
        }
    }

    @include breakpoint('md') {
        top: 10rem;
    }

    &__list {
        align-items: stretch;
        background-color: color('white');
        // box-shadow: box-shadow('small');
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: scroll;
        // padding: gutter() #{gutter() / 2} 0;
        padding: gutter() #{gutter() / 2};
        position: relative;
        width: 100%;
        z-index: 2;

        @include breakpoint('md') {
            width: #{map-get($grid-containers, 'md') / 2 + gutter()};
        }

        @include breakpoint('lg') {
            width: #{map-get($grid-containers, 'lg') / 2 + gutter()};
        }

        @include breakpoint('xxl') {
            width: #{map-get($grid-containers, 'xxl') / 4 * 3 + gutter()};
        }

        #{$block}--listView & {
            width: 100%;
        }
    }

    &__map {
        flex: 1 1 auto;
        position: relative;
        z-index: 1;
        grid-row: 2/3;
    }

    &__toggle {
        align-items: flex-end;
        display: flex;
        flex: 0 1 auto;
        justify-content: center;
        position: relative;
        width: 0;
        z-index: 2;

        @include breakpoint('sm', 'down') {
            bottom: 0;
            justify-content: flex-end;
            position: fixed;
            right: 0;
            width: auto;
        }

        #{$block}--listView & {
            justify-content: flex-end;
        }
    }

    &__toggleButton {
        display: flex;
        flex: 0 0 auto;
        margin: gutter('xs');
        position: relative;

        @include breakpoint('md') {
            margin: gutter('sm');
        }
    }
}
