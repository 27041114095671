/**
 * Estimate Header organism
 */

.o-estimateHeader {
    $block: &;

    &__headingWrapper {
        @include breakpoint('md') {
            text-align: center;
        }
    }

    &__heading {
        display: inline-block;
        position: relative;
    }

    &__edit {
        align-items: center;
        color: color('secondary');
        display: flex;
        justify-content: center;
        padding: gutter('xxs');
        position: absolute;
        right: -5rem;
        top: -0.7rem;
    }

    &__address {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: gutter('xs') 0;

        @include breakpoint('md') {
            text-align: center;
        }
    }

    &__type {
        margin-top: gutter('xs');

        @include breakpoint('md') {
            text-align: center;
        }
    }

    &__input {
        background-color: transparent;
        border-bottom: 1px solid rgba(color('black'), 0.2);
        height: 9rem;
        margin-bottom: 0;
        padding: 0;

        @include breakpoint('md') {
            padding: 0 gutter('xs');
            text-align: center;
        }

        &::placeholder {
            color: rgba(color('black'), 0.2);
        }
    }

    &__addressItem {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: gutter('xs') 0;
        text-align: left;
        transition: speed() easing();
        transition-property: background-color, border-color, color;
        width: 100%;

        @include breakpoint('md') {
            padding: gutter('md');
            text-align: center;
        }

        &:hover {
            background-color: color('secondary');

            & #{$block}__addressIcon {
                background-color: color('black');
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid rgba(color('black'), 0.2);

            &:hover {
                border-bottom-color: color('secondary');
            }
        }
    }

    &__addressHeading {
        margin-bottom: 0;
    }

    &__addressIcon {
        background-color: color('secondary');
        border-radius: border-radius('round');
        color: color('white');
        flex-shrink: 0;
        height: gutter('lg');
        padding: gutter('xxs');
        width: gutter('lg');
    }
}
