/**
 * Related Items organism
 */

.o-relatedItems {
    $block: &;

    color: color('white');
    height: 100%;
    padding: gutter('sm');

    @include breakpoint('md') {
        margin-left: 0;
        margin-right: 0;
        padding: gutter('xxl') 11rem;
    }

    &--greenLight {
        background-color: color('green');
    }

    &--primary {
        background-color: color('primary');
    }

    &__titleColumn {
        margin-bottom: gutter('md');

        @include breakpoint('md') {
            margin-bottom: gutter('xxl');
        }
    }

    &__itemsRow {
        display: grid;
        gap: gutter('xs');
        grid-template-columns: 1fr;

        @include breakpoint('md') {
            grid-template-columns: repeat(auto-fit, minmax(38rem, 1fr));
        }
    }

    &__link {
        align-items: center;
        background-color: color('white');
        border-radius: border-radius('large');
        color: color('black');
        display: flex;
        justify-content: space-between;
        min-height: 8rem;
        overflow: hidden;
        padding: 0 gutter('xs') 0 gutter('sm');
        transition: opacity speed() easing();
        width: 100%;

        &:hover {
            opacity: 0.9;
        }

        &--fullWidth {
            grid-column: 1/-1;
        }
    }

    &__linkText {
        align-items: center;
        display: flex;
        width: calc(100% - #{gutter('sm')});
    }

    &__linkTextSmall {
        margin-left: gutter('xxs');
    }

    &__linkIcon {
        flex: 0 0 auto;
    }

    &__buttonRow {
        display: flex;
        flex-wrap: wrap;
        gap: gutter('xs');
        margin-top: gutter('xl');
    }
}
