/**
 * Label atom
 */

.a-label {
    font-family: font-family();
    font-size: font-size('label');
    font-weight: font-weight('medium');
    line-height: line-height('label');
    position: relative;

    &--white {
        color: color('white');
    }

    &--secondary {
        color: color('secondary');
    }

    &--secondaryFont {
        // font-family: font-family('secondary');
        @include lora();
    }

    &--ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &--centered {
        text-align: center;
        width: 100%;
    }
}
