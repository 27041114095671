/**
 * Broker card organism
 */

.o-brokerMapCard {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    overflow: hidden;
    position: relative;

    &__owner {
        background-color: color('black');
        border-radius: 10px 10px 0 0;
        bottom: 100%;
        color: color('white');
        font-size: font-size('label');
        left: 4rem;
        line-height: 1;
        padding: gutter('xxs') gutter('xs');
        position: absolute;
    }

    &__brokerPortrait {
        align-self: stretch;
        flex: none;
        width: 150px;
    }

    &__wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 100%;

        #{$block}--flexible & {
            @include breakpoint('md') {
                align-items: center;
                flex-direction: column;
            }
        }

        &--showAsLink {
            cursor: pointer;
        }
    }

    &__image {
        display: block;
        height: 100%;
        max-height: 100px;
        object-fit: cover;
        width: 100%;
    }

    &__content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        justify-content: space-between;
        overflow: hidden;
        padding: 10px;

        #{$block}--flexible & {
            @include breakpoint('md') {
                margin-left: 0;
                margin-top: gutter('xs');
                text-align: center;
            }
        }
    }

    &__logo {
        height: 10px;
        width: unset;
    }

    &__name {
        font-size: font-size('label');
        line-height: line-height('small');
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include breakpoint('md') {
            font-size: font-size('lead');
        }
    }

    &__title {
        font-size: font-size('small');
        line-height: line-height('small');

        @include breakpoint('md') {
            font-size: font-size('label');
        }
    }

    &__info {
        font-size: font-size('small');
        line-height: line-height('small');
        margin-top: gutter('xxs');

        @include breakpoint('md') {
            font-size: font-size('label');
            margin-top: gutter('xs');
        }
    }

    &__phoneLink {
        bottom: 10px;
        position: absolute;
        right: 10px;

        svg {
            display: block;
        }
    }
}
