.o-propertyDetails {
    $block: &;

    position: relative;

    @include breakpoint('md') {
        grid-template-columns: repeat(12, minmax(20px, 1fr));
        display: grid;
        gap: 0 4rem;
    }

    @include breakpoint('md') {
        max-width: 120rem;
    }

    @include breakpoint('lg') {
        max-width: 172rem;
    }

    @include breakpoint('xl') {
        max-width: 176rem;
    }

    @include breakpoint('xxl') {
        max-width: 180rem;
    }

    &__row {
        @include breakpoint('md') {
            --image-size: 2.235fr;
            --gap-size: #{gutter('xxl')};
            --details-size: 1fr;

            align-items: end;
            display: grid;
            grid-template-columns:
                [image-start] var(--image-size)
                [gap-start details-start] var(--gap-size)
                [image-end gap-end] var(--details-size)
                [details-end];
            grid-column: 2 / -2;
            margin-inline: auto;

            &--leftAligned {
                grid-template-columns:
                    [details-start] var(--details-size)
                    [gap-start image-start] var(--gap-size)
                    [details-end gap-end] var(--image-size)
                    [image-end];
            }
        }
    }

    &__imageColumn {
        @include breakpoint('md') {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-right: 0;
            z-index: 1;
            grid-area: image;
        }
    }

    &__imageWrapper {
        @include breakpoint('md') {
            height: 60rem;
            overflow: hidden;
            width: 100%;
        }

        @include breakpoint('sm', 'down') {
            display: flex;
            flex-wrap: nowrap;
            gap: gutter('xxs');
            // height: gutter('xxxl') + gutter('xs');
            margin-bottom: gutter('sm');
            margin-inline: -4rem;
        }

        .swiper-pagination {
            padding-top: 1rem;
            position: relative;
        }
    }

    &__imageBox {
        flex: 1;
        max-width: 33%;
    }

    &__image {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
    }

    &__detailsColumn {
        position: relative;
        @include breakpoint('md') {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: 0;
            z-index: 2;
            grid-area: details;
            background-color: #fff;
            padding-top: var(--gap-size);
            padding-left: var(--gap-size);
            z-index: 1;
        }

        &--leftAligned {
            @include breakpoint('md') {
                padding-left: 0;
                padding-right: var(--gap-size);
            }
        }
    }

    &__detailsText {
        @include breakpoint('md') {
            margin-bottom: gutter('xxxl');
            overflow: hidden;
        }
    }

    &__detailsHeadline {
        margin-bottom: gutter('xs');
    }

    &__detailsProgress {
        margin-right: gutter('xs');
    }

    &__detailsNavigation {
        display: flex;
        flex-wrap: nowrap;
        margin-right: auto;
    }

    &__navigationButtons {
        display: flex;
        flex-wrap: nowrap;
        margin-right: auto;
    }

    &__navigationButton {
        cursor: pointer;
        transition: color speed() easing();

        &:hover {
            color: color('secondary');
        }

        &:not(:last-of-type) {
            margin-right: gutter('xs');
        }
    }
}
