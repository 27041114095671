/**
 * Horizontal Rule atom
 */

hr {
    border: 0;
    border-bottom: 1px solid color('gray-light');
    height: 0;
    margin: gutter('lg') 0;
    width: 100%;
}

.a-horizontalRule {
    @extend hr;
}
