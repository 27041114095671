/**
 * Module Image With Text organism
 */

.o-moduleImageWithText {
    $block: &;

    &__column {
        display: flex;
    }

    &__media {
        font-size: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    &__small {
        display: block;
        margin: gutter('xs') 0 0;
        white-space: normal;
    }
}
