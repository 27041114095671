/**
 * Pagination organism
 */

.o-pagination {
    align-items: center;
    border-top: border-width('small') solid color('gray-light');
    display: flex;
    justify-content: flex-end;
    padding: gutter('sm') 0 0;

    &__link {
        display: flex;
        margin-left: gutter('xxs');
    }

    &__text {
        margin-right: gutter('xxs');
    }
}
