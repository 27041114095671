/**
 * Module Box With Overlapping Icon
 */

.o-moduleBoxWithOverlappingIcon {
    $block: &;

    // background-color: color('green-dark');
    color: color('white');
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    margin: 0;
    position: relative;

    @include breakpoint('md') {
        grid-template-columns: repeat(12, 1fr);
    }

    &--paperYellowLight {
        background-color: color('paperYellow-light');
        color: color('black');
    }

    &--greenDark {
        background-color: color('green-dark');
    }

    &--green {
        background-color: color('green');
    }

    &--greenPastel {
        background-color: color('greenPastel');
        color: color('black');
    }

    &--white {
        background-color: color('white');
        color: color('black');
    }

    &--whiteShadow {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow();
        color: color('black');

        @include breakpoint('sm', 'down') {
            border-radius: 0;
            box-shadow: none;

            #{$block}__contentColumn {
                border-radius: border-radius('large');
                box-shadow: box-shadow();
            }
        }
    }

    &--whiteBorder {
        background-color: color('white');
        border: border-width() solid color('black');
        color: color('black');

        @include breakpoint('sm', 'down') {
            border: none;

            #{$block}__contentColumn {
                border: border-width() solid color('black');
            }
        }
    }

    &__imageColumn {
        grid-row: 1;
        min-height: 7rem;

        @include breakpoint('sm', 'down') {
            background-color: #fff;
        }

        @include breakpoint('md') {
            grid-column: 1/6;
            grid-row: 1;

            #{$block}.isRightAligned & {
                grid-column: 8/-1;
            }
        }
    }

    &__contentColumn {
        grid-row: 2;
        padding: gutter();
        padding-top: 10rem;
        width: 100%;

        @include breakpoint('md') {
            grid-column: 6/-1;
            grid-row: 1;
            padding: gutter('xxl') gutter('xxxl');

            #{$block}.isRightAligned & {
                grid-column: 1/8;
            }

            #{$block}.hasImage.isRightAligned & {
                padding-right: 14rem;
            }

            #{$block}.hasImage:not(.isRightAligned) & {
                padding-left: 14rem;
            }

            #{$block}.noImage:not(.isRightAligned) & {
                grid-column: 4/-1;
                padding-left: 14rem;
            }

            #{$block}.noImage.isRightAligned & {
                grid-column: 1/-4;
                padding-right: 14rem;
            }
        }
    }

    &__media {
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;

        @include breakpoint('md') {
            height: 100%;
        }
    }

    &__mediaImage {
        @include breakpoint('md') {
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__iconWrapper {
        display: flex;
        grid-row: 2;
        justify-content: flex-start;
        left: gutter();
        position: absolute;
        right: 0;
        top: -7rem;
        width: 14rem;
        z-index: 2;

        @include breakpoint('md') {
            grid-row: 1;
            left: 0;
            top: 7rem;
            width: auto;
        }

        #{$block}.hasImage:not(.isRightAligned) & {
            @include breakpoint('md') {
                grid-column: 5/7;
                padding-right: 4rem;
                transform: translateX(-2rem);
            }
        }

        #{$block}.hasImage.isRightAligned & {
            @include breakpoint('md') {
                grid-column: 7/9;
                padding-left: 4rem;
                transform: translateX(2rem);
            }
        }

        #{$block}.noImage & {
            @include breakpoint('md') {
                display: flex;
                grid-row: 1;
                justify-content: center;
                left: 4rem;
                margin: 0 auto;
                max-width: 250px;
                position: absolute;
                right: 4rem;
                top: -4rem;
                z-index: 2;
            }
        }

        #{$block}.noImage:not(.isRightAligned) & {
            @include breakpoint('md') {
                grid-column: 1/5;
                left: 6rem;
            }
        }

        #{$block}.noImage.isRightAligned & {
            @include breakpoint('md') {
                grid-column: 9/-1;
                right: 6rem;
            }
        }
    }

    &__trumpet {
        font-size: font-size('small');
        margin-bottom: gutter('xxs');
    }

    &__title {
        margin-bottom: 1.5rem;
    }

    &__small {
        display: block;
        margin-top: gutter('xs');
        white-space: normal;

        @include breakpoint('md') {
            margin-top: gutter();
        }
    }

    &__buttons {
        margin-top: gutter('sm');
    }

    &__icon {
        display: block;
    }
}
