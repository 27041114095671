/**
 * Neighborhood page hero organism
 */
.o-neighborhoodPageHero {
    $block: &;

    background-color: color('primary');
    padding-top: gutter('xs');

    @include breakpoint('md') {
        padding-bottom: gutter('xxxl');
        padding-top: gutter();
    }

    &__breadcrumb {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: gutter('lg');
        }
    }

    &__title {
        color: color('white');
        margin-bottom: gutter('sm');

        @include breakpoint('md') {
            margin-bottom: gutter('lg');
        }
    }

    &__description {
        color: color('white');
        margin-bottom: gutter();
    }

    &__map {
        height: 73.75rem;
    }

    &__mapRow {
        @include breakpoint('sm', 'down') {
            margin: 0 -4rem;
        }
    }

    &__mapColumn {
        @include breakpoint('sm', 'down') {
            padding: 0;
        }
    }
}
