/**
 *  Table Head Cell atom
 */

.a-tableCell {
    border: none;
    padding: gutter('xxs') 0;

    &.tags {
        padding-top: gutter('xs');
    }

    &:first-child:not(.tags) {
        padding-left: gutter('xxs');
        text-align: start;
    }

    &:not(:first-child) {
        padding-left: gutter('sm');
        text-align: end;
    }

    &:last-child:not(.tags) {
        padding-right: gutter('xxs');
    }

    //Advanced table
    .m-table__advancedTable & {
        &:not(:first-child) {
            padding-left: gutter('xxs');
            text-align: end;
        }

        @include breakpoint('sm') {
            min-width: 130px;
        }

        @include breakpoint('md') {
            min-width: auto;
        }

        &:first-child:not(.tags) {
            text-align: start;
        }

        thead {
            th {
                min-width: 10ch;
                word-break: keep-all;

                @include breakpoint('md') {
                    min-width: auto;
                }
            }
        }
    }
}
