/**
 * Favorite Preview organism
 */
.o-pointOfInterestSelectors {
    .m-tag {
        transition: opacity .25s ease;
        &[disabled] {
            opacity: .5;
        }
    }
}
.o-pointOfInterestPreview {
    $block: &;

    --padding: #{gutter('xs')};

    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    color: color('black');
    display: flex;
    flex-direction: column;
    font-family: font-family('primary');
    padding: var(--padding);
    position: relative;
    transition: box-shadow speed() easing();

    @include breakpoint('md') {
        --padding: #{gutter('sm')};
    }

    &--mobile {
        bottom: gutter('sm');
        left: gutter('sm');
        max-height: calc(73.75rem - #{gutter('sm') * 2});
        overflow: hidden;
        position: absolute;
        right: gutter('sm');
        z-index: 5;
        width: auto;
        max-width: 50rem;
    }

    &__closeButton {
        position: absolute;
        top: calc(var(--padding)  / 2);
        right: calc(var(--padding)  / 2);
    }

    [screen-reader] &:focus-within,
    [mouse] &:hover,
    &.isActive {
        box-shadow: box-shadow('normal:hover');
    }

    &__tag {
        background-color: transparent;
        border-color: transparent;
        color: color('black');

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: transparent;
            color: color('black');
        }

        input {
            position: absolute;
        }

        &:has(input:checked) {
            border-color: color('blue');
            color: color('blue');
        }
    }
    &__poiType {
        align-items: center;
        display: flex;
        margin-bottom: gutter('xxs');
    }
    &__title {
        flex: 1 0 auto;
        margin-bottom: gutter('xxs');
    }
    &__distance {
        color: color('blue');
        margin-bottom: gutter('xs');
    }

    
    + .mapboxgl-popup-close-button {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xOSA2LjQxTDE3LjU5IDVMMTIgMTAuNTlMNi40MSA1TDUgNi40MUwxMC41OSAxMkw1IDE3LjU5TDYuNDEgMTlMMTIgMTMuNDFMMTcuNTkgMTlMMTkgMTcuNTlMMTMuNDEgMTJMMTkgNi40MVoiIGZpbGw9ImJsYWNrIi8+DQo8L3N2Zz4NCg==);
        background-size: 3rem;
        top: 1.5rem;
        right: 1.5rem;
        width: 3rem;
        height: 3rem;
    }
}
