/**
 * Module Links organism
 */

.o-moduleLinks {
    $block: &;

    filter: drop-shadow(box-shadow('small'));

    // .t-property & {
    //     @include breakpoint('sm', 'down') {
    //         margin-bottom: #{gutter('xs')};
    //         margin-top: -#{gutter('xl')};
    //     }
    // }

    &__label {
        display: block;
        margin-bottom: gutter('xs');
    }

    &__toggle {
        display: none;
        flex: 0 0 auto;

        @include breakpoint('md') {
            display: flex;
        }
    }

    &__buttons {
        @include breakpoint('sm', 'down') {
            flex-wrap: nowrap;
            margin-left: -#{gutter('md')};
            margin-right: -#{gutter('md')};
            overflow-x: auto;
            overscroll-behavior: contain;
            padding: gutter('sm');
            scroll-padding: 0 gutter('sm');
            scroll-snap-type: x mandatory;
        }
    }

    &__button {
        border: none;
        box-shadow: none;
        flex: 0 0 auto;

        @include breakpoint('sm', 'down') {
            scroll-snap-align: center;
        }
    }
}
