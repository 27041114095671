.o-homeEstimateParamaterExplainer {
    max-width: 44rem;
    position: relative;
    z-index: 1;

    @include breakpoint('sm', 'down') {
        bottom: auto;
        left: auto;
        max-width: 100%;
        position: relative;
        width: 100%;
    }

    &__content {
        &--text {
            display: inline;
            font-size: font-size('label');
        }

        &--infoToggler {
            color: color('blue');
            cursor: pointer;
        }
    }
}
