/**
 * Neighborhood Hero organism
 */

.o-neighborhoodHero {
    $block: &;

    background-color: color('primary');
    padding-bottom: gutter();
    padding-top: gutter('xs');

    @include breakpoint('md') {
        padding-bottom: gutter('xxxl');
        padding-top: gutter();
    }

    &__breadcrumb {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: gutter('lg');
        }
    }

    &__title {
        color: color('white');
        margin-bottom: gutter('sm');

        @include breakpoint('md') {
            margin-bottom: gutter('lg');
        }
    }

    &__description {
        color: color('white');
        margin-bottom: gutter();
    }

    &__map {
        height: 73.75rem;

        @include breakpoint('md') {
            height: 88rem;
        }
    }

    &__mapRow {
        @include breakpoint('sm', 'down') {
            margin: 0 -4rem;
        }

        .m-map__list {
            padding-bottom: gutter('md');
        }
    }

    &__mapColumn {
        @include breakpoint('sm', 'down') {
            padding: 0;
        }
    }
}
