/**
 * Property Estimate Teaser
 */

.o-propertyEstimateTeaser {
    background-color: color('green-dark');
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    padding: gutter();

    @include breakpoint('lg') {
        flex-direction: row;
        flex-wrap: nowrap;
        padding: gutter('xl') 12.5rem;
    }

    &__wrapper {
        margin-top: gutter('lg');
    }

    &__content {
        display: flex;
        flex: 0 0 100%;
        flex-direction: column;

        @include breakpoint('lg') {
            flex-basis: unset;
            flex-grow: 1;
        }
    }

    &__info {
        color: color('secondary');
        cursor: pointer;
        display: inline-flex;
        transition: opacity speed() easing();

        &:hover {
            opacity: 0.75;
        }
    }

    &__infoHeadline {
        padding-bottom: gutter('sm');
    }

    &__contentHeadline {
        margin-bottom: gutter('xxs');
    }

    &__contentDescription {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: gutter('xs');
    }

    &__contentDescriptionIcon {
        color: color('secondary');
        margin-left: gutter('xxs');
    }

    &__contentInputButton {
        height: 6.25rem;
        margin-top: gutter('xs');
        text-align: center;

        @include breakpoint('md') {
            margin-top: 0;
        }
    }

    &__contentInputWrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include breakpoint('md') {
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    &__splash {
        align-self: flex-end;
        height: 11rem;
        margin-bottom: -#{gutter() * 2};
        transform: translateY(-#{gutter() * 2});
        width: 11rem;

        @include breakpoint('lg') {
            align-self: unset;
            flex: 0 0 auto;
            height: 32rem;
            margin-bottom: -11rem;
            transform: translateY(-11rem);
            width: 32rem;
        }
    }

    &__splashImage {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center center;
    }

    &__disclaimer {
        margin-top: gutter('xs');
    }

    &__disclaimerText {
        text-overflow: initial;
        white-space: initial;
    }
}
