/**
 * Unordered List atom
 */

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.a-unorderedList {
    @extend ul;

    list-style-position: outside;
    list-style-type: square;
    margin: 0 0 0 2.25rem;
    padding: 0;
}
