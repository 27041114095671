/**
 * Section atom
 */
.a-section {
    $block: &;

    margin: gutter('xl') 0;

    @include breakpoint('md') {
        margin: gutter('xxl') 0;
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &--secondary {
        background-color: color('secondary');
        margin: 0;
        padding: gutter('xxl') 0;
    }

    &--double-top-margin {
        @include breakpoint('md') {
            margin-top: 14rem;
        }
    }

    &--breadCrumb {
        @include breakpoint('sm', 'down') {
            margin-bottom: -#{gutter('md')};
        }
    }

    &--default {
        background-color: color('white');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--green {
        background-color: color('green');
        color: color('white');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--greenPastel {
        background-color: color('greenPastel');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--greenDark {
        background-color: color('green-dark');
        color: color('white');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--paperYellowLight {
        background-color: color('paperYellow-light');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--yellow {
        background-color: color('yellow');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--orange {
        background-color: color('orange');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--sand {
        background-color: color('sand');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--sandLight {
        background-color: color('sand-light');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--gray {
        background-color: color('gray');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--grayLight {
        background-color: color('gray-light');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--grayDark {
        background-color: color('gray-dark');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }

    &--buyerAdviceYellow {
        background-color: color('buyerAdviceYellow');
        color: color('black');
        margin: 0;
        padding: gutter('xl') 0;

        @include breakpoint('md') {
            padding: gutter('xxl') 0;
        }
    }
}
