/**
 * Tags organism
 */

.o-tags {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;

    &--centered {
        justify-content: center;
    }
}
