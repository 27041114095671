/**
 * Search Tags organism
 * This module is used on mobile only
 */

.o-searchTags {
    $block: &;

    background-color: color('white');
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: gutter('xs');
    padding-block-start: 0;
    scrollbar-width: thin;
    scroll-padding-inline: gutter('xs');
    scroll-snap-type: x mandatory;

    @include breakpoint('md') {
        display: none;
    }

    > button {
        scroll-snap-align: center;
    }

    .o-searchFilters & {
        margin-inline: -#{gutter()};
        width: calc(100% + (2 * #{gutter()}));
    }
}
