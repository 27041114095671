/**
 * Module Quote organism
 */

.o-moduleQuote {
    $block: &;

    &__column {
        display: flex;
        flex-direction: column;
    }

    &__content {
        border-left: border-width() solid color('secondary');
        padding-left: gutter();

        @include breakpoint('md') {
            padding-left: gutter('xxxl');
        }
    }

    &__label {
        display: block;
        margin: gutter() 0 0;
        white-space: normal;
    }
}
