.o-homeEstimateOrderEstimate {
    align-items: center;
    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    cursor: pointer;
    display: flex;
    gap: gutter('xs');
    max-width: 44rem;
    padding: gutter('xs');
    position: relative;

    @include breakpoint('sm', 'down') {
        bottom: auto;
        left: auto;
        max-width: 100%;
        position: relative;
        width: 100%;
    }

    &__image {
        border-radius: border-radius('round');
        height: rem(64);
        width: rem(64);
    }

    &__text {
        font-size: font-size('label');
    }

    &__icon {
        min-width: rem(24);
        width: rem(24);
    }
}
