/**
 * Article Signup organism
 */

.o-articleSignup {
    $block: &;

    align-items: center;
    background-color: color('white');
    border: border-width('base') solid color('black');
    border-radius: 0;
    box-shadow: none;
    color: color('black');
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: gutter('lg') gutter('sm');
    position: relative;
    text-align: center;
    width: 100%;

    @include breakpoint('md') {
        padding: gutter('xxxl') gutter('xxxl');
    }

    &--greenLight {
        background-color: color('green');
        border-color: color('green');
        color: color('white');
    }

    &--primary {
        background-color: color('primary');
        border-color: color('primary');
        color: color('white');
    }

    &--secondary {
        background-color: color('secondary');
        border-color: color('secondary');
        color: color('white');
    }

    &__title {
        margin-bottom: gutter('sm');
    }

    &__input {
        margin-top: gutter('sm');
    }

    &__content {
        display: flex;
        flex-direction: column;
    }
}
