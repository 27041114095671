/**
 * Property Map organism
 */

.o-propertyMap {
    display: flex;
    position: relative;

    &__map {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
    }

    .mapboxgl-canvas {
        &:focus {
            outline: none;
        }
    }
}
