.o-availableBuyers {
    overflow: hidden;

    @include breakpoint('md') {
        padding: gutter('xxxl') 0;
    }

    &__buyersCount {
        font-size: rem(100);
        line-height: 1;
    }

    &__title {
        margin: gutter('xxs') 0;
    }

    &__ctaButton {
        margin-top: gutter();
    }

    &__text {
        @include lora();
    }

    &__content {
        padding-top: 27rem;

        @include breakpoint('md') {
            padding: 31.875rem 0 0;
        }
    }

    .u-col-5 {
        @include breakpoint('sm', 'down') {
            max-width: 100%;
            flex: 0 0 100%;

            padding: 0;
        }
    }

    &__images {
        width: 0;
        position: relative;
        z-index: -1;
        top: 0;
        left: 50%;
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 11.5vw;
        max-width: 200px;
        height: auto;
        border-radius: border-radius('round');

        &:nth-child(1) {
            left: -52vw;
            top: 56.75rem;
            width: 18.2vw;
            max-width: 310px;

            @include breakpoint('xxl') {
                left: -104rem;
            }
            @include breakpoint('md', 'down') {
                display: none;
            }
            @include breakpoint('sm', 'down') {
                display: block;
            }
        }
        &:nth-child(2) {
            top: 13.375rem;
            left: -88.5rem;
        }
        &:nth-child(3) {
            top: 4.625rem;
            left: -22rem;
        }
        &:nth-child(4) {
            top: 68rem;
            left: 35.5rem;
            width: 18.2vw;
            max-width: 310px;
        }
        &:nth-child(5) {
            top: -3rem;
            left: 43.5rem;
            width: 18.2vw;
            max-width: 310px;
        }
        &:nth-child(6) {
            top: 41rem;
            left: 9.5rem;
        }
        &:nth-child(7) {
            top: 55rem;
            left: 39vw;

            @include breakpoint('xxl') {
                left: 78rem;
            }
        }

        @include breakpoint('md', 'down') {
            &:nth-child(4) {
                display: none;
            }
        }

        @include breakpoint('sm', 'down') {
            width: rem(125);

            &:nth-child(1) {
                left: calc(0px - 12vw);
                top: 30px;
                width: rem(200);
            }
            &:nth-child(2) {
                top: 0px;
                left: -52vw;
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                display: none;
            }
        }
    }
    &__image-below {
        display: flex;
        justify-content: flex-end;

        .o-availableBuyers__image--last {
            width: 11.5vw;
            max-width: rem(200);
            height: auto;
            border-radius: border-radius('round');
            transform: translate(63px, 40px);

            &:nth-child(2) {
                display: none;
            }
        }

        @include breakpoint('sm', 'down') {
            position: relative;
            display: flex;
            justify-content: left;
            padding-top: 65px;

            .o-availableBuyers__image--last {
                width: 200px;
                transform: none;

                &:nth-child(2) {
                    position: absolute;
                    right: -45px;
                    top: 20px;
                    width: 125px;
                    display: block;
                }
            }
        }
    }
}
