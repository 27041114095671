/**
 * IMG atom
 */

img {
    height: auto;
    width: 100%;
}

.a-image {
    @extend img;
}
