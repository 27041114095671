.o-propertyPresentationSimProperties {
    &__hero {
        display: flex;
        gap: gutter('xs');
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            align-items: center;
            justify-content: space-between;
            margin-bottom: gutter('lg');
        }
    }

    &__buttons {
        justify-content: flex-end;
    }

    &__button {
        text-align: center;
        width: 100%;
    }

    &__disallowWordBreak {
        white-space: nowrap;
    }

    &__heroHeading {
        flex-grow: 1;
    }

    &__propertyPreviewsWrapper {
        @include breakpoint('md', 'down') {
            margin-bottom: gutter('sm');
        }
    }

    &__propertyPreview {
        margin-bottom: gutter();

        @include breakpoint('md', 'down') {
            margin-bottom: gutter('xs');
        }
    }
}
