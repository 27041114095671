.o-brokerSalesValuation {
    $block: &;

    align-items: center;
    background-color: color('green');
    display: flex;
    justify-content: center;
    padding: gutter('xxl') gutter('md');
    text-align: center;
    width: 100%;

    @include breakpoint('md') {
        padding: 14rem 18rem;
    }

    &__trumpet {
        margin-bottom: gutter('xs');
    }

    &__headline {
        line-height: line-height('h2');
        margin-bottom: gutter('sm');

        @include breakpoint('md') {
            line-height: line-height('h1');
            margin-bottom: gutter('lg');
        }
    }

    // &__body {
    //     margin-bottom: gutter();
    // }

    &__stepWrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        &--confirmation {
            gap: gutter('xs');

            @include breakpoint('md') {
                align-items: center;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: gutter('xxl');
                height: 100%;
            }
        }
    }

    &__inputWrapper {
        display: flex;
        flex-direction: column;
        gap: gutter('xs');
        margin-top: gutter();
        width: 100%;

        @include breakpoint('md') {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__consent {
        padding-bottom: gutter();
        padding-top: gutter('xxs');
    }

    &__input {
        flex: 1;
        text-align: left;

        @include breakpoint('md') {
            flex-basis: 30rem;
        }

        @include breakpoint('lg') {
            flex-basis: 26rem;
        }

        &--dawa {
            margin-right: 0;
            width: unset;
        }
    }

    &__navigation {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__confirmationCheckmark {
        align-items: center;
        background-color: color('greenCustom');
        border-radius: border-radius('round');
        display: flex;
        height: gutter('xxl');
        justify-content: center;
        margin-bottom: gutter('xs');
        padding: 0 gutter('xs');
        width: gutter('xxl');

        @include breakpoint('md') {
            padding: 0;
        }
    }
}
