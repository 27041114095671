/**
 * Neighborhood Map organism
 */

.o-neighborhoodMap {
    display: flex;
    position: relative;
    scroll-margin-block-end: 4rem;

    &__map {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
    }

    .mapboxgl-canvas {
        &:focus {
            outline: none;
        }
    }
}
