.o-keyNumbers {
    align-items: center;
    display: flex;
    flex-direction: column;

    &__headline {
        margin-bottom: gutter('xs');
        order: 1;
    }

    &__text {
        order: 2;
        text-align: center;
    }

    &__wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 0 gutter();
        justify-content: center;

        @extend .u-container;
    }

    &__ctaButton {
        margin-top: gutter('sm');
        order: 3;

        @include breakpoint('md') {
            margin-top: gutter();
        }
    }

    &__numbersWrapper {
        display: flex;
        flex-direction: column;
        gap: gutter('sm');
        margin-top: gutter('sm');
        order: 5;

        @include breakpoint('md') {
            flex-direction: row;
            gap: gutter();
            margin-top: gutter('lg');
            order: 4;
        }
    }

    &__numbers {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: gutter('xs');
        justify-content: space-between;
        padding: gutter();
        width: 100%;

        @include breakpoint('md') {
            gap: gutter('xl');
        }
    }

    &__numbers:first-child {
        background-color: color('greenPastel-light');
        color: color('black');
    }

    &__numbers:nth-child(2) {
        background-color: color('green');
        color: color('white');
    }

    &__numbers:last-child {
        background-color: color('green-dark');
        color: color('white');
    }

    &__note {
        @include lora();

        margin-top: gutter('sm');
        order: 4;
        text-align: center;

        @include breakpoint('md') {
            order: 5;
        }
    }
}
