/**
 * Broker card organism
 */

.o-employeeShopCard {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    color: color('black');
    padding: gutter('lg') gutter('xs');
    position: relative;

    &__tag {
        background-color: color('black');
        border-radius: 10px 10px 0 0;
        bottom: 100%;
        color: color('white');
        font-size: font-size('label');
        left: 4rem;
        line-height: 1;
        padding: gutter('xxs') gutter('xs');
        position: absolute;
        top: auto;
    }

    &__wrapper {
        --portraitSize: 20rem;

        align-items: center;
        display: flex;
        flex-direction: column;
    }

    &__portrait {
        flex: none;
        height: var(--portraitSize);
        position: relative;
        width: var(--portraitSize);
    }

    &__content {
        margin-left: gutter('xs');
        text-align: center;
        width: 100%;

        @include breakpoint('md') {
            margin-left: 0;
            margin-top: gutter('xs');
        }
    }

    &__image {
        border-radius: border-radius('round');
        display: block;
        height: inherit;
        object-fit: cover;
        width: inherit;
    }

    &__actionButton {
        justify-content: center;
        min-width: 5rem;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__name {
        display: block;
        flex: none;
        font-weight: font-weight('medium');
        margin-top: gutter('xs');
        width: 100%;

        @include breakpoint('md') {
            margin-top: 0;
        }
    }

    &__title {
        display: block;
    }

    &__info {
        font-size: font-size('label');
        line-height: line-height('label');
        margin-bottom: gutter('xs');
        margin-top: gutter('xs');

        @include owl(4px);
    }
}
