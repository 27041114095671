/**
 * Estimate Graph organism
 */

.o-estimateGraph {
    margin-bottom: gutter('xxxl');

    &:last-of-type {
        margin-bottom: 0;
    }

    &__header {
        align-items: stretch;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        margin-bottom: gutter();

        @include breakpoint('md') {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__headerTitle {
        margin: 0;
    }

    &__legend {
        margin: gutter('xs') 0 0 0;

        @include breakpoint('md') {
            margin: 0 0 0 gutter('xs');
        }
    }
}
