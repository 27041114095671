/**
 * Property Movie Grid organism
 */

.o-movieMosaicWrapper {
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
}

.o-movieMosaic {
    $block: &;

    border-radius: border-radius('large');
    display: grid;
    gap: 2px;
    grid-column: 1/-1;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 46rem;
    position: relative;

    @include breakpoint('lg') {
        grid-column: 2/-2;
        height: 66rem;
    }

    &.hasTwo {
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr;

        @include breakpoint('lg') {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr;
        }
    }

    &.hasThree {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 1fr;

        @include breakpoint('lg') {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }

    &__image {
        border-radius: border-radius('large');
        grid-column: 1;
        grid-row: 1;
        overflow: hidden;
        position: relative;

        #{$block}.hasTwo & {
            &:nth-of-type(1) {
                border-radius: border-radius('large') border-radius('large') 0 0;

                @include breakpoint('lg') {
                    border-radius: border-radius('large') 0 0
                        border-radius('large');
                }
            }

            &:nth-of-type(2) {
                border-radius: 0 0 border-radius('large') border-radius('large');
                grid-row: 2;

                @include breakpoint('lg') {
                    border-radius: 0 border-radius('large')
                        border-radius('large') 0;
                    grid-column: 2;
                    grid-row: 1;
                }
            }
        }

        #{$block}.hasThree & {
            &:nth-of-type(1) {
                border-radius: border-radius('large') border-radius('large') 0 0;
                grid-column: 1 / span 2;

                @include breakpoint('lg') {
                    border-radius: border-radius('large') 0 0
                        border-radius('large');
                    grid-column: 1;
                    grid-row: 1 / span 2;
                }
            }

            &:nth-of-type(2) {
                border-radius: 0 0 0 border-radius('large');
                grid-row: 2;

                @include breakpoint('lg') {
                    border-radius: 0 border-radius('large') 0 0;
                    grid-column: 2;
                    grid-row: 1;
                }
            }

            &:nth-of-type(3) {
                border-radius: 0 0 border-radius('large') 0;
                grid-column: 2;
                grid-row: 2;

                @include breakpoint('lg') {
                    border-radius: 0 0 border-radius('large') 0;
                    grid-column: 2;
                    grid-row: 2;
                }

                .o-movieMosaic__highlight {
                    display: none;
                }
            }
        }

        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }

    &__imageLink {
        bottom: 0;
        cursor: pointer;
        display: flex;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.25s ease-in-out;
        width: 100%;

        &::before {
            background: linear-gradient(
                181.34deg,
                rgba(0, 0, 0, 0.295) 2.98%,
                rgba(0, 0, 0, 0) 36.9%
            );
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &__highlight {
        align-items: center;
        border-radius: border-radius();
        color: color('white');
        display: flex;
        left: 2rem;
        pointer-events: none;
        position: absolute;
        top: 2rem;

        svg {
            margin-right: 1rem;
        }
    }

    &__imageLinkOverlay {
        background: rgba(0, 0, 0, 0.5);
        bottom: 0;
        color: color('white');
        display: grid;
        left: 0;
        place-content: center;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__imagesButton {
        border: none;
        bottom: 0;
        height: 7rem;
        justify-content: center;
        padding: 0;
        position: absolute;
        right: 7rem;
        transform: translateY(50%);
        transition: background-color speed() easing();
        width: 7rem;

        @include breakpoint('md') {
            height: 9rem;
            right: 19rem;
            width: 9rem;

            .a-icon {
                height: 4rem;
            }
        }

        @mixin hover-focus {
            background-color: color('secondary:hover');
        }
    }
}
