/**
 *  Table Row atom
 */

.a-tableRow {
    font-size: font-size('label');

    &:nth-child(2n) {
        background-color: color('gray-light');
    }
}
