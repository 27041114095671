.o-homeEstimateResultHero {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: gutter('xs');
    position: relative;

    @include breakpoint('lg') {
        padding-top: 0;
    }

    &__kicker {
        margin-bottom: gutter('xs');
    }

    &__headline {
        text-align: center;
    }

    &__wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 0 gutter();
        justify-content: center;

        @extend .u-container;
    }

    &__imgLeft,
    &__imgRight {
        bottom: gutter('lg');
        display: none;
        position: absolute;
        width: 40rem;
        z-index: -1;

        @include breakpoint('md') {
            display: flex;
        }
    }

    &__imgLeft {
        left: 0;
    }

    &__imgRight {
        right: 0;
    }

    &__resultBarWrapper {
        margin: gutter('sm') 0 gutter('md');
        padding: 0;

        @include breakpoint('md') {
            margin: gutter() 0 gutter('xl');
        }
    }

    &__estimatesRange {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: gutter('sm') / 2;
    }

    &__responsibleBroker {
        padding: 0;
    }

    &__resultBar {
        align-items: center;
        background:
            linear-gradient(
                270deg,
                color('green') 0%,
                color('yellow') 100%
            );
        border-radius: border-radius('large') * 3;
        display: flex;
        height: gutter('lg');
        position: relative;

        @include breakpoint('md') {
            height: gutter('xl');
        }
    }

    &__resultLine {
        animation: resultLineMovement 1800ms cubic-bezier(0.35, 0.01, 0.61, 1) alternate infinite;
        background-color: color('black');
        border-radius: border-radius('normal');
        height: gutter('sm') * 2;
        left: 15%;
        position: absolute;
        width: gutter('xxs') / 2;

        @include breakpoint('md') {
            height: gutter('md') * 2;
        }
    }

    @keyframes resultLineMovement {
        from {
            left: 15%;
        }

        to {
            left: 85%;
        }
    }
}
