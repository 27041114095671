/**
 * Fonts
 */

//https://webdesign.tutsplus.com/tutorials/how-to-self-host-google-fonts--cms-34775
//https://google-webfonts-helper.herokuapp.com/fonts//* lora-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    src:
        local(''),
        url('/assets/fonts/lora-v26-latin-regular.woff2') format('woff2'),
        url('/assets/fonts/lora-v26-latin-regular.woff') format('woff');
}

/* lora-500 - latin */
@font-face {
    font-display: swap;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    src:
        local(''),
        url('/assets/fonts/lora-v26-latin-500.woff2') format('woff2'),
        url('/assets/fonts/lora-v26-latin-500.woff') format('woff');
}

/* lora-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    src:
        local(''),
        url('/assets/fonts/lora-v26-latin-700.woff2') format('woff2'),
        url('/assets/fonts/lora-v26-latin-700.woff') format('woff');
}

/* lora-italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Lora';
    font-style: italic;
    font-weight: 400;
    src:
        local(''),
        url('/assets/fonts/lora-v26-latin-italic.woff2') format('woff2'),
        url('/assets/fonts/lora-v26-latin-italic.woff') format('woff');
}

/* lora-500italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Lora';
    font-style: italic;
    font-weight: 500;
    src:
        local(''),
        url('/assets/fonts/lora-v26-latin-500italic.woff2') format('woff2'),
        url('/assets/fonts/lora-v26-latin-500italic.woff') format('woff');
}

/* lora-700italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Lora';
    font-style: italic;
    font-weight: 700;
    src:
        local(''),
        url('/assets/fonts/lora-v26-latin-700italic.woff2') format('woff2'),
        url('/assets/fonts/lora-v26-latin-700italic.woff') format('woff');
}

/* poppins-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src:
        local(''),
        url('/assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'),
        url('/assets/fonts/poppins-v20-latin-regular.woff') format('woff');
}

/* poppins-italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src:
        local(''),
        url('/assets/fonts/poppins-v20-latin-italic.woff2') format('woff2'),
        url('/assets/fonts/poppins-v20-latin-italic.woff') format('woff');
}

/* poppins-500 - latin */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src:
        local(''),
        url('/assets/fonts/poppins-v20-latin-500.woff2') format('woff2'),
        url('/assets/fonts/poppins-v20-latin-500.woff') format('woff');
}

/* poppins-500italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src:
        local(''),
        url('/assets/fonts/poppins-v20-latin-500italic.woff2') format('woff2'),
        url('/assets/fonts/poppins-v20-latin-500italic.woff') format('woff');
}

/* poppins-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src:
        local(''),
        url('/assets/fonts/poppins-v20-latin-700.woff2') format('woff2'),
        url('/assets/fonts/poppins-v20-latin-700.woff') format('woff');
}

/* poppins-700italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src:
        local(''),
        url('/assets/fonts/poppins-v20-latin-700italic.woff2') format('woff2'),
        url('/assets/fonts/poppins-v20-latin-700italic.woff') format('woff');
}
