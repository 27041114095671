/**
 * Neighborhood page map organism
 */
.o-neighborhoodPageMap {
    $block: &;

    background-color: color('white');
    height: 60rem;
    width: 100%;
}
