/**
 * Sticky button organism
 */

.o-stickyButton {
    $block: &;

    border: 0;
    bottom: gutter('xxs');
    display: flex;
    flex: 0 0 auto;
    font-size: 10px;
    gap: 1.6em;
    height: 5em;
    max-width: calc(100vw - 2rem);
    overflow: hidden;
    padding-left: 1.4em;
    padding-right: 0;
    position: fixed;
    right: gutter('xxs');
    transition-property: background-color, opacity, transform;
    z-index: 8;

    @include breakpoint('sm', 'down') {
        font-size: 9px;
    }

    @include breakpoint('lg') {
        bottom: gutter('xs');
        right: gutter('xs');
    }

    &.noImage {
        padding-right: 2.4em;
    }

    &__menuIcon {
        align-items: center;
        display: flex;
        flex: none;
        flex-direction: column;
        height: 2.4em;
        justify-content: center;
        margin-right: -1em;
        width: 2.4em;

        svg {
            height: inherit;
            width: inherit;
        }
    }

    &__text {
        font-size: 1.4em;
    }

    &__image {
        flex: none;
        padding: 0.4em;
        padding-left: 0;

        img {
            aspect-ratio: 1;
            border-radius: border-radius('round');
            display: block;
            height: calc(5em - (2 * 0.4em));
            object-fit: cover;
            width: calc(5em - (2 * 0.4em));
        }
    }
}
