/**
 * Property Presentation Broker organism
 */

.o-propertyPresentationBroker {
    $block: &;

    &__content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: gutter('sm');

        @include breakpoint('md') {
            height: 100%;
            margin-top: 0;
        }

        @include owl();
    }
}
