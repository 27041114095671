/**
 * Doughnut Chart molecule
 */

.m-doughnutChart {
    height: 100%;

    //@include breakpoint('md') {
    //    height: 50rem;
    //}

    &__wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    &__doughnut {
        @include breakpoint('md') {
            margin-top: auto;
        }
    }

    &__legendWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: gutter('xxs');
        justify-content: center;
        margin-top: gutter('lg');
        width: 100%;

        @include breakpoint('md') {
            flex-wrap: nowrap;
            gap: 0;
            justify-content: space-between;
        }
    }

    &__legend {
        display: flex;
        flex-flow: row nowrap;
    }

    &__legendColor {
        border-radius: border-radius('round');
        flex: 0 0 auto;
        flex-shrink: 0;
        height: gutter('xs');
        width: gutter('xs');
    }

    &__legendText {
        margin-left: gutter('xxs');
    }
}
