.o-neighborhoodPropertySale {
    &__property {
        margin-bottom: gutter('xs');

        @include breakpoint('lg') {
            margin-bottom: 0;

            &:not(:last-child) {
                margin-bottom: gutter();
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
    }

    &__types {
        border: border-width() solid color('black');
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 41rem;
        padding: gutter();

        @include breakpoint('xs') {
            min-height: unset;
        }

        @include breakpoint('md') {
            min-height: 39.375rem;
        }

        @include breakpoint('md', 'down') {
            margin-bottom: gutter('xs');
        }
    }

    &__typesType {
        display: flex;
        flex-direction: row;
        font-size: font-size('lead');
        margin-bottom: gutter('xs');

        & > :last-child {
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__typesTypeCount {
        min-width: gutter('xl');
    }

    &__typesSeeAllRow {
        margin-top: auto;
    }

    &__typesSeeAll {
        text-align: center;
        width: 100%;
    }
}
