.m-statusIndicator {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    &__item {
        background-color: color('green');
        border: border-width('small') solid color('green');
        border-radius: border-radius('round');
        height: 14px;
        position: relative;
        width: 14px;

        &::after {
            background-color: color('green');
            border-radius: border-radius('round');
            content: '';
            height: 7px;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 0.25s ease-out 0.2s;
            width: 7px;
        }

        &::before {
            background-color: color('green');
            content: '';
            height: 0.125rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateX(100%);
            transition: width 0.2s ease-out;
            width: 1.125rem;
        }

        &.is-active {
            background-color: transparent;

            &::after {
                opacity: 1;
            }

            &::before {
                width: 0;
            }

            & ~ .m-statusIndicator__item {
                background-color: transparent;

                &::before {
                    width: 0;
                }
            }
        }

        &:last-child {
            &::before {
                width: 0;
            }
        }

        &:focus-visible {
            opacity: 0.5;
        }
    }
}
