/**
 * Organism broker team
 */

.o-brokerTeam {
    $block: &;

    @include breakpoint('md') {
        margin-bottom: 10rem;
    }

    &__hero {
        margin-bottom: 0; // gutter('lg');

        @include breakpoint('md') {
            margin: 0 30rem gutter('xs') 0;
        }
    }

    &__heroTitle {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: gutter();
        }

        @include breakpoint('sm', 'down') {
            font-size: font-size('h3');
            line-height: line-height('h3');
        }
    }

    &__hideBrokers {
        @include breakpoint('sm', 'down') {
            filter: drop-shadow(0 0 6rem rgba(#000, 0.1));
        }

        @include breakpoint('md') {
            max-height: 80rem;
            overflow: hidden;
            position: relative;
            transition: max-height 3s ease-in-out;
        }

        &.expanded {
            max-height: 11000px;

            #{$block}__expander {
                opacity: 0;
                pointer-events: none;
                visibility: hidden;
            }
        }

        &.isNotExpandable {
            max-height: none;
        }
    }

    &__brokerList {
        display: flex;
        flex-wrap: nowrap;
        gap: gutter('sm');
        padding-bottom: gutter('lg');
        padding-top: gutter('xxl');

        @include breakpoint('sm', 'down') {
            padding-bottom: 0;
        }

        @include breakpoint('md', 'down') {
            flex-wrap: nowrap;
            margin: 0 -4rem;
            overflow-x: auto;
            overscroll-behavior: contain;
            padding-inline-end: 4rem;
            padding-inline-start: 4rem;
            scroll-padding-inline-end: 4rem;
            scroll-padding-inline-start: 4rem;
            scroll-snap-type: x mandatory;
            scrollbar-width: none;
        }

        @include breakpoint('md') {
            display: grid;
            gap: gutter('md');
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            overflow-x: unset;
        }

        .o-employeeShopCard {
            background-color: color('white');
            box-shadow: box-shadow();

            @include breakpoint('sm', 'down') {
                box-shadow: none;
                flex: none;
                max-width: 286px;
                min-width: 65vw;
                scroll-snap-align: center;
            }

            .o-employeeShopCard__wrapper {
                flex-direction: column;
                height: 100%;
            }

            .o-employeeShopCard__brokerPortrait {
                @include breakpoint('sm', 'down') {
                    max-width: 172px;
                    width: 100%;
                }
            }

            .o-employeeShopCard__image {
                @include breakpoint('sm', 'down') {
                    max-width: none;
                }
            }
        }

        .o-employeeShopCard .o-employeeCard__content {
            display: flex;
            flex-direction: column;
            height: 100%;
            margin-left: 0;
            margin-top: gutter('sm');
            text-align: center;
            width: 100%;

            .m-button {
                align-self: center;
                margin-top: 2rem;
            }
        }

        #{$block}--noHeadline & {
            padding-top: 5rem;
        }

        &--lessThanFour {
            @include breakpoint('md') {
                display: flex;
                flex-wrap: wrap;
                gap: gutter('md');
                justify-content: center;
            }

            .o-employeeShopCard {
                @include breakpoint('md') {
                    flex: 1;
                    max-width: 268px;
                    min-width: 240px;
                }
            }
        }
    }

    &--noHeadline {
        margin-top: -5rem;
    }

    &__expander {
        @include breakpoint('md') {
            background-color: color('white');
            border-top: border-width() solid color('black');
            bottom: 0;
            content: '';
            display: block;
            height: 3.5rem;
            left: 2rem;
            opacity: 1;
            position: absolute;
            right: 2rem;
            transition: all 0.3s ease-in-out;
            visibility: visible;
        }

        &--yellow {
            @include breakpoint('md') {
                background-color: color('paperYellow-light');
            }
        }
    }

    &__expanderButton {
        align-items: center;
        background-color: color('secondary');
        border-radius: border-radius('round');
        bottom: 0;
        color: color('white');
        display: none;
        height: 7rem;
        justify-content: center;
        left: 50%;
        opacity: 1;
        position: absolute;
        transform: translateX(-50%);
        transition: opacity 0.3s ease-in-out;
        width: 7rem;
        z-index: 1;

        @include breakpoint('md') {
            display: flex;
        }

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: color('secondary:hover');
        }
    }
}
