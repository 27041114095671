/**
 * Section Hero With USP organism
 */

.o-sectionHeroWithUsp {
    $block: &;

    display: grid;
    grid-template-rows: auto auto;
    padding-top: var(--iconHeight, 0px);
    position: relative;

    &.no-headline.has-icon {
        padding-top: var(--iconHeight, 0px);
    }

    &.has-gap {
        margin-bottom: gutter('sm');
    }

    &__headline {
        grid-column: 1/-1;
        grid-row: 1/2;
        margin-bottom: 3rem;

        @include breakpoint('md') {
            margin-bottom: 0;
            max-width: clamp(300px, 50vw, 720px);
        }

        .has-icon & {
            margin-bottom: 7rem;

            @include breakpoint('md') {
                margin-bottom: 0;
            }
        }

        .has-gap & {
            margin-bottom: 0;
        }
    }

    &__mediaImage {
        display: block;
        grid-column: 1/-1;
        grid-row: 2/-2;

        @include breakpoint('md') {
            --path: polygon(0% 64px, clamp(2rem, 50vw, 720px) 64px, clamp(2rem, 50vw, 720px) 0%, 100% 0%, 100% 100%, 0% 100%);

            -webkit-clip-path: var(--path);
            clip-path: var(--path);
            max-height: 75rem;
            object-fit: cover;
            object-position: var(--focalPoint);
        }
    }

    &__icon {
        --size: 17vw;

        grid-row: 2/3;
        height: clamp(96px, var(--size), 220px);
        object-fit: cover;
        position: absolute;
        right: clamp(2rem, 9vw, 15rem);
        transform: translateY(-50%);
        width: clamp(96px, var(--size), 220px);
        z-index: 2;

        @include breakpoint('sm', 'down') {
            right: 2rem;
        }
    }

    &.no-headline {
        #{$block}__mediaImage {
            clip-path: none;
        }
    }

    &.has-image + .o-uspGrid {
        margin-top: -6rem;

        @include breakpoint('md', 'down') {
            padding: 0 6rem;
            scroll-padding: 0 6rem;
        }

        @include breakpoint('md') {
            margin-top: -11rem;
            padding: 0 6rem;
        }

        @include breakpoint('lg') {
            margin-top: -16rem;
            padding: 0 11rem;
        }
    }
}
