/**
 * Front Page Hero organism
 */

.o-frontPageHero {
    $block: &;

    .u-container {
        @include breakpoint('md', 'down') {
            max-width: 120rem;
        }
    }

    &__contentContainer {
        margin-bottom: -4rem;
        position: relative;
        z-index: 1;

        @include breakpoint('md') {
            margin-bottom: -8rem;
        }
    }

    &__content {
        background-color: #fff;
        margin-left: -3rem;
        padding-bottom: 3rem;
        padding-left: 5rem;

        @include breakpoint('md') {
            margin-left: 0;
            padding-bottom: 6rem;
            padding-left: 10rem;
        }

        @include breakpoint('lg') {
            padding-left: 14.5rem;
        }
    }

    &__title {
        @include lora();

        font-size: clamp(42px, 7vw, 80px);
        max-width: 17ch;
    }

    &__description {
        display: none;
    }

    &__media {
        background-color: color('gray-light');
        left: -1rem;
        right: -1rem;
        width: calc(100% + 2rem);

        @include breakpoint('md') {
            left: 0;
            right: 0;
            width: auto;
        }

        img {
            display: block;

            @include breakpoint('sm', 'down') {
                aspect-ratio: 398/187;
                object-fit: cover;
            }
        }
    }

    // &__linkGridContainer {
    //     text-align: center;
    // }

    &__linkGrid {
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 1fr;
        margin-left: auto;
        margin-right: auto;
        margin-top: -2rem;
        padding: 0 2rem;
        position: relative;
        text-align: left;
        z-index: 1;

        @include breakpoint('md') {
            gap: 4rem;
            padding: 0;
        }

        @include breakpoint('lg') {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin-top: -4rem;
        }

        &__link {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: box-shadow('normal');
            display: flex;
            flex-direction: column;
            font-size: font-size('label');
            gap: 1rem;
            line-height: 1.4;
            padding: 3rem 2rem;

            &:focus-visible {
                outline: solid 2px #3fa9f5;
                outline-offset: -2px;
            }

            &:hover {
                box-shadow: box-shadow('normal:hover');
            }

            @include breakpoint('lg') {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                padding: 4rem;
            }

            .a-icon {
                display: block;
            }

            * {
                pointer-events: none;
            }
        }

        // &__linkDescription {
        //  max-width: 21ch;
        // }

        &__linkText {
            color: #3fa9f5;
            margin-top: auto;
            text-decoration: underline;
        }
    }
}
