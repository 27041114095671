/**
 * Broker template
 */

.t-broker {
    margin-top: gutter();

    @include breakpoint('sm', 'down') {
        margin-top: gutter('xs');
    }
}
