.o-brokerContact {
    &__container {
        display: flex;
        justify-content: center;
        padding: 0;
    }

    &__wrapper {
        display: grid;

        @include breakpoint('md') {
            grid-template-columns: 5fr 7fr;
        }
    }

    &__employeeCard {
        container-type: normal;
    }

    &__employeeCard .o-employeeCard__wrapper {
        --portraitSize: 20rem;

        flex-direction: column;
    }

    &__employeeCard .o-employeeCard__content {
        text-align: center;
        width: 100%;
    }

    &__employeeCard .o-employeeCard__infoPhone {
        font-size: font-size('label');
    }

    &__responsibleBroker {
        align-items: center;
        background-color: color('green-dark');
        display: flex;
        justify-content: center;
        padding: gutter();

        @include breakpoint('md') {
            padding: gutter('md') * 2 gutter('lg') * 2 gutter('xxxl');
        }
    }

    &__formWrapper {
        background-color: color('green');
        color: color('white');
        padding: gutter();

        @include breakpoint('md') {
            padding: gutter('md') * 2 gutter('lg') * 2 gutter('xxxl');
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: gutter('sm');
    }

    &__formInput input {
        background-color: color('white');
    }

    &__textareaInput {
        &::before {
            display: none;
        }

        textarea {
            padding: gutter('sm') / 2;
        }
    }

    &__text {
        @include lora();

        margin: gutter('sm') 0 gutter('sm');
    }

    &__formSubmitButton {
        margin-top: gutter('sm');
    }

    &__formSuccessHeadline {
        margin: gutter('sm') 0 gutter('xs');
    }

    &__formSuccessText {
        @include lora();
    }

    &__formSuccessWrapper {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        text-align: center;
    }

    &__formSuccess {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__icon {
        align-items: center;
        background-color: color('greenCustom');
        border-radius: border-radius('round');
        color: color('black');
        display: flex;
        height: gutter('xxl');
        justify-content: center;
        width: gutter('xxl');
    }
}
