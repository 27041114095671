/**
 * Icon atom
 */

.a-icon {
    background-color: transparent;
    fill: currentColor;
    height: 3rem;
    pointer-events: none;
    transition: fill speed() easing();
    width: auto;

    &--favorite {
        .cls-1 {
            fill: color('favoriteOrange');
            transition: all 1s ease-out;
        }

        .cls-2 {
            clip-rule: evenodd;
            fill: none;
        }

        .cls-3 {
            fill-rule: evenodd;
            fill: #000;
            transition: all 1s ease-out;
        }

        .cls-4 {
            clip-path: url(#clippath);
        }
    }
}

.isFavorite .a-icon--favorite {
    .cls-3 {
        fill: color('favoriteOrange');
    }

    .cls-1 {
        y: 0;
    }
}
