/**
 * Map molecule
 */
// @import '../node_modules/mapbox-gl/dist/mapbox-gl.css';
// @import '../node_modules/vue-mapbox/dist/vue-mapbox.css';

@import '../node_modules/maplibre-gl/src/css/maplibre-gl.css';

.map-wrapper {
    height: 100%;
}

.m-map {
    $block: &;

    overflow: hidden;

    &__marker {
        align-items: center;
        background-color: color('green-dark');
        border-radius: border-radius();
        color: color('white');
        cursor: pointer;
        display: inline-flex;
        flex-direction: row;
        font-family: font-family();
        font-size: font-size('label');
        font-weight: font-weight('medium');
        gap: 0.5rem;
        justify-content: center;
        margin: 0 0 1.75rem;
        min-width: 3.5rem;
        padding: 0.625rem gutter('xxs');
        position: relative;
        text-align: center;
        transition: speed() easing();
        transition-property: color, background-color, box-shadow;
        white-space: nowrap;
        z-index: 0;

        &::before {
            background-color: inherit;
            border-radius: border-radius();
            bottom: -1rem;
            box-shadow: box-shadow('small');
            content: '';
            height: 2.5rem;
            left: 50%;
            position: absolute;
            transform: translateX(-50%) rotate(45deg);
            width: 2.5rem;
            z-index: -1;
        }

        &:not(.m-map__marker--broker) {
            [mouse] &:hover,
            &.isHovered,
            &.isOpen {
                background-color: color('secondary');
            }
        }

        &.isOpen {
            color: color('white');
        }

        &--address {
            pointer-events: none;
        }

        &--broker,
        &--favorite {
            background-color: color('white');
            color: color('black');

            [mouse] &:hover {
                color: color('white');
            }
        }

        &--poi {
            background-color: color('green');
            color: color('white');
            padding-inline: 0;
            padding-block: 0.5rem;
            transform: translateY(10px);

            [mouse] &:hover {
                color: color('white');
            }
        }

        &--broker {
            border: solid 2px #cecece;
            box-shadow: none;

            &::before {
                background-color: inherit;
                border: inherit;
                border-radius: 0 0 0.5rem 0;
                box-shadow: none;
                content: '';
                height: 2.5rem;
                left: 0;
                margin-inline: auto;
                margin-top: -1rem;
                position: absolute;
                right: 0;
                top: 100%;
                transform: rotate(45deg);
                width: 2.5rem;
                z-index: -1;
            }

            &::after {
                background-color: inherit;
                border: none;
                border-radius: 0 0 3px 0;
                box-shadow: none;
                content: '';
                height: 2.5rem;
                left: 0;
                margin-inline: auto;
                margin-top: -1.35rem;
                position: absolute;
                right: 0;
                top: 100%;
                transform: rotate(45deg);
                width: 2.5rem;
                z-index: 1;
            }

            [mouse] &:hover,
            &.isHovered,
            &.isOpen {
                background-color: color('primary');
            }
        }

        &--favorite {
            [mouse] &:hover {
                background-color: color('secondary');
            }
        }

        &--neighborhood {
            background-color: color('green');

            &::before {
                display: none;
            }

            [mouse] &:hover {
                background-color: color('green:hover');
                box-shadow: box-shadow('small:hover');
            }
        }

        &.hasOpenHouse.hasLongLabel {
            border-radius: border-radius() 0 0 border-radius();

            [mouse] &:hover {
                .m-map__marker--openHouse {
                    background-color: color('paperYellow:hover');
                    box-shadow: box-shadow('small:hover');
                }
            }
        }

        &.isSold {
            background-color: color('gray-dark');

            [mouse] &:hover,
            &.isHovered,
            &.isOpen {
                background-color: color('logoRed');
            }
        }

        &--openHouse {
            display: none;

            #{$block}__marker--property.hasOpenHouse.hasLongLabel & {
                align-items: center;
                background-color: color('paperYellow');
                border-radius: 0 border-radius() border-radius() 0;
                bottom: 0;
                color: color('black');
                display: flex;
                left: 100%;
                padding: 0 gutter('xxs');
                position: absolute;
                text-align: center;
                top: 0;
                transition: speed() easing();
                transition-property: background-color, box-shadow;
            }
        }

        &__favorite {
            svg {
                display: block;
                fill: color('favoriteOrange');
                height: 2rem;
                width: 2rem;
            }
        }
    }

    &__poiMarker.m-map__marker {
        display: none;
    }

    &__markerIcon {
        align-items: center;
        background-color: color('pink-dark');
        border-radius: border-radius('round');
        box-shadow: 0 0 0 0.75rem rgba(color('pink-dark'), 0.2);
        color: color('white');
        display: flex;
        height: 2rem;
        justify-content: center;
        position: absolute;
        right: -0.5rem;
        top: -1.5rem;
        width: 2rem;
        z-index: 1;

        svg {
            height: 1rem;
        }
    }

    &__markerImage {
        background-color: transparent;
        height: 2rem;
        position: relative;
        width: auto;
        z-index: 2;
    }

    .mapboxgl-marker,
    .maplibregl-marker {
        &.hidden {
            display: none;
        }
        &[data-type='broker'],
        &[data-type='favorite'],
        &[data-type='poi'] {
            filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.2));
        }

        &[data-type='property'] {
            z-index: 2;
        }

        &[data-type='address'] {
            z-index: 3;
        }

        &[data-hasproperties='true'] {
            // Used on the neighborhood pages
            display: none;
        }

        &.isSold[data-type='property'] {
            z-index: 1;
        }

        [mouse] &:hover,
        &.isHovered,
        &.isOpen {
            z-index: 4 !important;
        }
        &[data-type='broker'],
        &[data-type='neighborhood'],
        &[data-type='favorite'],
        &[data-type='poi'] {
            transform: translate(-50%, -100%);
        }
    }

    .mapboxgl-popup,
    .maplibregl-popup {
        width: 100vw;
        max-width: 39rem !important;
        z-index: 4;

        &:has(.o-pointOfInterestPreview) {
            max-width: 50rem !important;
        }

        @include breakpoint('sm', 'down') {
            top: auto;
            bottom: gutter('sm');
            left: gutter('sm');
            right: gutter('sm');
            margin-inline: auto;
            transform: none !important;
            width: auto;
            max-width: none !important;
        }
    }

    .mapboxgl-popup-tip,
    .maplibregl-popup-tip {
        display: none;
    }

    .mapboxgl-popup-content,
    .maplibregl-popup-content {
        background-color: transparent;
        border-radius: border-radius('none');
        box-shadow: none;
        padding: 0;
        width: 100%;
    }

    &__listWrapper {
        bottom: 0;
        display: flex;
        left: 0;
        margin: 0;
        padding: 0; // gutter('xs');
        position: absolute;
        right: 0;
        z-index: 5;
    }

    &__list {
        align-items: flex-start;
        display: flex;
        flex-wrap: nowrap;
        gap: 2rem;
        justify-content: flex-start;
        -ms-overflow-style: none;
        overflow-x: scroll;
        overflow-y: hidden;
        overscroll-behavior-x: contain;
        padding-bottom: 8rem;
        padding-top: 8rem;
        position: relative;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        padding-inline: 4rem;
        scroll-padding-inline: 4rem;

        &::-webkit-scrollbar {
            display: none;
        }

        // .t-search & {
        //     min-height: 21rem;
        // }

        &.isAnimating {
            transition: transform speed() easing();
        }
    }

    &__listItemWrapper {
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        position: relative;
        scroll-snap-align: center;
    }

    &__listItem {
        flex: 0 0 auto;
        // margin: gutter('xxs');
        width: calc(100vw - #{gutter() * 2});
    }

    &__listMultiple {
        align-items: center;
        background-color: color('white');
        border-bottom: 1px solid color('gray-light');
        border-radius: border-radius('round');
        bottom: 100%;
        // box-shadow: box-shadow('small:hover');
        display: flex;
        justify-content: space-between;
        left: gutter('xxs');
        margin-bottom: gutter('xxs');
        padding: gutter('xs');
        pointer-events: none;
        position: absolute;

        &.isAnimating {
            transition: transform speed() easing();
        }
    }
}
