$backgroundRemaining: #e5f0ef;

.o-homeEstimateSlider {
    margin-bottom: gutter('sm');
    position: relative;

    @include breakpoint('sm', 'down') {
        margin-bottom: 0;
        width: 100%;
    }

    &__con {
        position: relative;
        width: rem(661);

        @include breakpoint('sm', 'down') {
            width: 100%;
        }
    }

    &__dial-borders {
        height: rem(132);
        overflow: hidden;
        position: relative;
        width: 100%;

        @include breakpoint('sm', 'down') {
            margin-left: -2rem;
            width: calc(100% + 4rem);
        }
    }

    &__handle-path {
        height: rem(2400);
        left: 50%;
        pointer-events: none;
        position: absolute;
        transform: translateX(-50%) rotate(0deg);
        transform-origin: center;
        transition: transform 0.025s ease-out;
        width: rem(2400);

        @include breakpoint('sm', 'down') {
            height: 170rem;
            width: 170rem;
        }

        &.has-animation {
            transition-duration: 0.25s;
        }

        &__handle {
            background: white;
            border-radius: border-radius('round');
            box-shadow: box-shadow('normal');
            height: rem(117);
            left: 50%;
            position: absolute;
            top: 0;
            transform: translate(-50%, -50%);
            transition: none;
            width: rem(117);

            @include breakpoint('sm', 'down') {
                height: rem(78);
                width: rem(78);
            }

            &::before,
            &::after {
                background-color: transparent;
                border-color: transparent color('green') transparent transparent;
                border-radius: border-radius('none');
                border-style: solid;
                border-width: rem(10) rem(10) rem(10) 0;
                content: '';
                height: auto;
                left: 50%;
                position: absolute;
                top: 50%;
                transition: none;
                width: auto;

                @include breakpoint('sm', 'down') {
                    border-width: rem(7) rem(7) rem(7) 0;
                }
            }

            &::before {
                transform: translate(rem(-16), -50%);

                @include breakpoint('sm', 'down') {
                    transform: translate(-1.45rem, -50%);
                }
            }

            &::after {
                transform: translate(rem(5), -50%) rotate(180deg);

                @include breakpoint('sm', 'down') {
                    transform: translate(0.45rem, -50%) rotate(180deg);
                }
            }
        }
    }

    &__dial-con {
        height: rem(2400);
        left: 50%;
        position: absolute;
        transform-origin: center;
        transition: transform 0.35s ease-out;
        width: rem(2400);

        @include breakpoint('sm', 'down') {
            height: 200rem;
            margin-top: rem(-13);
            width: 200rem;
        }

        span {
            cursor: pointer;
        }

        circle {
            cursor: pointer;
            fill: color('gray-dark');
            transition: fill 0.25s ease;
        }

        &__circle-1,
        &__circle-2,
        &__circle-3,
        &__circle-4,
        &__circle-5,
        &__circle-6,
        &__circle-7,
        &__circle-8,
        &__circle-9,
        &__circle-10,
        &__circle-11 {
            stroke: transparent;
            stroke-width: 40px;
        }

        &__text-2,
        &__text-4,
        &__text-6,
        &__text-8,
        &__text-10 {
            color: color('gray-dark');
            display: flex;
            font-size: rem(21);
            height: 0;
            line-height: 1;
            position: absolute;
            transform-origin: center;
            transition: color 0.25s ease;
            width: 0;

            span {
                transform: translateX(-50%);
                white-space: nowrap;
            }

            @include breakpoint('sm', 'down') {
                font-size: font-size('label');
            }
        }

        &__text-2 {
            left: 35.8%;
            top: 1.5rem;
            transform: rotate(-16deg);

            @include breakpoint('sm', 'down') {
                top: -0.35em;
            }
        }

        &__text-4 {
            left: 42.8%;
            top: -3.3rem;
            transform: rotate(-8deg);

            @include breakpoint('sm', 'down') {
                top: -3.5rem;
            }
        }

        &__text-6 {
            left: 50%;
            top: -4.75rem;
            transform: translateX(-50%);

            @include breakpoint('sm', 'down') {
                top: -4.5rem;
            }
        }

        &__text-8 {
            left: 57.15%;
            top: -3.1rem;
            transform: rotate(8deg);

            @include breakpoint('sm', 'down') {
                top: -3.5rem;
            }
        }

        &__text-10 {
            left: 64.2%;
            top: rem(11);
            transform: rotate(16deg);

            @include breakpoint('sm', 'down') {
                top: -0.35em;
            }
        }

        @for $i from 1 through 11 {
            &--active-#{$i} {
                .o-homeEstimateSlider__dial-con__text-#{$i} {
                    color: color('black');
                }

                .o-homeEstimateSlider__dial-con__circle-#{$i} {
                    fill: black;
                }
            }
        }
    }

    &__arrow {
        left: calc(50% + 1px);
        margin: 0;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: rem(18);

        @include breakpoint('sm', 'down') {
            left: calc(50% + 1px);
            top: rem(-10);
            width: rem(13);
        }
    }

    &__grad-left,
    &__grad-right {
        height: 100%;
        left: auto;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: rem(72);
        z-index: 5;

        @include breakpoint('sm', 'down') {
            height: 60%;
        }
    }

    &__grad-left {
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        left: 0;
    }

    &__grad-right {
        background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        right: 0;
    }

    &__dial {
        height: 100%;
        overflow: visible;
        width: 100%;

        @include breakpoint('sm', 'down') {
            margin-top: rem(-12);
        }
    }

    &__slider-borders {
        margin: rem(-23) auto 0;
        position: relative;
        width: rem(549);
        z-index: 5;

        @include breakpoint('sm', 'down') {
            margin-top: -10rem;
            width: 88%;
        }
    }

    &__bg {
        background: linear-gradient(270deg, #006664 -100%, #ffe6b0 100%);
        height: 12.5rem;
        left: 0;
        position: absolute;
        top: rem(15);
        width: 68.625rem;
        pointer-events: none;

        @include breakpoint('sm', 'down') {
            height: 8rem;
            top: 3.4rem;
            width: 100%;
        }

        &__remaining {
            background: $backgroundRemaining;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            transition: width 0s ease;
            width: 100%;

            &.has-animation {
                transition-duration: 0.25s;
            }
        }
    }

    &__clippath {
        position: absolute;
    }

    .vue-slider {
        height: rem(100) !important;
        padding: 0 !important;
    }

    .vue-slider-dot-handle {
        height: rem(130);
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: none;
        width: rem(130);

        @include breakpoint('sm', 'down') {
            height: rem(78);
            transform: translate(
                calc(-50% + (#{var(--slider-dot-rotation)} * -2.6)),
                calc(-50% - (#{var(--slider-dot-rotation-abs)} * -2.6))
            );
            width: rem(78);
        }
    }

    .vue-slider-rail {
        background: none;

        &::after {
            content: '';
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }

    .vue-slider-process {
        background: none;
        border-radius: border-radius('none');
        transition-duration: 0.5s !important;

        &::after {
            content: '';
        }
    }

    /*********** Baseline, reset styles ***********/
    input[type='range'] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        width: 100%;
        height: 11.6rem;
    }

    /* Removes default focus */
    input[type='range']:focus {
        outline: none;
    }

    /******** Chrome, Safari, Opera and Edge Chromium styles ********/
    /* slider track */
    input[type='range']::-webkit-slider-runnable-track {
        background-color: transparent;
        border-radius: 8.5rem;
        height: 11.6rem;
    }

    /* slider thumb */
    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: var(--top, -69px); /* Centers thumb on the track */
        background-color: transparent;
        border-radius: 12rem;
        height: 14.625rem;
        width: 14.625rem;
        transform: translate(calc(var(--slider-dot-rotation) * 3.3), 0px);
        box-shadow: none;

        @media (max-width: 767.98px) {
            height: 9.75rem;
            transform: translate(
                calc(var(--slider-dot-rotation) * 0.7),
                calc(var(--slider-dot-rotation-abs) * 0.7)
            );
            width: 9.75rem;
        }
    }

    [screen-reader] & {
        input[type='range']:focus::-webkit-slider-thumb {
            outline: 3px solid #808080;
            outline-offset: 0.125rem;
        }
    }

    /*********** Firefox styles ***********/
    /* slider track */
    input[type='range']::-moz-range-track {
        background-color: transparent;
        border-radius: 8.5rem;
        height: 11.6rem;
    }

    /* slider thumb */
    input[type='range']::-moz-range-thumb {
        background-color: transparent;
        border: none; /*Removes extra border that FF applies*/
        margin-top: var(--top, -69px); /* Centers thumb on the track */
        border-radius: 12rem;
        height: 14.625rem;
        width: 14.625rem;
        transform: translate(
            calc(var(--slider-dot-rotation) * 3.3),
            calc(var(--top) * 4.9)
        );
        box-shadow: none;

        @media (max-width: 767.98px) {
            height: 9.75rem;
            transform: translate(
                calc(var(--slider-dot-rotation) * 0.7),
                calc(var(--slider-dot-rotation-abs) * 0.7)
            );
            width: 9.75rem;
        }
    }

    [screen-reader] & {
        input[type='range']:focus::-moz-range-thumb {
            outline: 3px solid #808080;
            outline-offset: 0.125rem;
        }
    }
}
