/**
 * Radio molecule
 */

.m-radio {
    $block: &;

    align-items: center;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    fill: currentColor;
    margin: 0 gutter('sm') 0 0;
    position: relative;
    transition: speed() easing();
    transition-property: color, fill;

    &.isDisabled {
        cursor: default;
        pointer-events: none;
    }

    &__field {
        appearance: none;
        border: border-width() solid color('black');
        border-radius: border-radius('round');
        display: inline-flex;
        flex: 0 0 auto;
        height: 2.5rem;
        position: relative;
        transition: speed() easing();
        transition-property: background-color, border-color;
        width: 2.5rem;

        #{$block}--dark & {
            border-color: color('white');
        }

        &::after {
            background-color: transparent;
            border-radius: border-radius('round');
            bottom: border-width();
            content: '';
            left: border-width();
            position: absolute;
            right: border-width();
            top: border-width();
            transition: background-color speed() easing();
        }

        #{$block}.isChecked & {
            border-color: color('secondary');

            &::after {
                background-color: color('secondary');
            }
        }

        #{$block}.isDisabled & {
            border-color: color('gray');
        }

        #{$block}.isChecked.isDisabled & {
            border-color: color('gray');

            &::after {
                background-color: color('gray');
            }
        }
    }

    &__label {
        display: inline-flex;
        flex: 0 0 auto;
        margin: 0 gutter('xs');
        pointer-events: none;
        transition: speed() easing();
        transition-property: color, opacity;

        #{$block}.isDisabled & {
            color: color('gray');
        }
    }
}
