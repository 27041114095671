/**
 * Display Inline Flex utility
 */

.u-inlineFlex {
    display: inline-flex !important;
}

@each $key, $value in $grid-containers {
    @for $i from 1 through $grid-columns {
        @include breakpoint($key) {
            .#{$key}\:u-inlineFlex {
                display: inline-flex !important;
            }
        }
    }
}
