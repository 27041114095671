.o-salesValuation {
    $block: &;

    padding: gutter('sm') gutter();
    width: 100%;

    @include breakpoint('md') {
        padding: gutter('xxl');
    }

    &--paperYellowLight {
        background-color: color('paperYellow-light');
        color: color('black');
    }

    &--greenDark {
        background-color: color('green-dark');
    }

    &--green {
        background-color: color('green');
    }

    &--greenPastel {
        background-color: color('greenPastel');
        color: color('black');
    }

    &--white {
        background-color: color('white');
        color: color('black');
    }

    &--whiteShadow {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow();
        color: color('black');
    }

    &--whiteBorder {
        background-color: color('white');
        border: border-width() solid color('black');
        color: color('black');
    }

    &--orange {
        background-color: color('orange');
        color: color('black');
    }

    &--sand {
        background-color: color('sand');
        color: color('black');
    }

    &--yellow {
        background-color: color('yellow');
        color: color('black');
    }

    &__stepWrapper {
        display: flex;
        flex-direction: column;

        &--confirmation {
            gap: gutter('xs');

            @include breakpoint('md') {
                display: block;
            }
        }
    }

    &__disclaimer {
        display: grid;
        gap: gutter('xs');
    }

    &__information,
    &__headline,
    &__disclaimer {
        #{$block}--green &,
        #{$block}--greenDark & {
            color: #fff;
        }
    }

    &__information {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: gutter('xs');
        padding-top: gutter('xs');
    }

    &__info {
        color: color('secondary');
        cursor: pointer;
        display: inline-flex;
        transition: opacity speed() easing();

        &:hover {
            opacity: 0.75;
        }
    }

    &__inputWrapper {
        display: flex;
        flex-direction: column;
        gap: gutter('xs');
        padding-top: gutter('sm');

        @include breakpoint('md') {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__broker {
        margin: 0 -#{gutter('xs')} gutter('xs') -#{gutter('xs')};

        @include breakpoint('md') {
            display: flex;
            margin: 0;
        }
    }

    &__input {
        flex-grow: 1;

        @include breakpoint('md') {
            flex-basis: 35rem;
        }

        @include breakpoint('lg') {
            flex-basis: 29rem;
        }

        &--dawa {
            margin-right: 0;

            @include breakpoint('lg') {
                flex: 0 0 360px;
            }
        }

        &--select {
            margin-bottom: 0;
        }
    }

    &__textarea {
        flex: none;
        flex-basis: 100%;
    }

    &__inputButton {
        margin-left: auto;
    }

    &__navigation {
        display: flex;
        flex-direction: column;
        gap: gutter('sm');
        margin-top: gutter('sm');

        @include breakpoint('md') {
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    &__navigationButtons {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        @include breakpoint('md') {
            gap: gutter('lg');
            margin-left: auto;
        }
    }

    &__confirmation {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }

    &__confirmationNotice {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: gutter('xs');
        padding-top: gutter('md');
    }

    &__confirmationCheckmark {
        align-items: center;
        background-color: color('greenCustom');
        border-radius: border-radius('round');
        display: flex;
        height: gutter('xxl');
        justify-content: center;
        padding: 0 gutter('xs');
        width: gutter('xxl');

        @include breakpoint('md') {
            padding: 0;
        }
    }
}
