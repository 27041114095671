/**
 * Heading 1 atom
 */

.a-heading1 {
    font-family: font-family();
    font-size: font-size('h2');
    font-weight: font-weight('medium');
    line-height: line-height('h2');
    position: relative;

    @include breakpoint('lg') {
        font-size: font-size('h1');
        line-height: line-height('h1');
    }

    &--white {
        color: color('white');
    }
}
