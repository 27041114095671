/**
 * Broker Properties for sale organism
 */

.o-brokerPropertiesForSale {
    display: grid;
    gap: gutter('md');

    @include breakpoint('md') {
        grid-template-columns: repeat(4, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        // grid-template-rows: repeat(2, 1fr);
    }

    &__top {
        margin-bottom: gutter();
        width: 100%;

        @include breakpoint('md') {
            margin-bottom: gutter('xl');
        }
    }

    &__relatedBrokers {
        align-items: center;
        background-color: color('primary');
        color: color('white');
        display: flex;
        flex-direction: column;
        gap: gutter('md');
        justify-content: center;
        min-height: 25rem;
        padding: 0 gutter('lg');
        text-align: center;
    }

    &__types {
        border: border-width() solid color('black');
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: gutter();

        @include breakpoint('md') {
            grid-column: -2/-1;
        }

        &--full {
            @include breakpoint('md') {
                grid-column: -2/-1;
                grid-row: 1/3;
            }
        }
    }

    &__typesType {
        display: flex;
        flex-direction: row;
        font-size: font-size('lead');
        margin-bottom: gutter('xs');

        & > :last-child {
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__typesTypeCount {
        min-width: gutter('xl');
    }

    &__typesSeeAllRow {
        margin-top: auto;
    }

    &__typesSeeAll {
        text-align: center;
        width: 100%;
    }
}
