/**
 * Municipality Subpage List organism
 */

.o-municipalitySubpageList {
    $block: &;

    color: color('white');

    @include breakpoint('md', 'down') {
        background-color: color('green');
    }

    &__container {
        background-color: color('green');
        padding: gutter('lg') 0;

        @include breakpoint('md') {
            padding: gutter('xxxl') 0;
        }
    }

    &__title {
        margin-bottom: gutter('sm');

        @include breakpoint('md') {
            margin-bottom: gutter('xl');
        }
    }

    &__list {
        display: grid;
        gap: gutter('xs') gutter('md');
        grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
        margin-bottom: 3rem;

        @include breakpoint('md') {
            margin-bottom: 8rem;
        }

        @include breakpoint('lg') {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__subpage {
        align-items: center;
        background-color: color('white');
        border-radius: border-radius('large');
        color: color('black');
        display: flex;
        flex-flow: row nowrap;
        min-height: gutter('xxl');
        padding-left: gutter('sm');
    }

    &__subpageName {
        font-family: font-family('primary');
        font-size: font-size('label');
        font-weight: font-weight('medium');
        line-height: line-height('display');
        margin-right: gutter('xxs');
    }

    &__subpageDescription {
        font-family: font-family('primary');
        font-size: font-size('small');
        font-weight: font-weight('medium');
        line-height: line-height('small');
        min-width: 10rem;
    }

    &__subpageIcon {
        margin-left: auto;
        margin-right: gutter('xs');
        min-width: gutter('sm');
    }

    &__seeAll {
        border-color: color('white');
        color: color('white');
    }
}
