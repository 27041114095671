/**
 * Module Accordion organism
 */

.o-moduleAccordion {
    $block: &;

    background-color: color('primary');
    color: color('white');
    margin: gutter('xs') 0;

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &__header {
        align-items: center;
        color: inherit;
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        padding: gutter('sm') gutter('md');
        text-align: start;
        width: 100%;

        @include breakpoint('md') {
            padding: gutter() gutter('xl');
        }

        * {
            pointer-events: none;
        }
    }

    &__icon {
        @include breakpoint('md') {
            height: 7rem;
            width: 7rem;
        }

        #{$block}:hover & {
            background-color: color('secondary:hover');
        }
    }

    &__headingWrapper {
        display: flex;
        flex-direction: column;
    }

    &__label {
        color: color('secondary');
        margin-bottom: gutter('xxs');
    }

    &__title {
        @include breakpoint('md') {
            font-size: font-size('h3');
            line-height: line-height('h3');
        }
    }

    &__contentWrapper {
        display: grid;
        grid-template-rows: 0fr;
        opacity: 0;
        overflow: hidden;
        transition: speed() easing();
        transition-property: grid-template-rows, opacity, padding;
        padding-block: 0;
        padding-inline: gutter();

        @include breakpoint('md') {
            padding-inline: gutter('xl');
        }

        &.isExpanded {
            grid-template-rows: 1fr;
            opacity: 1;
            padding-block: gutter('sm') gutter('md');

            @include breakpoint('md') {
                padding-block: gutter('xxs') gutter('xl');
            }
        }
    }

    &__contentBody {
        min-height: 0;
    }

    &__body {
        margin-bottom: gutter();

        &:last-of-type {
            margin-bottom: 0;
        }

        a {
            color: inherit;
        }

        .a-heading3,
        h3 {
            @include lora();

            font-size: 2rem;

            @include breakpoint('md') {
                font-size: 2.25rem;
            }
        }
    }
}
