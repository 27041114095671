/**
 * Paragraph atom
 */

.a-paragraph {
    font-size: font-size();
    line-height: line-height();

    @include lora();

    &--white {
        color: color('white');
    }

    u {
        word-break: break-word;
    }
}
