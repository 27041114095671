.o-homeEstimateResultsMap {
    &__mapContainer {
        margin: 0 -#{gutter()};

        @include breakpoint('md') {
            background-color: color('green');
            margin: 0;
            padding: gutter();
        }

        &--inner {
            height: 73.75rem;
            position: relative;
            width: 100%;

            @include breakpoint('md') {
                height: 88rem;
            }
        }
    }
}
