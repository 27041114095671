/**
 * Display None utility
 */

.u-none {
    display: none !important;
}

@each $key, $value in $grid-containers {
    @for $i from 1 through $grid-columns {
        @include breakpoint($key) {
            .#{$key}\:u-none {
                display: none !important;
            }
        }
    }
}
