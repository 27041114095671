/**
 * Property No Longer For Sale Hero organism
 */

.o-propertyNoLongerForSaleHero {
    margin: gutter('xl') 0;
    text-align: center;

    @include breakpoint('md') {
        margin: gutter('xxxl') 0;
    }

    .a-heading3 {
        margin-bottom: gutter('xs');
    }
}
