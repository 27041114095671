.o-propertyDetailsList {
    display: flex;
    flex-direction: column;
    gap: gutter('md');

    @include breakpoint('md') {
        gap: gutter('xxxl');
    }
}

section[data-type='propertyPresentationInNumbers']
    + [data-anchor-text]
    ~ section[data-type='propertyDetailsList'] {
    @include breakpoint('sm', 'down') {
        margin-top: -6rem;
    }
}
