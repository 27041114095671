/**
 * Property Estimate Teaser
 */

.o-homeEstimateLandingHero {
    $block: &;

    @extend .u-container;

    display: grid;
    gap: 0 gutter();

    --columnCount: 6;
    grid-template-columns: repeat(var(--columnCount), minmax(20px, 1fr));

    @include breakpoint('sm', 'down') {
        max-width: unset;
        padding: unset;
        row-gap: gutter('lg');
    }

    @include breakpoint('md') {
        --columnCount: 12;
    }

    > * {
        grid-column: span 6;
    }

    &__wrapper {
        margin-top: gutter('lg');
    }

    &__infoHeadline {
        margin-bottom: gutter('md');
    }

    &__inputSection {
        @include breakpoint-max-width('sm', 'down');

        display: grid;
        row-gap: gutter('md');

        @include breakpoint('xl') {
            grid-column: span 5;
            row-gap: gutter('lg');
        }
    }

    &__inputSectionTexts {
        display: grid;
        row-gap: gutter('sm');

        @include breakpoint('md') {
            row-gap: gutter();
        }
    }

    &__contentHeadline {
        @include lora();

        font-size: clamp(42px, 7vw, 80px);
        margin: 0;
        max-width: 17ch;
        text-wrap: balance;
    }

    &__contentDescription {
        display: flex;
        flex-wrap: nowrap;
    }

    &__contentDescriptionIcon {
        color: color('secondary');
        margin-left: gutter('xxs');
    }

    &__info {
        color: color('secondary');
        cursor: pointer;
        display: inline-flex;
        transition:
            opacity speed() easing(),
            color speed() easing();

        &:hover {
            opacity: 0.75;
        }

        &:focus-visible {
            color: color('black');
        }
    }

    &__inputWrapper {
        display: grid;
        row-gap: gutter('xs');
    }

    &__disclaimerText {
        text-overflow: initial;
        white-space: initial;
    }

    &__contentInputButton {
        height: 6.25rem;
        margin: 0;
        text-align: center;
        width: fit-content;
    }

    &__imageWrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: 'content';
        margin: 0 auto;
        max-width: 47.75rem;
        padding: 0 2rem;
        width: 100%;

        @include breakpoint('md') {
            max-width: unset;
            padding: unset;
        }

        @include breakpoint('xl') {
            grid-column: span 7;
        }

        &:has(video) > #{$block}__image,
        &:has(iframe) > #{$block}__image {
            opacity: 0;
        }
    }

    &__image,
    &__video {
        grid-area: content;
        aspect-ratio: 16 / 9;
        align-self: start;
        object-fit: cover;
    }

    // Note! This fixes a bug in the Blink rendering engine.
    // Sometimes the browser will render a 1px black line at the bottom or right side of the video
    // Using a filter forces the video redering to use the GPU and fixes the issue
    // https://stackoverflow.com/questions/20771971/video-player-shows-black-border
    // Solution found by @el-muerto
    // https://codepen.io/el-muerto/full/WNVzVwO
    &__video {
        filter: grayscale(0);
    }
}
