/**
 * SnapSwiper organism
 */

.o-snapSwiper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 3rem 0;

    &__header {
        grid-column: span 12;
        padding-bottom: 2rem;
        text-align: center;

        @include breakpoint('md') {
            grid-column: 4 / 10;
        }
    }

    &__container {
        gap: 2rem;
        grid-column: span 12;

        @include breakpoint('sm', 'down') {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding: 3rem calc(100vw - 47.5rem);
            scroll-snap-stop: always;
            scroll-snap-type: x mandatory;
            width: 100%;

            &::-webkit-scrollbar {
                display: none;
            }

            & {
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
            }

            &.u-container {
                margin: unset;
                max-width: unset;
            }
        }

        @include breakpoint('md') {
            display: grid;
            gap: 4rem;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @include breakpoint('md', 'down') {
        &__item {
            min-width: 85%;
            scroll-snap-align: center;
        }
    }

    @include breakpoint('md') {
        padding: 5rem 0;

        &__header {
            padding-bottom: 5rem;
        }

        &__item {
            display: flex;
            justify-content: center;
        }
    }
}
