/**
 * Sticky button organism
 */

.o-stickyFavoriteButton {
    $block: &;

    border: 0;
    bottom: gutter('xxs');
    display: flex;
    flex: 0 0 auto;
    height: 5em;
    overflow: hidden;
    position: fixed;
    left: gutter('xxs');
    font-size: 10px;
    transition-property: color, background-color, opacity, transform;
    width: max-content;
    z-index: 8;

    &:disabled {
        opacity: 1;
    }

    @include breakpoint('sm', 'down') {
        aspect-ratio: 1;
        font-size: 9px;
        justify-content: center;
        padding: 0;
        overflow: visible;
    }

    @include breakpoint('lg') {
        bottom: gutter('xs');
        left: gutter('xs');
    }

    svg {
        display: block;
        height: 2.4em;
        width: 2.4em;
    }

    .m-button__text {
        --animation-offset: -2.5rem;

        @include breakpoint('sm', 'down') {
            color: color('black');
            flex: none;
            font-size: 9px;
            left: 0;
            margin-block: 0;
            margin-inline: auto;
            opacity: 0;
            padding: 0;
            position: absolute;
            right: 0;
            top: 50%;
            text-align: center;
            transform: translateY(-50%);

            &.animateIn {
                animation: animateIn 0.2s linear forwards;
            }
            &.animateOut {
                animation: animateOut 0.2s linear forwards;
            }
        }

        @include breakpoint('md') {
            font-size: 1.4em;
            transition: opacity 0.5s ease-in-out;
            &.hidden {
                opacity: 0;
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: color('black') !important;

            svg .cls-3 {
                fill-rule: unset;
                fill: color('black');
            }
        }
    }
}
