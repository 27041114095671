/**
 * Confirmation Notice
 */

.a-confirmationNotice {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: gutter('xs');
    padding-bottom: gutter('md');
    padding-top: gutter('md');

    &--paddingBottom {
        padding-bottom: gutter('lg');
    }

    &__checkmark {
        align-items: center;
        background-color: color('greenCustom');
        border-radius: border-radius('round');
        display: flex;
        flex: none;
        height: gutter('xxl');
        justify-content: center;
        padding: 0 gutter('xs');
        width: gutter('xxl');

        @include breakpoint('md') {
            padding: 0;
        }
    }
}
