/**
 * Functions
 */

@function get-vw($target, $breakpoint: 'sm') {
    $vw-context: (map-get($breakpoints, $breakpoint) * 0.01);

    @return ($target / $vw-context) * 1vw;
}

@function grid-offset($from, $to) {
    $calc: ($to / $from * 100);

    @return calc(-#{$calc + '%'} - #{$grid-gutter / 2});
}

@function grid-padding($from, $to) {
    $calc: ($to / $from * 100);

    @return calc(#{$calc + '%'} - #{$grid-gutter / 2});
}

@function border-radius($index: 'normal') {
    @if map-has-key($border-radiuses, $index) {
        @return map-get($border-radiuses, $index);
    }

    @else {
        @error 'ERROR: The specified "border-radius" value does not exist in the mapping.';
    }
}

@function border-width($index: 'base') {
    @if map-has-key($border-widths, $index) {
        @return map-get($border-widths, $index);
    }

    @else {
        @error 'ERROR: The specified "border-width" value does not exist in the mapping.';
    }
}

@function box-shadow($index: 'normal') {
    @if map-has-key($box-shadows, $index) {
        @return map-get($box-shadows, $index);
    }

    @else {
        @error 'ERROR: The specified "box-shadow" value does not exist in the mapping.';
    }
}

@function color($index) {
    @if map-has-key($colors, $index) {
        @return map-get($colors, $index);
    }

    @else {
        @error 'ERROR: The specified "color" value does not exist in the mapping.';
    }
}

@function font-family($index: 'primary') {
    @if map-has-key($font-families, $index) {
        @return map-get($font-families, $index);
    }

    @else {
        @error 'ERROR: The specified "font-family" value does not exist in the mapping.';
    }
}

@function font-size($index: 'base') {
    @if map-has-key($font-sizes, $index) {
        @return map-get($font-sizes, $index);
    }

    @else {
        @error 'ERROR: The specified "font-size" value does not exist in the mapping.';
    }
}

@function font-weight($index: 'regular') {
    @if map-has-key($font-weights, $index) {
        @return map-get($font-weights, $index);
    }

    @else {
        @error 'ERROR: The specified "font-weight" value does not exist in the mapping.';
    }
}

@function gutter($index: 'md') {
    @if map-has-key($gutters, $index) {
        @return map-get($gutters, $index);
    }

    @else {
        @error 'ERROR: The specified "gutter" value does not exist in the mapping.';
    }
}

@function letter-spacing($index: 'base') {
    @if map-has-key($letter-spacings, $index) {
        @return map-get($letter-spacings, $index);
    }

    @else {
        @error 'ERROR: The specified "letter-spacing" value does not exist in the mapping.';
    }
}

@function line-height($index: 'base') {
    @if map-has-key($line-heights, $index) {
        @return map-get($line-heights, $index);
    }

    @else {
        @error 'ERROR: The specified "line-height" value does not exist in the mapping.';
    }
}

@function easing($index: 'base') {
    @if map-has-key($transition-timing-functions, $index) {
        @return map-get($transition-timing-functions, $index);
    }

    @else {
        @error 'ERROR: The specified "easing" value does not exist in the mapping.';
    }
}

@function speed($index: 'base') {
    @if map-has-key($transition-durations, $index) {
        @return map-get($transition-durations, $index);
    }

    @else {
        @error 'ERROR: The specified "speed" value does not exist in the mapping.';
    }
}

@function map-get-next($map, $key, $fallback: false, $return: value) {
    @if type-of($map) == map {
        @if map-has-key($map, $key) {
            $i: 0;
            $key-index: false;

            @each $map-key, $map-value in $map {
                $i: $i + 1;

                @if ($map-key == $key) {
                    $key-index: $i;
                }

                @if ($i == $key-index + 1) {
                    @if $return == key {
                        @return $map-key;
                    }

                    @else {
                        @return $map-value;
                    }
                }

                @if ($i == length($map)) {
                    @return $fallback;
                }
            }

            @warn 'No next map item for key #{$key}';

            @return $fallback;
        }

        @warn 'No valid key #{$key} in map';

        @return $fallback;
    }

    @warn 'No valid map';

    @return $fallback;
}

@function rem($value) {
    $remValue: ($value / 8) + rem;

    @return $remValue;
}
