.o-neighborhoodTeaser {
    .o-moduleBoxedImageWithText {
        // align-items: center;
        display: flex;
        flex-direction: column;

        @include breakpoint('md') {
            display: grid;
            gap: 0 4rem;
            grid-template-columns: repeat(10, minmax(20px, 1fr));
        }
    }

    .o-moduleBoxedImageWithText__imageColumn {
        padding: 4rem;
        padding-bottom: 0;
        // width: unset;

        @include breakpoint('md') {
            grid-column: 1/5;
            justify-content: center;
            padding: 7rem;
        }

        // .o-moduleBoxedImageWithText__mediaWrapper {
        //     width: 32rem;
        // }
    }

    .o-moduleBoxedImageWithText__contentColumn {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: 4rem;

        @include breakpoint('md') {
            grid-column: 5/-1;
            padding: 7rem 11rem 7rem 0;
        }
    }
}
