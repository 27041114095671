.o-propertyMunicipality {
    &__row {
        flex-wrap: wrap;

        @include breakpoint('md') {
            flex-wrap: nowrap;
            justify-content: center;
        }
    }

    &__municipality {
        margin-bottom: gutter('sm');

        @include breakpoint('md') {
            margin-bottom: -#{gutter('sm')};
        }
    }

    &__municipalityTable {
        margin-bottom: 0;
    }

    &__municipalitySource {
        display: flex;
        height: gutter('xs');
        justify-content: center;
        margin-top: gutter('xxs');
    }

    &__neighborhoodWrapper {
        display: grid;
        gap: gutter('xxs') 4rem;

        &:has(.o-propertyMunicipality__sourceText) {
            grid-template-rows: auto auto;
        }

        &.twoColumns {
            grid-template-columns: repeat(2, 1fr);

            @include breakpoint('sm', 'down') {
                grid-template-columns: repeat(2, 70vw);
                margin: 0 -#{gutter('md')};
                -ms-overflow-style: none;
                overflow-x: scroll;
                overflow-y: hidden;
                overscroll-behavior-x: contain;
                padding: 0 #{gutter('md')};
                scroll-padding: 0 #{gutter('md')};
                scroll-snap-type: x mandatory;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }

    &__neighborhoodTeaser {
        align-items: center;
        background-color: color('green-dark');
        color: color('white');
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: gutter('xs');
        scroll-snap-align: center;
        text-align: center;

        :not(:last-child) {
            margin-bottom: gutter('sm');
        }
    }

    &__neighborhoodVotes {
        background-color: color('pink');
        display: flex;
        flex-direction: column;
        padding: gutter('sm') gutter('md');
        scroll-snap-align: center;
    }

    &__neighborhoodVotesHeading {
        margin-bottom: gutter('md');
    }

    &__neighborhoodVote {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &__neighborhoodVoteIcon {
        align-items: center;
        border: 0.25rem solid color('pink-dark');
        border-radius: border-radius('round');
        display: flex;
        height: 3.5rem;
        justify-content: center;
        margin: 0.5rem 1rem 0.5rem 0;
        padding: 0;
        width: 3.5rem;

        svg {
            width: 75%;
        }
    }

    &__neighborhoodVotePanel {
        align-items: flex-start;
        background-color: color('white');
        color: color('white');
        display: flex;
        flex-direction: column;
    }

    &__sourceText {
        align-items: center;
        display: flex;
        gap: gutter('xxs');
        grid-column: -2 / -1;
        grid-row: -2 / -1;
        justify-content: center;
    }
}
