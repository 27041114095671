/**
 * Horizontal Chart molecule
 */

.m-horizontalChart {
    &__barWrapper {
        display: grid;
        gap: 0.5rem 0;
        grid-template-areas: "charttext chartvalue" "chartgraph chartgraph";
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            gap: 4rem;
            grid-template-areas: "charttext chartgraph chartvalue";
            grid-template-columns: 4fr 5fr 1fr;
            grid-template-rows: auto;
        }
    }

    &__chartText {
        grid-area: charttext;
    }

    &__chartValue {
        grid-area: chartvalue;
        text-align: right;
    }

    &__barBackground {
        border-radius: border-radius('round');
        grid-area: chartgraph;
        height: gutter('xs');
        width: 100%;

        &--secondary {
            background-color: rgba(color('white'), 0.5);
        }
    }

    &__bar {
        background: linear-gradient(to right, rgba(color('secondary'), 0) 0%, rgba(color('secondary'), 1) 100%);
        border-radius: border-radius('round');
        height: gutter('xs');
        position: relative;

        &--secondary {
            background: linear-gradient(to right, color('white') 25%, color('pink-dark') 100%);
        }
    }

    &__label {
        align-items: center;
        display: flex;
        font-size: font-size('label');
        height: gutter('xs');
        justify-content: center;
        opacity: 0;
        padding: 0 gutter('xxs');
        position: absolute;
        right: 0;
        top: 0;
        transform: scale(1);
        transform-origin: 50% 50%;
        transition: opacity speed() easing();

        &.isActive {
            animation: upvoted 1s ease 0.1s;
            animation-direction: alternate;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            opacity: 1;
        }
    }
}

@keyframes upvoted {
    from {
        transform: scale(0.75);
    }

    to {
        transform: scale(1.2);
    }
}
