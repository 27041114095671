/**
 * Project Sale All Projects
 */

.o-projectSaleAllProjectsWrapper {
    display: grid;
    gap: gutter('sm');

    @include breakpoint('sm') {
        gap: gutter('md');
    }

    @include breakpoint('md') {
        gap: gutter('xl');
    }
}

.o-projectSaleTeaser {
    border-radius: 10px;
    box-shadow: box-shadow('normal');
    display: grid;
    grid-template-areas: "image" "text";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    overflow: hidden;

    @include breakpoint('md') {
        grid-template-areas: "text image";
        grid-template-columns: 480fr 928fr; // 1408px
        grid-template-rows: auto;
    }

    [screen-reader] &:focus {
        outline: solid 2px #3fa9f5;
        outline-offset: -2px;
    }

    [mouse] &:hover {
        box-shadow: box-shadow('normal:hover');
    }

    &__Content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        grid-area: text;
        padding: 4rem;
    }

    &__Facts {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 3rem;
    }

    &__PropertiesForSale {
        align-items: center;
        display: flex;
        gap: 1rem;
        margin-top: auto;
    }

    &__Image {
        align-items: center;
        background: color('green-dark');
        color: color('white');
        display: flex;
        flex-direction: column;
        grid-area: image;
        justify-content: center;

        &.noImage {
            aspect-ratio: 928/455;
            max-height: 455px;
        }

        img {
            display: block;
            max-height: 455px;
            max-width: none;
            object-fit: cover;
            width: 100%;
        }
    }
}
