/**
 * DAWA Autocomplete2 vendor
 */

.autocomplete-container {
    //max-width: 60rem;
    position: relative;
    width: 100%;

    @include breakpoint('md') {
        margin-right: gutter('xs');
    }
}

.dawa-autocomplete-suggestions {
    background-color: color('white');
    border-bottom-left-radius: border-radius('large');
    border-bottom-right-radius: border-radius('large');
    box-shadow: box-shadow();
    left: 0;
    max-height: 36rem;
    //max-width: 60rem;
    overflow-y: auto;
    position: absolute;
    top: 6.25rem;
    width: 100%;
    z-index: 9999;

    .dawa-autocomplete-suggestion {
        color: color('black');
        cursor: pointer;
        list-style: none;
        padding: gutter('xs');
    }

    .dawa-autocomplete-suggestion:last-child {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    .dawa-autocomplete-suggestion.dawa-selected,
    .dawa-autocomplete-suggestion:hover {
        background: color('gray-light');
        color: color('black');
    }
}
