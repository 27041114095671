/**
 * Checkbox atom
 */

.a-checkbox {
    @extend input;

    background-color: color('white');
    border: border-width() solid color('secondary');
    border-radius: border-radius('round');
    cursor: pointer;
    height: 2.5rem;
    transition: speed() easing();
    transition-property: background-color, border-color;
    width: 2.5rem;

    &:checked {
        background-color: color('secondary');
    }

    &[disabled] {
        border-color: color('gray');

        &:checked {
            background-color: color('gray');
        }
    }
}
