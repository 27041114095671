.o-buyerAdviceTestimonials {
    $block: &;

    display: grid;
    gap: 3rem;

    &__headline {
        grid-column: 1/-1;
    }

    &__mediaImage {
        display: block;
        grid-column: 1/-1;
        min-height: 30rem;
        object-fit: cover;
    }

    + .o-buyerAdviceTestimonialsGrid {
        margin-top: -8rem;

        @include breakpoint('md', 'down') {
            padding: 0 6rem;
            scroll-padding: 0 6rem;
        }

        @include breakpoint('md') {
            margin-top: -12rem;
            padding: 0 6rem;
        }

        @include breakpoint('lg') {
            margin-top: -19rem;
            padding: 0 11rem;
        }
    }
}

.o-buyerAdviceTestimonialsGrid {
    $block: &;

    display: grid;
    gap: 3rem;
    grid-column: 1/-1;
    grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));

    @include breakpoint('md') {
        gap: 4rem;
    }

    &__item {
        background-color: color('white');
        border-radius: 10px;
        display: grid;
        gap: 2rem;
        justify-content: flex-start;
        line-height: 1.4;
        padding: 3rem;
        place-content: start;

        @include breakpoint('md') {
            padding: 4rem 3rem;
        }

        @include breakpoint('lg') {
            padding: 5rem 4rem;
        }

        &__quote {
            padding-left: 6rem;
            position: relative;

            svg {
                bottom: 0;
                display: block;
                left: 0;
                margin: auto 0;
                position: absolute;
                top: 0;
            }
        }
    }

    &[data-count="1"] {
        @include breakpoint('md') {
            margin-inline: auto;
            max-width: 566px;
        }
    }

    &[data-count="2"] {
        @include breakpoint('md') {
            margin-inline: auto;
            max-width: 988px;
        }
    }
}
