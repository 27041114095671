.o-brokerCtaWithBorder {
    align-items: center;
    background-color: color('white');
    border: border-width() solid color('black');
    display: flex;
    flex-direction: column;
    height: 383px;
    justify-content: center;
    margin-bottom: gutter('lg');
    width: 100%;

    @include breakpoint('md') {
        margin-bottom: 12rem;
    }

    &__headline {
        color: black;
        margin: 4rem;
        text-align: center;
    }
}
