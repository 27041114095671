.o-homeEstimateResponsibleBroker {
    &__wrapper {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow('tiny');
        display: flex;
        flex-direction: column;
        gap: gutter('xxs');
        padding: gutter('md') gutter('md') gutter('lg');

        @include breakpoint('md') {
            flex-direction: row;
            gap: gutter('sm');
        }
    }

    &__image {
        border-radius: border-radius('round');
        height: 10rem;
        width: 10rem;
    }

    &__text {
        @include lora();

        margin-bottom: gutter('xs');
    }
}
