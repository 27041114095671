/**
 * Heading 3 atom
 */

.a-heading3 {
    font-family: font-family();
    font-size: font-size('lead');
    font-weight: font-weight('medium');
    line-height: line-height('lead');
    position: relative;

    @include breakpoint('lg') {
        font-size: font-size('h3');
        line-height: line-height('h3');
    }

    &--secondary {
        color: color('secondary');
    }

    &--white {
        color: color('white');
    }
}
