/**
 * Visually hidden atom
 * Source: https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
 */

.visually-hidden,
.visuallyhidden {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px !important;
    word-wrap: normal !important;
}
