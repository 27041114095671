/**
 * Estimate Parameters organism
 */

.o-estimateParameters {
    $block: &;

    margin-bottom: 8rem;

    &__disclaimer {
        line-height: line-height('small');

        @include breakpoint('md') {
            text-align: center;
        }
    }
}
