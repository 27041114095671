/**
 * Animations
 * https://www.youtube.com/watch?v=nOd8yVfu3N4 - Vue3 animations tutorial
 */

.fade {
    &-enter-to,
    &-leave-from {
        opacity: 1;
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
    }

    &-enter-active,
    &-leave-active {
        transition: opacity speed('slow') easing();
    }
}

.slide-up {
    &-enter-from,
    &-leave-to {
        opacity: 0;
        transform: translateY(20px);
    }

    // We can comment this out because opacity:1 and transform: translateY(0px) is the browser default
    // &-enter-to,
    // &-leave-from {
    //     opacity: 1;
    //     transform: translateY(0px);
    // }

    &-enter-active,
    &-leave-active {
        transition: all speed() easing();
    }
}

@keyframes animateIn {
    from {
        opacity: 0;
        transform: translateY(-50%);
    }
    to {
        opacity: 1;
        transform: translateY(var(--animation-offset, -3.3rem));
    }
}

@keyframes animateOut {
    from {
        opacity: 1;
        transform: translateY(var(--animation-offset, -3.3rem));
    }
    to {
        opacity: 0;
        transform: translateY(-6.6rem);
    }
}

@keyframes animateInDesktop {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes animateOutDesktop {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
