/**
 * Estimate Legend organism
 */

.o-estimateLegend {
    $block: &;

    align-items: stretch;
    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.06);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: space-between;
    padding: gutter('xs');

    @include breakpoint('md') {
        align-items: center;
        flex-direction: row;
        padding: gutter('xxs');
        padding-left: gutter('sm');
    }

    &__item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: 0;
            margin-right: gutter('sm');
        }

        &:last-of-type {
            margin-bottom: 0;
            margin-right: 0;
        }
    }

    &__itemLine {
        background-color: color('secondary');
        border-radius: border-radius('small');
        flex: 0 0 auto;
        height: 0.5rem;
        margin-right: gutter('xxs');
        width: 1.5rem;
    }

    &__itemText {
        flex: 1 1 auto;
    }
}
