/**
 * Estimate District Facts organism
 */

.o-estimateDistrictFacts {
    $block: &;

    margin-bottom: 13rem;

    &__heading {
        margin-bottom: gutter('sm');
        text-align: center;
    }

    &__intro {
        text-align: center;
    }

    &__details {
        margin-top: gutter('sm');
        text-align: center;
    }

    &__boxes {
        margin-top: gutter('xl');

        @include breakpoint('md') {
            display: flex;
            justify-content: center;
        }
    }

    &__box {
        color: color('white');
        padding: gutter('md');
        position: relative;

        @include breakpoint('md') {
            align-self: flex-start;
            display: flex;
            flex-basis: 33.33%;
            flex-direction: column;
            justify-content: space-between;
            max-width: 40rem;
            transition: speed() easing();
            transition-property: box-shadow, transform;
        }

        @include breakpoint('lg') {
            height: 40rem;
        }

        [mouse] &:hover {
            transform: translateY(-#{gutter('xxs') / 2});
        }

        &::before {
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: opacity speed() easing();
        }

        [mouse] &:hover::before {
            opacity: 0.5;
        }

        &:nth-child(2) {
            @include breakpoint('md') {
                margin-top: 12rem;
            }
        }

        &:nth-child(3) {
            @include breakpoint('md') {
                margin-top: 6rem;
            }
        }

        &:not(:last-child) {
            margin-bottom: gutter('xs');

            @include breakpoint('md') {
                margin-bottom: 0;
                margin-right: gutter('md');
            }
        }

        &--greenDark {
            background-color: color('primary');
        }

        &--greenLight {
            background-color: color('green');
        }

        &--greenLighter {
            background-color: color('greenPastel');
            color: color('black');
        }
    }

    &__number {
        line-height: 1;
        margin-bottom: gutter('xs');
        margin-top: 0;
    }

    &__paragraph {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-top: auto;
        }
    }

    &__link {
        align-items: center;
        color: color('white');
        display: flex;

        &::after {
            bottom: 0;
            content: '';
            left: 0;
            pointer-events: auto;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }

        #{$block}__box--greenLighter & {
            color: color('black');
        }
    }

    &__icon {
        margin-left: 1rem;
    }
}
