/**
 * Display Block utility
 */

.u-block {
    display: block !important;
}

@each $key, $value in $grid-containers {
    @for $i from 1 through $grid-columns {
        @include breakpoint($key) {
            .#{$key}\:u-block {
                display: block !important;
            }
        }
    }
}
