.o-propertySellerSay {
    $block: &;

    border: border-width('small') solid color('black');
    display: flex;
    flex-direction: column;
    padding: gutter('lg') gutter('md');

    @include breakpoint('md') {
        padding: 10rem 0;
    }

    &--hasSplash {
        margin-top: 14rem;

        @include breakpoint('md') {
            margin-top: 17rem;
        }
    }

    &__splash {
        height: 11rem;
        margin-top: -10rem;
        width: 11rem;

        @include breakpoint('md') {
            height: 26rem;
            margin-top: -20rem;
            width: 26rem;
        }
    }

    &__quotes {
        gap: gutter('sm');

        @include breakpoint('md') {
            gap: gutter('xl');
        }
    }

    .luxurious & {
        border-width: border-width('base');
    }
}
