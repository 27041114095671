/**
 * Container utility
 */

.u-container {
    margin: 0 auto;
    padding: 0 #{$grid-gutter / 2};
    width: 100%;

    @each $key, $value in $grid-containers {
        @include breakpoint($key) {
            max-width: $value;
        }
    }

    &--fluid {
        max-width: 100%;
    }
}
