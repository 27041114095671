/**
 * Lead atom
 */

.a-lead {
    font-family: font-family();
    font-size: font-size('lead');
    font-weight: font-weight('medium');
    line-height: line-height('lead');

    &--white {
        color: color('white');
    }
}
