/**
 * Bar Chart molecule
 */

.m-barChart {
    display: inline-block;
    height: 100%;
    min-height: 285px;
    width: 100%;

    &__wrapper {
        height: 100%;

        @include breakpoint('lg') {
            padding: 0 gutter('xl');
        }
    }

    &__chartWrapper {
        display: flex;
        flex-flow: row nowrap;
        height: 100%;
        justify-content: space-between;
        margin-inline: auto;
        max-width: 700px;
        padding-top: gutter('sm');
        width: 100%;
    }

    &__barWrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__bar {
        background: linear-gradient(rgba(color('secondary'), 1) 0%, rgba(color('secondary'), 0) 100%);
        border-radius: border-radius('round');
        margin-top: auto;
        position: relative;
        width: gutter('xs');

        &::before {
            background-color: color('white');
            border-radius: 100%;
            content: "";
            height: 0.75rem;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            right: 0;
            top: 0.75rem;
            width: 0.75rem;
        }

        &::after {
            background-color: color('white');
            content: "";
            height: 0.25rem;
            left: 1rem;
            position: absolute;
            top: 1rem;
            width: 2rem;
        }

        + .a-label {
            margin-top: gutter('sm');
        }
    }

    &__barValue {
        color: color('white');
        left: 100%;
        position: absolute;
        transform: translateX(1.25rem);
    }
}
