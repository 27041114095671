/**
 * Estimate Evaluation organism
 */

.o-estimateEvaluation {
    $block: &;

    align-items: center;
    background-color: color('primary');
    color: color('white');
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin: 0 -#{gutter('sm')} gutter('xxxl');
    padding: gutter('sm') gutter('sm') 0;

    @include breakpoint('md') {
        margin: 0 0 gutter('xxxl');
        padding: gutter('xl') gutter('xl') 0;
    }

    @include breakpoint('lg') {
        margin: 0 0 gutter('xxxl');
        padding: gutter('xl') gutter('xxxl') 0;
    }

    &__header {
        display: flex;
        text-align: left;

        @include breakpoint('lg') {
            align-items: center;
            text-align: center;
        }
    }

    &__headerTitle {
        margin: 0;
    }

    &__info {
        color: color('secondary');
        cursor: pointer;
        display: inline-flex;
        margin-left: gutter('xxs');
        transition: opacity speed() easing();

        &:hover {
            opacity: 0.75;
        }
    }

    &__sliders {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: gutter('lg') 0;
        position: relative;
        width: 100%;

        @include breakpoint('lg') {
            flex-direction: row;
            margin: gutter('xxxl') 0;
        }
    }

    &__slidersGraphWrapper {
        bottom: 0;
        height: 220px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        width: calc(80% + 2px);
    }

    &__slidersGraph {
        width: 100% !important;
    }

    &__sliderWrapper {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: gutter('xs') 0;

        @include breakpoint('lg') {
            align-items: center;
            flex: 0 0 20%;
            margin: 0;
        }
    }

    &__sliderDetails {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: gutter('xs');
        user-select: none;
        width: 100%;

        @include breakpoint('lg') {
            flex-direction: column;
            margin-bottom: gutter('sm');
        }
    }

    &__sliderName {
        margin: 0;
    }

    &__sliderLabel {
        margin: 0;

        @include breakpoint('lg') {
            margin: gutter('xxs') 0 0;
        }
    }

    &__slider {
        .vue-slider-rail {
            background-color: color('white');
        }

        .vue-slider-dot-tooltip-inner {
            background-color: transparent;
            border-radius: border-radius('none');
        }

        .vue-slider-dot-handle {
            background-color: color('secondary');
            transition: background-color speed() easing();

            &::after {
                background-color: color('white');
                border: 1.5rem solid color('secondary');
                height: 4rem;
                transform: translate(-50%, -50%) scale(1);
                width: 4rem;
                z-index: 0;
            }
        }
    }

    &__calculation {
        background-color: color('paperYellow');
        border-radius: border-radius('large');
        bottom: gutter('xxs');
        box-shadow: box-shadow('normal:hover');
        color: color('black');
        display: inline-flex;
        flex-direction: column;
        justify-content: stretch;
        margin-bottom: -#{gutter('xxxl')};
        overflow: hidden;
        position: sticky;
        width: 100%;
        z-index: 7;

        @include breakpoint('lg') {
            // bottom: gutter('xs');
            bottom: none;
            position: relative;
        }
    }

    &__bankCalculation {
        align-items: center;
        background-color: color('white');
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: gutter('xs') gutter('xs');

        @include breakpoint('lg') {
            padding: gutter('xs') gutter();
        }
    }

    &__bankCalculationText {
        align-items: center;
        display: flex;
        margin: 0;
        white-space: nowrap;

        @include breakpoint('sm', 'down') {
            font-size: font-size('label');
        }
    }

    &__userCalculation {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: gutter('sm') gutter('xs');

        @include breakpoint('lg') {
            padding: gutter();
        }
    }

    &__userCalculationText {
        align-items: center;
        display: flex;
        margin: 0;
        white-space: nowrap;

        &:first-of-type {
            @include breakpoint('sm', 'down') {
                font-size: font-size('label');
            }
        }

        &:last-of-type {
            @include breakpoint('sm', 'down') {
                font-size: font-size('h3');
            }
        }
    }

    &__accuracyCalculation {
        align-items: center;
        background-color: color('paperYellow-light');
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: gutter('xs') gutter('xs');

        @include breakpoint('lg') {
            padding: gutter('xs') gutter();
        }
    }

    &__accuracyCalculationText {
        align-items: center;
        display: flex;
        margin: 0;
        white-space: nowrap;

        @include breakpoint('sm', 'down') {
            font-size: font-size('small');
        }
    }
}

.o-estimateParametersOverlay {
    &__title {
        margin-bottom: gutter('xxxl');
    }
}

.o-estimateCalculationOverlay {
    &__title {
        margin-bottom: gutter('xxxl');
    }
}

.m-indicator {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &__scale {
        background: linear-gradient(to right, color('white'), #5cf18e);
        border-radius: border-radius('round');
        display: flex;
        height: 1rem;
        margin: 0 gutter('xxs');
        position: relative;
        width: 11.25rem;

        @include breakpoint('md') {
            width: 28rem;
        }
    }

    &__line {
        border-right: border-width() dotted color('black');
        bottom: -0.5rem;
        left: 0;
        position: absolute;
        top: -0.5rem;
    }
}
