/**
 * Sub Panel organism
 */

.o-subPanel {
    $block: &;

    &--spacedChildrenLarge {
        @include owl(4rem);
    }

    &--spacedChildrenSmall {
        @include owl(2rem);
    }

    &__headline {
        margin-bottom: gutter('xs');
    }

    &__description {
        margin-bottom: gutter('sm');
    }

    &__rangeSlider {
        margin: gutter() 0 0;
    }

    &__actions {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: gutter('md');

        &--additional {
            margin-top: 0;
        }

        &--singleCTA {
            justify-content: flex-end;
        }

        &--fixed > :last-child {
            margin-left: auto;
        }
    }

    &__helpText {
        margin: gutter('lg') 0 gutter('sm');
        text-align: right;
    }

    &__openHouse {
        align-items: center;
        background-color: color('paperYellow');
        display: flex;
        flex-direction: column;
        gap: gutter('xs');
        justify-content: center;
        margin-top: gutter();
        padding: gutter() gutter('xs');
        text-align: center;
    }

    &__openHouseHeadline {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    &__ad {
        background-color: color('greenPastel');
        border-radius: border-radius('large');
        color: color('black');
        margin-top: gutter('md');
        padding: gutter('sm') gutter('md') gutter('md');
        text-align: center;

        h3 {
            margin-bottom: gutter('xs');
        }

        p {
            margin-bottom: gutter('sm');
            margin-left: gutter('xs');
            margin-right: gutter('xs');
        }
    }

    &__downloads {
        display: flex;
        flex-direction: column;

        @include owl(gutter('sm') / 2);
    }

    &__downloadLink {
        align-items: center;
        display: flex;

        svg {
            margin-right: gutter('xxs');
        }
    }
}
