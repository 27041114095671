/**
 * Product Card
 */

.m-productCard {
    border-radius: border-radius('large');
    box-shadow: box-shadow('small');
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    height: 100%;
    padding: 4rem 4rem 5rem;
    width: 100%;

    img {
        height: 80px;
        margin-bottom: 1.5rem;
        object-fit: contain;
        width: 80px;

        @include breakpoint('md') {
            height: 100px;
            width: 100px;
        }
    }

    @include breakpoint('md') {
        padding: 5rem 5rem 6rem;
    }
}
