.u-inline-auto {
    margin-inline: auto;
}

.u-block-auto {
    margin-block: auto;
}

.u-block-start-medium {
    margin-block-start: 3rem;
}

.u-block-end-medium {
    margin-block-end: 3rem;
}

.u-block-start-large {
    margin-block-start: 4rem;
}

.u-block-end-large {
    margin-block-end: 4rem;
}
