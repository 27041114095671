/**
 * Module Video organism
 */

.o-moduleVideo {
    $block: &;

    &__title {
        margin-bottom: gutter('sm');
    }

    &__small {
        font-size: 80%;
        margin-top: gutter('xs');
        white-space: normal;
    }
}
