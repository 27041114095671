/**
 * List Item atom
 */

li {
    margin: 0;
}

.a-listItem {
    @extend li;

    margin: gutter('xs') 0;
}
