/**
 * Small atom
 */

small {
    font-family: font-family();
    font-size: font-size('small');
    font-weight: font-weight('medium');
    line-height: line-height('small');
    margin: 0;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.a-small {
    @extend small;

    &--white {
        color: color('white');
    }

    &--allowBreak {
        overflow: visible;
        text-overflow: inherit;
        white-space: normal;
    }

    &--content {
        display: grid;
        gap: 1em;
    }
}

.a-error {
    @extend small;

    color: color('logoRed');
    display: block;
    margin: #{gutter('xxs') / 2} 0 0;
}

.a-error--bright {
    color: color('white');
}
