/**
 * Module Boxed Image With Text organism
 */

.o-moduleBoxedImageWithText {
    $block: &;

    background-color: color('green-dark');
    color: color('white');

    @include breakpoint('md') {
        height: 100%;
    }

    &--paperYellowLight {
        background-color: color('paperYellow-light');
        color: color('black');
    }

    &--green {
        background-color: color('green');
    }

    &--greenPastel {
        background-color: color('greenPastel');
        color: color('black');
    }

    &--white {
        background-color: color('white');
        color: color('black');
    }

    &--orange {
        background-color: color('orange');
        color: color('black');
    }

    &--sand {
        background-color: color('sand');
        color: color('black');
    }

    &--yellow {
        background-color: color('yellow');
        color: color('black');
    }

    &--whiteShadow {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow();
        color: color('black');
    }

    &--whiteBorder {
        background-color: color('white');
        border: border-width('small') solid color('black');
        color: color('black');
    }

    &__row {
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        margin: 0;

        #{$block}.isRightAligned & {
            flex-direction: row-reverse;
        }

        @include breakpoint('sm', 'down') {
            display: block;
        }
    }

    &__imageColumn {
        align-items: center;
        display: flex;
        height: 100%;
        padding: 0;
        width: 100%;

        @include breakpoint('md') {
            flex: 0 0 auto;
        }

        #{$block}.hasSmallImage & {
            @include breakpoint('md') {
                width: 25.34246575%;
            }
        }

        #{$block}.hasSmallImage.isHalfWidth & {
            @include breakpoint('md') {
                width: 41.86046512%;
            }
        }

        #{$block}.hasMediumImage & {
            @include breakpoint('md') {
                width: 41.09589041%;
            }
        }

        #{$block}.hasLargeImage & {
            @include breakpoint('md') {
                width: 51.45547945%;
            }
        }

        #{$block}.hasMediumImage.isHalfWidth &,
        #{$block}.hasLargeImage.isHalfWidth & {
            @include breakpoint('md') {
                width: 47.6744186%;
            }
        }
    }

    &__mediaWrapper {
        height: 100%;
        width: 100%;

        #{$block}.hasSmallImage & {
            padding: gutter() gutter() 0;

            @include breakpoint('md') {
                padding: gutter('xl') 0 gutter('xl') gutter('xl');
            }
        }

        #{$block}.hasSmallImage.isRightAligned & {
            @include breakpoint('md') {
                padding: gutter('xl') gutter('xl') gutter('xl') 0;
            }
        }
    }

    &__media {
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;

        @include breakpoint('md') {
            height: 100%;
        }
    }

    &__mediaImage {
        @include breakpoint('md') {
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: 100%;
            object-fit: cover;
            object-position: center;
        }

        #{$block}.hasSmallImage & {
            object-fit: contain;

            @include breakpoint('sm', 'down') {
                width: 18.75rem;
            }
        }

        &--contain {
            object-fit: contain;
        }
    }

    &__contentColumn {
        align-items: center;
        display: flex;
        padding: gutter('sm') gutter() gutter();
        width: 100%;

        #{$block}.mobileFullWidth & {
            padding: gutter('sm') 0 gutter();
            padding-bottom: 0;

            @include breakpoint('md') {
                padding: gutter('xxl') gutter('xxxl');
            }
        }

        @include breakpoint('md') {
            padding: gutter('xxl') gutter('xxxl');
        }

        #{$block}.hasSmallImage & {
            flex-grow: 1;
        }

        #{$block}.isHalfWidth & {
            @include breakpoint('md') {
                padding: gutter('xxl') gutter('xl');
            }
        }
    }

    &__content {
        align-items: center;
        width: 100%;
    }

    &__title {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: gutter('sm');
        }
    }

    &__small {
        display: block;
        margin-top: gutter('xs');
        white-space: normal;

        @include breakpoint('md') {
            margin-top: gutter();
        }
    }

    &__buttons {
        margin-top: gutter('sm');

        @include breakpoint('md') {
            margin-top: gutter();
        }
    }
}
