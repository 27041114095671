/**
 * Energy Label atom
 */

.a-energyLabel {
    align-items: center;
    border-radius: 0.625rem;
    color: color('white');
    display: flex;
    fill: color('white');
    flex: none;
    height: 3rem;
    justify-content: center;
    pointer-events: none;
    transition: fill speed() easing();
    width: 3rem;

    &--a2020,
    &--a2015,
    &--a2010,
    &--a {
        background-color: #137b3c;
    }

    &--b { background-color: #8dc348; }
    &--c { background-color: #d9c038; }
    &--d { background-color: #e0872c; }
    &--e { background-color: #ee5a35; }
    &--f { background-color: #fc0d1b; }
    &--g { background-color: #c10820; }

    &__icon {
        height: auto;
        width: 100%;
    }
}

.a-energyLabels {
    align-items: center;
    display: flex;
}
