/**
 * Truncated Text Atom
 */

.a-truncatedText {
    --lines: 3;

    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: var(--lines, 3);
    overflow: hidden;
}
