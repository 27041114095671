/**
 * Neighborhood page about organism
 */
.o-neighborhoodPageAbout {
    $block: &;

    margin: gutter('xxxl') 0;

    @include breakpoint('sm', 'down') {
        margin: -#{gutter('lg')} 0 gutter('xxxl') 0;
    }

    &__title {
        margin-bottom: gutter();
    }

    &__description {
        margin-bottom: gutter();

        @include breakpoint('md') {
            margin-bottom: 0;
        }
    }

    &__anchorlinks {
        margin-bottom: gutter('xs');
    }
}
