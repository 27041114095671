/**
 * Article overview organism
 */

.o-articleOverview {
    $block: &;

    &--teasers {
        .o-articleOverview__titleColumn {
            margin-bottom: gutter('xs');

            @include breakpoint('md') {
                margin-bottom: gutter('sm');
            }
        }
    }
    &__titleColumn {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: gutter();

        @include breakpoint('md') {
            margin-bottom: gutter('xl');
        }
    }

    &__itemsRow {
        margin-bottom: -#{gutter()};
    }

    &__itemsColumn {
        display: flex;
        margin-bottom: gutter();
    }

    &__button {
        margin-top: gutter();
        text-align: center;
        width: 100%;

        @include breakpoint('md') {
            margin-top: 0;
        }
    }
}
