/**
 * Estimate Campaigns organism
 */

.o-estimateCampaigns {
    $block: &;

    &__heading {
        margin-bottom: gutter();
        text-align: center;

        @include breakpoint('md') {
            margin-bottom: gutter('xxl');
        }
    }

    &__blocks {
        @include breakpoint('md') {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        > .o-campaign {
            margin-bottom: gutter('md');

            @include breakpoint('md') {
                flex-basis: calc(50% - 2rem);
                margin-bottom: gutter('md');
                padding: gutter('xxl') 11rem;
            }
        }
    }

    &__wrapper {
        margin-bottom: gutter('sm');

        @include breakpoint('md') {
            margin-bottom: 0;
        }
    }

    &__mappedBlocks {
        margin-bottom: gutter();

        @include breakpoint('sm', 'down') {
            margin-bottom: gutter('xs');
        }
    }
}
