/**
 * ABC overview organism
 */

.o-abcOverview {
    $block: &;

    &__heading {
        @include lora();
        // font-family: font-family('secondary');
        font-size: 4rem;
        // font-weight: font-weight('medium');
        line-height: line-height('display');
        margin-bottom: 4rem;

        @include breakpoint('md') {
            font-size: 10rem;
            margin-bottom: 10rem;
            text-align: right;
        }
    }
}
