/**
 * Estimate Graphs organism
 */

.o-estimateGraphs {
    &__title {
        margin-bottom: gutter();

        @include breakpoint('md') {
            margin-bottom: gutter('xl');
        }
    }

    &__legal {
        margin-top: gutter();

        @include breakpoint('md') {
            margin-top: gutter('xl');
        }
    }

    &__link {
        margin-top: gutter('sm');
    }
}
