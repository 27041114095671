/**
 * Navigation organism
 */

.o-navigation {
    $block: &;

    display: none;
    margin-top: gutter('sm');
    width: 100%;

    @include breakpoint('md') {
        margin-top: 0;
    }

    &.isMounted {
        display: block;
    }

    &__list {
        margin: gutter('sm') 0 gutter();

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: gutter('lg');
        }

        @include breakpoint('md') {
            margin: gutter('lg') 0;
            padding-top: 0;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
                padding-bottom: gutter('lg');
            }
        }
    }

    &__listItem {
        $element: &;

        position: relative;
        width: 100%;

        &.isActive > a {
            color: color('secondary');
        }

        &.isActive > a > svg {
            fill: color('secondary');
        }
    }

    &__actions {
        $element: &;

        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;

        &.isActive > a {
            color: color('secondary');
        }

        &.isActive > a > svg {
            fill: color('secondary');
        }

        &.isOpen + .o-navigation__subList {
            display: block;
        }
    }

    &__listItemText {
        pointer-events: none;
    }

    &__listItemIcon {
        display: block;
        fill: color('white');
        pointer-events: none;
        transition-property: fill, transform;

        .isOpen & {
            transform: rotate(180deg);
        }
    }

    &__subList {
        border-top: 0;
        display: none;
        margin: 0;
        padding: 0;

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    &__subListItem {
        a {
            padding-left: gutter();
        }

        &.isActive > a {
            color: color('secondary');
        }
    }

    &__label {
        display: block;
        margin: gutter('xxs') 0;

        @include breakpoint('md') {
            font-size: font-size('label');
            margin: 0 0 gutter('xs');
        }
    }

    &__link {
        align-items: center;
        color: color('white');
        display: inline-flex;
        justify-content: space-between;
        margin: 0;
        padding: gutter('xxs') 0;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: speed() easing();
        transition-property: color, text-decoration-color;

        @include breakpoint('md') {
            padding: gutter('xxs') 0;
        }

        #{$block}__listItem--hasChildren & {
            display: flex;
        }

        [screen-reader] &:focus,
        [mouse] &:hover {
            color: color('secondary');
            text-decoration: underline;
            text-decoration-color: inherit;
        }
    }

    &__toggleButton {
        align-items: center;
        align-self: center;
        background: transparent;
        display: flex;
        flex: none;
        height: 4rem;
        justify-content: center;
        width: 4rem;

        [screen-reader] &:focus,
        [mouse] &:hover {
            background: color('secondary');

            svg {
                fill: color('white');
            }
        }
    }
}
