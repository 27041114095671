/**
 * Loader molecule
 */

.m-loader {
    align-items: center;
    background-color: rgba(color('white'), 0.25);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
    z-index: 5;

    &__content {
        display: flex;
        font-size: 12.5rem;
        height: 1em;
        position: relative;
        width: 1em;
    }

    &__lineMask {
        animation: 2s infinite linear;
        height: 1em;
        left: 50%;
        margin-left: -0.5em;
        margin-top: -0.5em;
        -webkit-mask-image: -webkit-linear-gradient(top, rgba(color('black'), 1), rgba(color('black'), 0));
        mask-image: linear-gradient(to top, rgba(color('black'), 1), rgba(color('black'), 0));
        mix-blend-mode: hard-light;
        opacity: 0.8;
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform-origin: 0.5em 0.5em;
        width: 0.5em;

        &:nth-of-type(1) {
            animation-fill-mode: both;
            animation-name: loaderMask1;

            @keyframes loaderMask1 {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

        &:nth-of-type(2) {
            animation-fill-mode: both;
            animation-name: loaderMask2;

            @keyframes loaderMask2 {
                0% {
                    transform: rotate(180deg);
                }

                100% {
                    transform: rotate(540deg);
                }
            }
        }
    }

    &__line {
        border-radius: 50%;
        box-shadow: inset 0 0 0 1rem color('secondary');
        height: 1em;
        width: 1em;
    }
}
