.o-contactBrokerModal {
    overflow: hidden;

    &__headline {
        margin-bottom: gutter();
    }

    &__text {
        @include lora();

        margin: gutter('sm') 0;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: gutter('xs');
        margin-bottom: gutter('xs');
    }

    &__formInput input {
        &::placeholder {
            color: color('black');
        }
    }

    &__textareaInput {
        &::before {
            display: none;
        }

        textarea {
            padding: gutter('sm') / 2;
        }
    }

    &__formSubmitButton {
        display: flex;
        margin: gutter('sm') 0 0 auto;
    }

    &__formSuccessHeadline {
        margin: gutter('sm') 0 gutter('xs');
    }

    &__formSuccessText {
        @include lora();
    }

    &__formSuccessWrapper {
        display: flex;
        flex-direction: column;
        gap: gutter('sm');
        height: 100%;
    }

    &__formSuccess {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    &__icon {
        align-items: center;
        background-color: color('greenCustom');
        border-radius: border-radius('round');
        color: color('black');
        display: flex;
        height: gutter('xxl');
        justify-content: center;
        width: gutter('xxl');
    }

    .o-homeEstimateBrokerIntro__content {
        margin: 0;
        max-width: none;
        padding: 0;
    }

    .o-homeEstimateBrokerIntro {
        display: grid;
    }

    .o-homeEstimateBrokerIntro__content-mapContainer {
        background-color: transparent;
    }
}
