/**
 * Neighborhood Communes organism
 */

.o-neighborhoodCommunes {
    $block: &;

    margin: gutter('xxxl') 0;

    &__anchor {
        font-size: font-size('lead');
    }

    &__title {
        margin-bottom: gutter('xxl');
    }
}
