.m-table__advancedTable.m-table__tableShadow {
    .vgt-inner-wrap {
        border-radius: 0;
        box-shadow: none;

        .m-select__customOptions.isOpen {
            display: flex;
            flex-direction: column;
        }

        .vgt-global-search {
            border: none;
            background: none;
            margin-right: auto;
            margin-bottom: gutter('sm');
            max-width: 78svw;

            @include breakpoint('md') {
                width: 100%;
            }

            .vgt-global-search__input {
                padding-left: 0;
                z-index: 2;
                position: relative;
            }

            label {
                display: none;
            }

            .vgt-input {
                background-color: #f3f3f3;
                border: 0;
                border-bottom: 0.25rem solid #000;
                border-radius: 0;
                color: #000;
                font-size: 1.75rem;
                font-weight: 500;
                height: 6.25rem;
                line-height: 1.25;
                padding: 0 2rem;
                transition: 0.25s ease-in-out;
                transition-property: border, color;
                width: 100%;

                @include breakpoint('md') {
                    max-width: rem(360);
                }
            }
        }

        .vgt-table {
            $block: &;

            box-shadow: box-shadow();
            padding: gutter('xs');
            word-break: break-word;

            &__title {
                font-size: font-size('h3');
                margin-bottom: gutter('sm');
                margin-top: gutter('xs');

                @include breakpoint('md') {
                    margin-left: gutter('sm');
                }
            }

            &__tableText {
                overflow: unset;
                text-overflow: unset;
                white-space: unset;
            }

            &__tableContent {
                display: inline-block;
                min-width: 100%;
            }

            &__tableShadow {
                position: relative;

                &::before,
                &::after {
                    bottom: 0;
                    content: '';
                    display: block;
                    opacity: 0;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    transition: opacity speed() easing();
                    width: gutter();
                    z-index: 1;
                }

                &::before {
                    background: linear-gradient(
                        to right,
                        rgba(color('white'), 1),
                        rgba(color('white'), 0)
                    );
                    left: 0;
                }

                &::after {
                    background: linear-gradient(
                        to left,
                        rgba(color('white'), 1),
                        rgba(color('white'), 0)
                    );
                    right: 0;
                }

                &.isOverflownLeft {
                    &::before {
                        opacity: 1;
                    }
                }

                &.isOverflownRight {
                    &::after {
                        opacity: 1;
                    }
                }
            }

            &__tableWrapper {
                display: block;
                overflow-x: auto;
                white-space: nowrap;
            }

            thead {
                border-bottom: none;

                tr:nth-child(odd) {
                    background-color: color('gray-dark');
                }

                th {
                    background-color: color('gray-light');
                    background: none;
                    color: color('black');
                    padding-right: gutter('sm');
                    vertical-align: middle;
                    word-break: keep-all;
                }

                th:last-child:not(.tags) {
                    padding-right: gutter('sm');
                }
            }

            table {
                background-color: color('white');
                border: none;
            }

            td {
                @extend .a-tableCell;

                vertical-align: top;
                border-bottom: none;
                color: color('black');
                min-width: 10ch;
            }

            th {
                @extend .a-tableCell;

                font-weight: font-weight('medium');
            }

            thead {
                @extend .a-tableHead;
            }

            tr {
                @extend .a-tableRow;
            }

            &__search {
                margin-bottom: gutter('sm');
            }
        }

        .vgt-wrap__footer {
            background: none;
            border: none;
            overflow-x: auto;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 1rem 0;

            @include breakpoint('md') {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            form {
                display: flex;
                white-space: nowrap;
            }

            .vgt-pull-right {
                display: flex;
                align-items: flex-start;

                @include breakpoint('md') {
                    width: 100%;
                    justify-content: flex-end;
                    align-items: center;
                }
            }

            .footer__navigation,
            .footer__row-count__label,
            .footer__navigation__page-btn span,
            .footer__row-count__select {
                font-size: font-size('label');
                color: color('black');
                font-weight: font-weight('medium');
            }

            .footer__navigation__page-btn .chevron:after,
            .footer__navigation__page-btn .chevron.right::after {
                margin-top: -4px;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left-color: color('black');
                border-right-color: color('black');
            }

            .footer__row-count::after {
                right: gutter('xxs');
                margin-top: -2px;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid color('black');
            }

            .footer__navigation__page-btn {
                margin: 0 gutter('xxs');

                :not(span.chevron) {
                    display: none;

                    @include breakpoint('md') {
                        display: block;
                        margin-top: 1px;
                    }
                }

                @include breakpoint('md') {
                    display: flex;
                    align-items: center;
                }
            }

            .footer__navigation__page-info {
                margin: 0 gutter() 0 0;
                color: color('black');

                @include breakpoint('md') {
                    margin: 0 gutter('xs');
                }
            }
        }
    }
}
