/**
 * Module Small Campaign organism
 */

.o-moduleSmallCampaign {
    $block: &;

    align-items: center;
    display: flex;
    gap: 2rem;
    padding: 2rem;

    @include breakpoint('md') {
        gap: 4rem;
        padding: 2.5rem;
    }

    &--whiteBorder {
        background-color: color('white');
        border: border-width('base') solid color('black');
        color: color('black');
    }

    &--whiteShadow {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow();
        color: color('black');
    }

    &--green {
        background-color: color('green');
        color: color('white');
    }

    &--greenDark {
        background-color: color('green-dark');
        color: color('white');
    }

    &--paperYellowLight {
        background-color: color('paperYellow-light');
        color: color('black');
    }

    &--yellow {
        background-color: color('yellow');
        color: color('black');
    }

    &--orange {
        background-color: color('orange');
        color: color('black');
    }

    &--sandLight {
        background-color: color('sand-light');
        color: color('black');
    }

    .a-anchor {
        align-items: center;
        color: color('blue');
        display: inline-flex;
        font-size: font-size('label');
        gap: 0.5em;
        text-decoration: none;

        svg {
            flex: none;
            height: 1.715em;
            pointer-events: none;
            width: 1.715em;
        }
    }

    &__illustration {
        flex: none;
        height: clamp(104px, 9vw, 134px);
        width: clamp(104px, 9vw, 134px);

        img {
            height: inherit;
            object-fit: cover;
            width: inherit;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        &Headline {
            font-size: clamp(14px, 3vw, 24px);
            line-height: 1.3;
        }
    }
}
