/**
 * Button atom
 */

button {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0;
    color: color('black');
    cursor: pointer;
    font-weight: 500;
    outline: none;
    padding: 0;
    position: relative;
    user-select: none;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: inherit;
    }

    &[disabled] {
        pointer-events: none;
    }
}
