/**
 * Error template
 */

.t-error {
    &__logoLink {
        align-items: center;
        display: inline-flex;
    }

    &__logo {
        height: 3.625rem;
        width: auto;
    }

    &__collaboration {
        height: 3rem;
        margin-left: gutter('xs');
        width: auto;
    }

    &__label {
        margin: gutter('sm') 0 gutter('xxs');
    }

    &__title {
        margin: 0 0 gutter();
    }

    &__content {
        margin: gutter() 0 gutter();
    }
}
