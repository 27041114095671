.m-infoModal {
    display: flex;

    &--left {
        padding-right: gutter('xxs');
    }

    &__button {
        border-radius: 100%;
        color: color('secondary');
        cursor: pointer;
        display: inline-flex;
        transition: opacity speed() easing();

        &:hover {
            @media (hover: hover) {
                opacity: 0.75;
            }
        }

        &:focus-visible {
            outline: solid 2px color('black');
        }
    }
}
