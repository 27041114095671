/**
 * Panel organism
 */

.o-panel {
    $block: &;

    left: 0;
    padding-bottom: gutter('lg');
    padding-top: gutter('xs');
    position: absolute;
    right: 0;
    top: 0;
    transition: speed() easing();
    transition-property: opacity, transform;

    &--spacedChildrenLarge {
        @include owl(4rem);
    }

    &--spacedChildrenMedium {
        @include owl(3rem);
    }

    &--spacedChildrenSmall {
        @include owl(2rem);
    }

    &--next {
        &-enter-active,
        &-leave-active {
            opacity: 1;
            transform: translateX(0);
        }

        &-enter {
            opacity: 0;
            transform: translateX(calc(100% + #{gutter()}));
        }

        &-leave-to {
            opacity: 0;
            transform: translateX(calc(-100% - #{gutter()}));
        }
    }

    &--prev {
        &-enter-active,
        &-leave-active {
            opacity: 1;
            transform: translateX(0);
        }

        &-enter {
            opacity: 0;
            transform: translateX(calc(-100% - #{gutter()}));
        }

        &-leave-to {
            opacity: 0;
            transform: translateX(calc(100% + #{gutter()}));
        }
    }

    &__title {
        margin-bottom: gutter('sm');
    }

    &__panel {
        position: absolute;
    }

    &__heading {
        margin-bottom: gutter('lg');
        max-width: 20ch;
        text-wrap-style: balance;

        @include breakpoint('md') {
            max-width: 21ch;
        }
    }

    &__select {
        margin-bottom: gutter('xs');
    }

    &__brokerCard {
        margin-bottom: gutter('xs');
    }

    &__broker {
        align-items: flex-start;
        display: flex;
        margin-bottom: gutter('sm');
        margin-top: -#{gutter('xs')};

        @include breakpoint('md') {
            margin-top: -#{gutter('md')};
        }
    }

    &__brokerImage {
        border-radius: border-radius('round');
        height: gutter('xl');
        margin-right: gutter('xxs') * 1.5;
        object-fit: cover;
        width: gutter('xl');
    }

    &__brokerInfo {
        margin-right: gutter('lg');
    }

    &__name {
        margin-bottom: 0;
    }

    &__location {
        font-size: font-size('label');
        line-height: line-height('small');
    }

    &__estimate {
        align-items: center;
        background-color: color('paperYellow');
        border-radius: border-radius('large');
        color: color('black');
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: gutter('xs');
        padding: gutter('xs') gutter('sm');
    }

    &__estimatePrice {
        margin-top: 0;
    }

    &__nav {
        margin-bottom: gutter('sm');
        margin-top: gutter('xxl');
    }

    &__navItem {
        align-items: center;
        color: color('black');
        display: block;
        display: flex;
        justify-content: space-between;
        margin: 0 0 gutter('sm');
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: speed() easing();
        transition-property: color, text-decoration-color;
        width: 100%;

        [screen-reader] &:focus,
        [mouse] &:hover {
            color: color('secondary');
            text-decoration: underline;
            text-decoration-color: inherit;
        }
    }

    &__owner {
        background: color('black');
        border-radius: 0 0 10px 10px;
        color: color('white');
        font-size: font-size('label');
        padding: 0.5rem gutter('xxs');
        position: absolute;
        top: -2rem;

        @include breakpoint('md') {
            top: -7.5rem;
        }
    }

    &__buyerAdviceImage {
        max-width: 100%;
        width: auto;
    }

    &.o-salesValuation__overlay {
        padding-top: gutter('lg');
    }
}
