.o-brokerHero {
    $block: &;

    &__media {
        @include breakpoint('md') {
            height: 70rem;
        }
    }

    &__mediaImage {
        display: block;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center 50%;
    }

    &__detailsGrid {
        display: grid;
        gap: gutter('xl') 0;
        grid-template-areas:
            "intro"
            "description"
            "cards"
            "special";
        grid-template-columns: minmax(20px, 1fr);
        grid-template-rows: max-content max-content max-content max-content;

        @include breakpoint('md') {
            gap: gutter('md');
            grid-template-areas:
                ". cards cards cards . intro intro intro intro intro intro ."
                ". cards cards cards . description description description description description description ."
                ". cards cards cards . special special special special special special .";
            grid-template-columns: repeat(12, minmax(20px, 1fr));
            margin-bottom: -16rem;
            transform: translateY(-16rem);
        }
    }

    &__gridContent {
        background-color: color('white');
        box-shadow: -7rem 0 0 0 color('white'), 17rem 0 0 0 color('white');
        grid-area: intro;

        @include breakpoint('md') {
            margin-bottom: 0;
        }
    }

    &__gridDescription {
        grid-area: description;

        @include owl(gutter('sm'));
    }

    &__gridSpecial {
        border: border-width() solid color('black');
        grid-area: special;
    }

    &__gridCards {
        grid-area: cards;

        @include breakpoint('md') {
            grid-row: 1 / -1;
        }
    }

    &__detailsContent {
        @include breakpoint('md') {
            margin-top: -15rem;
        }
    }

    &__brokerDetails {
        background-color: color('white');
        width: 100%;
    }

    &__brokerInfoWrapper {
        border-bottom: border-width() solid color('black');
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        @include breakpoint('md') {
            margin-bottom: gutter('lg');
        }
    }

    &__brokerInfo {
        display: flex;
        margin-bottom: gutter('sm');
        position: relative;
        width: 100%;

        @include breakpoint('md') {
            flex-direction: column;
            justify-content: flex-start;
            width: auto;
        }

        &__divider {
            display: none;

            @include breakpoint('md', 'down') {
                display: inline;
            }
        }

        &--contactInfo {
            color: color('gray-dark');
            display: block;
            flex: 1 1 100%;
            font-size: font-size('label');
        }

        .a-anchor {
            margin-right: gutter('xxs');
        }
    }

    &__expandable {
        align-items: center;
        display: flex;
        justify-content: space-between;
        transition: color speed() easing();
    }

    &__expandableButton {
        text-align: left;
        width: 100%;

        [screen-reader] &:focus .o-brokerHero__expandable,
        [mouse] &:hover .o-brokerHero__expandable {
            color: color('secondary');
        }
    }

    &__expandableIcon {
        #{$block}__expandable.isOpen & {
            transform: rotate(180deg);
        }
    }

    &__openingHours {
        background-color: #fff;
        border-radius: 1.25rem;
        box-shadow: 0 0 6rem rgba(0, 0, 0, 0.1);
        height: auto;
        opacity: 0;
        padding: gutter('sm');
        position: absolute;
        right: 0;
        top: 100%;
        transform: translateY(-5px);
        transition: all 0.3s ease-in-out;
        visibility: hidden;
        width: auto;
        z-index: 1;

        &--visible {
            opacity: 1;
            transform: translateY(5px);
            visibility: visible;
        }

        table {
            border: none;

            th,
            td {
                font-weight: 500;
                text-align: left;
                white-space: nowrap;
            }

            td {
                color: color('gray-dark');
            }
        }
    }

    &__brokerTitle {
        margin-bottom: gutter('xs');
        margin-top: gutter();

        @include breakpoint('md') {
            margin-bottom: gutter();
            margin-top: gutter('xxl');
        }
    }

    &__brokerDescTitle {
        margin-bottom: gutter('sm');
    }

    &__brokerSpecialContent {
        padding: gutter();

        @include owl(gutter('sm'));
    }

    &__anchorMenu {
        margin-bottom: gutter('xxxl');
        margin-top: gutter();
    }

    &__mediaButton {
        align-items: center;
        background-color: color('secondary');
        border-radius: border-radius('round');
        color: color('white');
        display: flex;
        height: 7rem;
        justify-content: center;
        margin-bottom: -7rem;
        margin-left: gutter('xs');
        position: relative;
        top: -#{4.5rem};
        width: 7rem;

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: color('secondary:hover');
        }

        @include breakpoint('md') {
            margin-left: 0;
            top: -#{3.5rem};
        }
    }

    // &__brokerCard {
    //     @include breakpoint('md') {
    //         margin-bottom: gutter();
    //     }
    // }

    // &__ctaShadow {
    //     background-color: color('white');
    //     border-radius: border-radius('large');
    //     box-shadow: box-shadow();
    //     color: color('black');
    //     height: 383px;
    //     margin-bottom: gutter('lg');
    //     width: 100%;

    //     @include breakpoint('md') {
    //         margin-bottom: 12rem;
    //         margin-left: 13rem;
    //         width: 1168px;
    //     }
    // }
}
