/**
 * Order utility
 */

@for $i from 1 through $grid-columns {
    .u-order-#{$i} {
        order: #{$i};
    }
}

@each $key, $value in $grid-containers {
    @for $i from 1 through $grid-columns {
        @include breakpoint($key) {
            .#{$key}\:u-order-#{$i} {
                order: #{$i};
            }
        }
    }
}
