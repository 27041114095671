/**
 * Search select organism
 */

.o-searchSelect {
    $block: &;

    border-bottom: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    position: relative;

    &__field {
        background-color: transparent;
        cursor: pointer;
        font-size: font-size('label');
        padding-right: calc(1rem + 16px);
        transform: translateX(16px);

        [mouse] & {
            visibility: hidden;
        }
    }

    &__icon {
        [mouse] & {
            visibility: hidden;
        }
    }

    &__customWrapper {
        display: none;
        left: -10%;
        position: absolute;
        top: -68%;
        width: 115%;
        z-index: 2;

        &.isOpen {
            background-color: color('white');
            border-radius: border-radius('large');
            box-shadow: box-shadow();
        }

        [mouse] & {
            display: block;
        }
    }

    &__customTrigger {
        align-items: center;
        display: flex;
        font-size: font-size('label');
        font-weight: font-weight('medium');
        justify-content: flex-end;
        padding: gutter('xs') gutter('xxs') gutter('xs') gutter('xs');
        transition: color speed() easing();
        width: 100%;

        &:hover {
            color: color('secondary');
        }

        #{$block}__customWrapper.isOpen & {
            border-bottom: border-width('small') solid color('gray-light');
        }
    }

    &__customIcon {
        margin-left: gutter('xxs');
    }

    &__customOptions {
        display: none;

        &.isOpen {
            border-radius: 0 0 border-radius('large') border-radius('large');
            display: block;
            max-height: 26rem;
            overflow-y: scroll;
        }
    }

    &__customOption {
        border-bottom: border-width('small') solid color('gray-light');
        font-size: font-size('label');
        padding: gutter('xs');
        text-align: right;
        transition: speed() easing();
        transition-property: background-color, border-color, color;
        width: 100%;

        &:last-child {
            border-bottom: 0;
        }

        &:hover,
        &:focus {
            color: color('secondary');
        }

        &.isSelected {
            background-color: color('secondary');
            border-bottom-color: color('secondary');
            color: color('white');
        }
    }
}
