.o-homeEstimateFlow {
    background: color('white');
    block-size: 100%;
    inset-inline-start: 0;
    margin: 0;
    overflow-y: auto;
    position: fixed;
    inset-block-start: 0;
    transition: transform speed('slow') easing();
    inline-size: 100%;
    z-index: 12;

    .no-flow-animation & {
        transition-duration: 0s;
    }

    &-enter-active,
    &-leave-active {
        transform: translateX(0);

        .o-homeEstimateFlow__inner {
            opacity: 1;
        }
    }

    &-enter,
    &-leave-to {
        transform: translateX(100%);

        .o-homeEstimateFlow__inner {
            opacity: 0;
        }
    }

    &__inner {
        opacity: 1;
        transition: opacity speed('slow') easing() 0.4s;
    }

    &__column {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    // Header
    &__header {
        align-items: center;
        display: flex;
        block-size: 11rem;

        @include breakpoint('md') {
            align-items: center;
            display: flex;
            block-size: 15.75rem;
            padding: 0 gutter('xs');
        }

        .u-container {
            padding: 0 2rem;

            @include breakpoint('md') {
                padding: 0 3rem;
            }
        }
    }

    &__steps {
        display: flex;
        justify-content: center;
        margin: 0 gutter('xs');
        position: relative;

        @include breakpoint('sm', 'down') {
            margin: 0;
        }
    }

    &__logo {
        &__img {
            block-size: 3.5rem;
            inline-size: auto;

            @include breakpoint('md') {
                block-size: 3.75rem;
            }
        }
    }

    .m-statusIndicator {
        margin-block-start: rem(-6);
    }

    &__step {
        display: flex;
        flex-direction: column;
        opacity: 1;
        overflow: hidden;

        @include breakpoint('sm', 'down') {
            max-inline-size: none;
            inline-size: 100%;
        }

        &:not(.u-container) {
            align-items: center;
        }

        &--next,
        &--prev {
            &-enter-active,
            &-leave-active {
                position: absolute;
                inset-block-start: 0;

                .o-homeEstimateFlow__actions {
                    transition: opacity 0 ease 0.5s;
                }

                .o-homeEstimateFlow__step__headline,
                .o-segmentationStep__headline,
                .o-homeEstimateSlider,
                .o-homeEstimateBrokerIntro {
                    transition: opacity 0.25s ease;
                }

                .o-segmentationStep__segments,
                .o-homeEstimateFlow__step__image img,
                .o-homeEstimateFlow__step__image svg {
                    transition:
                        opacity 0.25s ease,
                        transform 0.25s ease-out;
                }

                .o-homeEstimateParamaterExplainer {
                    transition:
                        opacity 0.25s ease,
                        transform 0.35s cubic-bezier(0.47, 1.64, 0.41, 0.8);
                }

                .o-homeEstimateOrderEstimate {
                    transition:
                        opacity 0.25s ease,
                        transform 0.35s ease-out;
                }
            }

            &-enter-active {
                .o-homeEstimateFlow__step__headline,
                .o-segmentationStep__headline,
                .o-homeEstimateSlider,
                .temp-img,
                .o-homeEstimateBrokerIntro {
                    transition-delay: 0.15s;
                }

                .o-segmentationStep__segments,
                .o-homeEstimateFlow__step__image img,
                .o-homeEstimateFlow__step__image svg {
                    transition-delay: 0.15s;
                }

                .o-homeEstimateParamaterExplainer {
                    transition-delay: 0.2s;
                }

                .o-homeEstimateOrderEstimate {
                    transition-delay: 0.2s;
                }
            }

            &-enter,
            &-leave-to {
                .o-homeEstimateFlow__step__headline,
                .o-segmentationStep__headline,
                .o-homeEstimateFlow__actions,
                .o-homeEstimateSlider,
                .o-homeEstimateBrokerIntro {
                    opacity: 0;
                }

                .o-homeEstimateParamaterExplainer {
                    opacity: 0;
                    transform: translateY(rem(30));
                }

                .o-homeEstimateOrderEstimate {
                    opacity: 0;
                    transform: translateY(rem(20));
                }
            }

            &-enter {
                .o-segmentationStep__segments,
                .o-homeEstimateFlow__step__image img,
                .o-homeEstimateFlow__step__image svg {
                    opacity: 0;
                    transform: translateX(25%);
                }
            }

            &-leave-to {
                .o-segmentationStep__segments,
                .o-homeEstimateFlow__step__image img,
                .o-homeEstimateFlow__step__image svg {
                    opacity: 0;
                    transform: translate(-25%);
                }
            }
        }

        &--prev {
            &-enter {
                .o-segmentationStep__segments,
                .o-homeEstimateFlow__step__image img,
                .o-homeEstimateFlow__step__image svg {
                    transform: translateX(-25%);
                }
            }

            &-leave-to {
                .o-segmentationStep__segments,
                .o-homeEstimateFlow__step__image img,
                .o-homeEstimateFlow__step__image svg {
                    transform: translateX(25%);
                }
            }
        }

        &__lottieWrapper {
            block-size: inherit;
            isolation: isolate;
            overflow: hidden;
            pointer-events: none;

            svg {
                @include breakpoint('sm', 'down') {
                    scale: 2;
                }
            }
        }

        &__info {
            color: color('blue');
            transition: color 0.25s ease;
            vertical-align: bottom;

            &:focus-visible {
                color: color('black');
            }
        }

        &__image {
            block-size: 19rem;
            margin: gutter('sm') 0 1.5rem;
            max-inline-size: 180rem;
            inline-size: 100%;

            @include breakpoint('sm', 'down') {
                block-size: rem(100);
                margin-block-end: gutter('xs');
                // margin-inline-start: -2rem;
                // margin-inline-end: -2rem;
                // inline-size: calc(100% + 4rem);
            }

            img {
                @include breakpoint('sm', 'down') {
                    block-size: 100%;
                    object-fit: cover;
                    inline-size: 100%;
                }
            }
        }

        &__headline {
            font-size: font-size('h3');
            font-weight: font-weight('medium');

            @include breakpoint('sm', 'down') {
                font-size: font-size('lead');
            }
        }

        &__corner-info {
            inset-block-end: gutter('lg');
            inset-inline-start: gutter('lg');
            position: fixed;
            z-index: 5;

            @include breakpoint('sm', 'down') {
                inset-block-end: auto;
                inset-inline-start: auto;
                margin-block-end: 7rem;
                padding: 0 gutter('xs');
                position: relative;
                z-index: 5;
            }

            .o-homeEstimateParamaterExplainer {
                margin-block-end: rem(14);
            }
        }
    }

    &__actions {
        display: flex;
        gap: gutter('lg');
        justify-content: center;
        margin-block-end: gutter('sm');
        margin-block-start: gutter('sm');
        padding: 0 gutter('xs');
    }
}
