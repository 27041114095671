/**
 * USP grid organism
 */

.o-uspGrid {
    $block: &;

    display: grid;
    gap: 3rem;

    @include breakpoint('md') {
        --itemSize: 295px;

        grid-template-columns: repeat(auto-fit, minmax(var(--itemSize), 1fr));
    }

    @include breakpoint('lg') {
        gap: 4rem;
    }

    &__item {
        background-color: color('white');
        border-radius: 10px;
        color: color('black');
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 1rem;
        line-height: 1.4;
        padding: 3rem 2rem;

        @include breakpoint('lg') {
            padding: 4rem;
        }
    }
}
