/**
 * Content organism
 */

.o-content {
    $block: &;

    a {
        @extend .a-anchor;
    }

    h2 {
        @extend .a-heading2;

        margin-bottom: gutter('sm');
        margin-top: gutter('lg');
    }

    h2.a-heading1 {
        @extend .a-heading1;

        margin-bottom: gutter('sm');
        margin-top: gutter('lg');
    }

    h3 {
        @extend .a-heading3;

        margin-bottom: gutter('sm');
        margin-top: gutter('lg');
    }

    h4 {
        @extend .a-heading4;

        margin-bottom: gutter('sm');
        margin-top: gutter('lg');
    }

    li {
        @extend .a-listItem;

        margin: 0;
    }

    ol {
        @extend .a-orderedList;

        display: grid;
        gap: gutter('xxs');
        margin-bottom: gutter('sm');
        margin-top: gutter('sm');
    }

    ul {
        @extend .a-unorderedList;

        display: grid;
        gap: gutter('xxs');
        margin-bottom: gutter('sm');
        margin-top: gutter('sm');
    }

    p {
        @extend .a-paragraph;

        margin: gutter('sm') 0;
    }

    &#{$block}--lead p {
        @extend .a-lead;
    }

    small {
        @extend .a-small;
    }

    > * {
        &:first-child {
            margin-top: 0 !important;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
