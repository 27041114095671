/**
 * Wildcard atom
 */

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;

    &,
    &::before,
    &::after {
        box-sizing: inherit;
    }
}
