/**
 * Broker Store Card organism
 */

.o-brokerStoreCard {
    $block: &;

    border-radius: border-radius('large');
    //  TODO: Edit box-shadow
    box-shadow:
        0 10px 80px rgba(0, 0, 0, 0.06),
        0 22.3363px 17.869px rgba(0, 0, 0, 0.0317),
        0 12.5216px 10.0172px rgba(0, 0, 0, 0.015),
        0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    position: relative;

    &__brokerPortrait {
        flex: none;
        height: auto;
        position: relative;
        width: 40%;
    }

    &__wrapper {
        align-items: center;
        display: flex;
    }

    &__content {
        width: 60%;
    }

    &__innerContent {
        padding: 0 gutter('xs');
    }

    &__image {
        border-radius: border-radius('large') 0 0 border-radius('large');
        display: block;
    }

    &__name {
        font-size: font-size('label');
        line-height: line-height('small');
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include breakpoint('md') {
            font-size: font-size('lead');
        }
    }

    &__info {
        align-items: flex-end;
        display: flex;
        font-size: font-size('small');
        justify-content: space-between;
        line-height: line-height('small');
        margin-top: gutter('xxs');

        @include breakpoint('md') {
            font-size: font-size('label');
        }
    }

    &__phoneLink {
        display: flex;
        line-height: line-height('base');
    }
}
