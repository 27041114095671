/**
 * Profile Button organism
 */

.o-mobileProfile {
    position: relative;

    @include breakpoint('md') {
        display: none;
    }

    &__button {
        max-width: 35rem;
    }

    &__buttonText {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__profileDropdown {
        background-color: color('primary');
        border: border-width() solid color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow('normal');
        color: color('white');
        left: 0;
        margin-top: 1rem;
        padding: gutter('sm');
        text-align: center;
        top: 100%;
        visibility: hidden;
        white-space: nowrap;
        width: -moz-fit-content;
        width: fit-content;
        z-index: 1;

        &.isMounted {
            visibility: visible;
        }

        @include owl(1.5rem);
    }
}
