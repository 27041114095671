/**
 * Textarea molecule
 */

.m-textarea {
    $block: &;

    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        background-color: color('gray-light');
        content: '';
        height: 2.6rem;
        left: 0;
        position: absolute;
        right: 2rem;
        top: 0;
    }

    &.isDisabled {
        cursor: default;
        pointer-events: none;
    }

    &__label {
        color: color('secondary');
        font-size: font-size('small');
        left: gutter('xs');
        pointer-events: none;
        position: absolute;
        top: gutter('xxs');
        transition: speed() easing();
        transition-property: color, font-size, top, transform;

        #{$block}.isEmpty & {
            color: color('black');
            font-size: font-size('label');
            top: gutter('xs');
        }

        #{$block}.isFocused & {
            color: color('secondary');
            font-size: font-size('small');
            top: gutter('xxs');
            transform: translateY(0);
        }

        #{$block}.hasError & {
            color: color('logoRed');
            font-size: font-size('small');
            top: gutter('xxs');
            transform: translateY(0);
        }

        #{$block}.isDisabled & {
            color: rgba(color('black'), 0.2);
        }
    }

    &__field {
        #{$block}--black &,
        #{$block}--primary &,
        #{$block}--secondary & {
            background-color: color('white');

            &:-webkit-autofill {
                box-shadow: inset 0 0 0 gutter() color('white');
            }
        }

        #{$block}--black &,
        #{$block}--primary & {
            border-color: color('secondary');
        }

        #{$block}.hasLabel & {
            padding: gutter('sm') gutter('xs') gutter('xxs');
        }

        #{$block}.isFocused & {
            border-color: color('secondary');
        }

        #{$block}--black.isFocused &,
        #{$block}--primary.isFocused & {
            border-color: color('secondary:hover');
        }

        #{$block}--secondary.isFocused & {
            border-color: color('black:hover');
        }

        #{$block}.hasError & {
            border-color: color('logoRed');
        }

        #{$block}.isDisabled & {
            border-color: rgba(color('black'), 0.2);
        }
    }
}
