/**
 * Broker card organism
 */

.o-employeeShopPanelCard {
    $block: &;

    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    position: relative;

    &__wrapper {
        --portraitSize: 10rem;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: gutter('sm');

        @include breakpoint('md') {
            --portraitSize: 14rem;
        }
    }

    &__portrait {
        /* stylelint-disable */
        aspect-ratio: 1 / 1;
        /* stylelint-enable */
        height: var(--portraitSize);
        position: relative;
        width: var(--portraitSize);
    }

    &__image {
        border-radius: border-radius('round');
        display: block;
        height: inherit;
        object-fit: cover;
        width: inherit;
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: gutter('xxs');
        justify-content: center;
        margin-left: gutter('xs');
    }

    &__tag {
        background-color: color('black');
        border-radius: 0 0 10px 10px;
        color: color('white');
        left: 0;
        line-height: 1;
        padding: gutter('xxs') gutter('xs');
        position: absolute;
        top: -#{gutter('lg')};

        @include breakpoint('md') {
            top: -10.5rem;
        }
    }
}
