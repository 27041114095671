/**
 * Broker card organism
 */

.o-brokerCard {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    //  TODO: Edit box-shadow
    box-shadow:
        0 10px 80px rgba(0, 0, 0, 0.06),
        0 22.3363px 17.869px rgba(0, 0, 0, 0.0317),
        0 12.5216px 10.0172px rgba(0, 0, 0, 0.015),
        0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    padding: gutter('sm') 2.25rem;
    position: relative;

    &__owner {
        background-color: color('black');
        border-radius: 10px 10px 0 0;
        bottom: 100%;
        color: color('white');
        font-size: font-size('label');
        left: 4rem;
        line-height: 1;
        padding: gutter('xxs') gutter('xs');
        position: absolute;
    }

    &__brokerPortrait {
        max-width: 13rem;
        position: relative;

        #{$block}--flexible & {
            @include breakpoint('md') {
                max-width: 21.5rem;
            }
        }
    }

    &__wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        // gap: gutter('xs');

        #{$block}--flexible & {
            @include breakpoint('md') {
                align-items: center;
                flex-direction: column;
            }
        }
    }

    &__image {
        border-radius: border-radius('round');
        display: block;
    }

    &__content {
        margin-left: gutter('xs');

        #{$block}--flexible & {
            @include breakpoint('md') {
                margin-left: 0;
                margin-top: gutter('xs');
                text-align: center;
            }
        }
    }

    &__name {
        font-size: font-size('label');
        line-height: line-height('small');

        @include breakpoint('md') {
            font-size: font-size('lead');
        }
    }

    &__title {
        font-size: font-size('small');
        line-height: line-height('small');

        @include breakpoint('md') {
            font-size: font-size('label');
        }
    }

    &__info {
        font-size: font-size('small');
        line-height: line-height('small');
        margin-top: gutter('xxs');

        @include breakpoint('md') {
            font-size: font-size('label');
            margin-top: gutter('xs');
        }
    }

    &__phoneText {
        display: none;
        line-height: line-height('base');

        @include breakpoint('md') {
            display: block;
            margin-top: gutter('xs');
        }
    }

    &__phoneLink {
        line-height: line-height('base');
        margin-top: gutter('xs');

        @include breakpoint('md') {
            display: none;
        }

        #{$block}--flexible & {
            @include breakpoint('md') {
                margin-bottom: gutter('xs');
            }
        }
    }

    &__videoButton {
        justify-content: center;
        min-width: 5rem;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
