/**
 * Broker Our Customers Say organism
 */

.o-brokerOurCustomersSay {
    overflow: hidden;

    &__customerStory {
        display: flex;
        flex-direction: column;
        scroll-snap-align: center;
    }

    &__row {
        transition: transform speed() easing();
    }

    &__customerStories {
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        position: relative;
        width: 100%;

        @include breakpoint('sm', 'down') {
            &::-webkit-scrollbar {
                display: none;
            }

            margin: 0;
            -ms-overflow-style: none;
            overflow-x: scroll;
            overflow-y: hidden;
            overscroll-behavior-x: contain;
            padding: 0 gutter();
            scroll-snap-type: x mandatory;
            scrollbar-width: none;
        }
    }

    &__bottom {
        @include breakpoint('sm', 'down') {
            margin: 0 -#{gutter()};
        }
    }

    &__customerStoryImageWrapper {
        height: 18rem;
        width: 100%;

        @include breakpoint('md') {
            height: 45rem;
        }
    }

    &__customerStoryImage {
        display: block;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
    }

    &__customerStorySoldTag {
        align-items: center;
        background-color: color('gray-light');
        border-radius: border-radius('round');
        display: flex;
        flex-direction: row;
        font-size: font-size('label');
        font-weight: font-weight('medium');
        left: 30px;
        line-height: 1;
        padding: 7px 15px;
        position: absolute;
        top: 10px;
    }

    &__customerStoryDescription {
        background-color: color('white');
        display: flex;
        flex-direction: column;
        padding-top: gutter('xs');

        @include breakpoint('md') {
            align-self: flex-end;
            box-shadow: 1px 0 0 0 color('white');
            margin-bottom: -#{gutter('xxxl')};
            margin-top: 0;
            max-width: 448px;
            padding: gutter('lg');
            transform: translateY(-#{gutter('xxxl')});
            width: 100%;
        }
    }

    &__tagline {
        margin-top: gutter('sm');
    }

    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: gutter('xl');
        width: 100%;
    }

    &__navigation {
        align-items: center;
        display: flex;
    }

    &__navigationProgress {
        margin-right: gutter('xs');
    }

    &__navigationButton {
        cursor: pointer;
        display: flex;

        &:last-of-type {
            margin-left: gutter('xs');
        }
    }
}
