/**
 * Box CTA organism
 */

.o-boxCTA {
    $block: &;

    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    text-align: center;

    &--white {
        background-color: color('white');
        box-shadow: box-shadow('normal');
        margin: 0 -#{gutter()};

        @include breakpoint('md') {
            border-radius: border-radius('large');
            margin: 0;
        }
    }

    &--primary {
        background-color: color('primary');
        color: color('white');
    }

    &--wide {
        @include breakpoint('md') {
            flex-direction: row;
            justify-content: center;
            text-align: left;
        }
    }

    &__gradient {
        display: none;

        #{$block}--primary & {
            background: linear-gradient(189.15deg, color('secondary') -13.38%, rgba(63, 169, 245, 0) 59.85%);
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &__graphic {
        margin-bottom: gutter('sm');
        max-width: 21rem;
        z-index: 1;

        #{$block}--wide & {
            @include breakpoint('md') {
                margin-bottom: 0;
                margin-right: gutter('xxl');
            }
        }
    }

    &__heading {
        margin: 0;
        margin-bottom: gutter('xs');
    }

    &__paragraph {
        margin: 0;
        margin-bottom: gutter('sm');
    }
}
