/**
 * Search Map organism
 */

.o-searchMap {
    background-color: color('gray-light');
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &__map {
        bottom: 0;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    .mapboxgl-canvas {
        &:focus {
            outline: none;
        }
    }
}
