/**
 * Estimate Button organism
 */

.o-estimateButton {
    $block: &;

    align-items: stretch;
    background-color: color('secondary');
    border: 0;
    border-radius: border-radius('round');
    bottom: gutter('xxs');
    color: color('white');
    display: flex;
    flex: 0 0 auto;
    font-size: 10px;
    gap: 1.6em;
    height: 5em;
    justify-content: stretch;
    max-width: calc(100vw - 2rem);
    overflow: hidden;
    position: fixed;
    right: gutter('xxs');
    text-align: left;
    transition: speed() easing();
    transition-property: background-color, opacity, transform;
    z-index: 8;

    @include breakpoint('sm', 'down') {
        font-size: 9px;
    }

    @include breakpoint('md', 'down') {
        opacity: 0;
        pointer-events: none;
        transform: translateY(50%);
    }

    @include breakpoint('lg') {
        bottom: gutter('xs');
        right: gutter('xs');
    }

    &.isVisible {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
    }

    &.noImage {
        padding-right: 2.4em;
    }

    [screen-reader] &:focus,
    [mouse] &:hover {
        background-color: color('secondary:hover');
    }

    &__price {
        background-color: color('paperYellow');
        border-radius: 100vw 0 0 100vw;
        color: color('black');
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 0.4em;
        justify-content: center;
        padding: 0 1.5em 0 2.4em;
        text-align: left;
        transition: background-color speed() easing();

        #{$block}:hover & {
            background-color: color('paperYellow:hover');
        }
    }

    &__priceLabel {
        font-size: 1em;
    }

    &__priceCount {
        font-size: 1.4em;
    }

    &__content {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        justify-content: stretch;
    }

    &__text {
        font-size: 1.4em;
    }

    &__image {
        flex: none;
        padding: 0.4em;
        padding-left: 0;

        img {
            aspect-ratio: 1;
            border-radius: border-radius('round');
            display: block;
            height: calc(5em - (2 * 0.4em));
            object-fit: cover;
            width: calc(5em - (2 * 0.4em));
        }
    }
}
