/**
 * Page Hero with product icon organism
 */

.o-pageHeroWithProductIcon {
    $block: &;

    position: relative;

    &__media {
        background-color: color('gray-light');
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-bottom: gutter();
        margin-left: -#{gutter('sm')};
        margin-right: -#{gutter('sm')};
        width: calc(100% + #{gutter('sm') * 2});

        @include breakpoint('md') {
            margin: 0;
            width: 100%;
        }
    }

    &__mediaImage {
        flex: none;
        max-height: 50.5rem;
        object-fit: cover;
        object-position: center center;
    }

    &__contentContainer {
        #{$block}.hasImage & {
            @include breakpoint('sm', 'down') {
                margin-left: gutter('lg');
                margin-top: -#{gutter('xxxl')};
                width: auto;
            }
        }
    }

    &__content {
        display: grid;
        flex: 1 1 auto;
        gap: 3rem;
        place-content: start;
        place-items: start;
        position: relative;

        #{$block}.hasImage & {
            background-color: color('white');
            padding-right: gutter('sm');
            padding-top: gutter();

            @include breakpoint('md') {
                margin-top: -8rem;
                padding-right: 0; // gutter();
                padding-top: #{gutter() * 2};
            }

            &::before {
                background-color: color('white');
                bottom: 0;
                box-shadow: 17rem 0 0 0 color('white');
                content: '';
                left: -#{gutter('sm')};
                position: absolute;
                right: 0;
                top: 0;
                z-index: 0;

                @include breakpoint('md') {
                    left: calc(-25% - 1rem);
                }
            }
        }
    }

    &__title {
        position: relative;

        &--no-margin {
            margin-bottom: 0;
        }
    }

    &__description {
        position: relative;
    }

    &__leftItem {
        position: absolute;
        right: 5rem;
        top: 0;
        transform: translateY(-75%);
        z-index: 9;

        @include breakpoint('md') {
            left: 17rem;
            right: auto;
            transform: translateY(-50%);
        }
    }

    &__splash {
        height: 12rem;
        pointer-events: none;
        width: 12rem;

        @include breakpoint('md') {
            height: 25rem;
            width: 25rem;
        }
    }
}
