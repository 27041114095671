/**
 * Heading 4 atom
 */

.a-heading4 {
    font-family: font-family();
    font-size: font-size('h3') - 0.5rem;
    font-weight: font-weight('medium');
    line-height: line-height('h3');
    position: relative;

    @include breakpoint('lg') {
        font-size: font-size('h3');
    }
}
