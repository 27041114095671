/**
 * News Article template
 */

.t-newsArticle {
    margin-top: gutter();

    @include breakpoint('sm', 'down') {
        margin-top: gutter('xs');
    }
}
