/**
 * Date Picker molecule
 */

.m-datePicker {
    $block: &;

    position: relative;

    @include breakpoint('md') {
        &::before,
        &::after {
            bottom: 0;
            content: '';
            display: block;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: opacity speed() easing();
            width: gutter();
            z-index: 1;
        }

        &::before {
            background: linear-gradient(to right, rgba(color('white'), 1), rgba(color('white'), 0));
            left: 0;
        }

        &::after {
            background: linear-gradient(to left, rgba(color('white'), 1), rgba(color('white'), 0));
            right: 0;
        }

        &.isOverflownLeft {
            &::before {
                opacity: 1;
            }
        }

        &.isOverflownRight {
            &::after {
                opacity: 1;
            }
        }
    }

    @include breakpoint('xl') {
        &::before,
        &::after {
            display: none;
        }
    }

    &--horizontal {
        filter: drop-shadow(box-shadow('small'));
        margin: -#{gutter('xs')} -#{gutter()};

        @include breakpoint('md') {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--vertical {
        &::before,
        &::after {
            display: none;
        }
    }

    &__control {
        color: color('black');
        display: none;

        #{$block}.isNotTouch & {
            background-color: color('white');
            border-radius: border-radius('large');
            bottom: 0;
            display: block;
            margin-bottom: gutter('xs');
            margin-top: gutter('xs');
            position: absolute;
            top: 0;
            transition: speed() easing();
            transition-property: background-color, color;
            width: gutter('md');

            [screen-reader] &:focus,
            [mouse] &:hover {
                background-color: rgba(color('black'), 0.3);
            }

            &:active {
                background-color: color('black');
            }
        }

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }
    }

    &__options {
        &--horizontal {
            display: flex;
            -ms-overflow-style: none;  /* IE and Edge */
            overflow-x: auto;
            padding-bottom: gutter('xs');
            padding-top: gutter('xs');
            scrollbar-width: none;  /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }

            #{$block}.isNotTouch & {
                margin-right: gutter('lg');
                overflow-x: hidden;
            }

            &::after {
                content: '';
                display: block;
                min-width: gutter('md');

                @include breakpoint('md') {
                    display: none;
                }
            }

            &.isScrolled {
                margin-left: gutter('lg');
            }
        }
    }

    &__option {
        display: block;

        &:not(:last-child) {
            margin-bottom: gutter('xxs');
        }

        #{$block}__options--horizontal & {
            margin-bottom: 0;
            // 4rem * 2 for window margin, 3rem for margin between
            min-width: calc((100% - 11rem) / 4);

            #{$block}.isNotTouch & {
                // 3 rem for margin between, fourth of width w/o missing navigation (simulate width after behavior from scroll)
                min-width: calc((100% - 3rem - #{gutter('lg')}) / 4);

                &:first-of-type {
                    min-width: calc(((100% - 3rem - #{gutter('lg')}) / 4) + #{gutter('lg')});
                }
            }

            &:first-of-type {
                margin-left: gutter('md');

                @include breakpoint('md') {
                    margin-left: 0;
                }
            }

            &:not(:last-child) {
                margin-right: gutter('xxs');
            }
        }

        #{$block}__options--horizontal.isScrolled & {
            #{$block}.isNotTouch & {
                // 3 rem for margin between, fourth of width w/o missing navigation (simulate width after behavior from scroll)
                min-width: calc((100% - 3rem) / 4);

                &:first-of-type {
                    min-width: calc((100% - 3rem) / 4 + #{gutter('lg')});
                }
            }
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
        }
    }

    &__optionInput {
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
        position: absolute;
    }

    &__optionContent {
        background-color: color('white');
        border-radius: border-radius('large');
        cursor: pointer;
        display: block;
        font-size: font-size('label');
        line-height: line-height('base');
        padding: gutter('sm') gutter('xs');
        text-align: center;
        transition: speed() easing();
        transition-property: background-color, color;
        user-select: none;

        input:active + &,
        input:hover + & {
            background-color: color('green-dark');
            color: color('white');
        }

        #{$block}__optionContentSmall {
            color: color('secondary');
            transition: speed() easing();
            transition-property: background-color, color;

            input:active + &,
            input:hover + & {
                color: color('white');
            }
        }

        #{$block}__option.hasOpenHouse & {
            position: relative;

            &::after {
                background-color: color('paperYellow');
                border-radius: 0 0 border-radius('large') border-radius('large');
                bottom: 0;
                color: color('black');
                content: 'Åbent hus';
                display: block;
                font-size: 10px;
                left: 0;
                padding: 3px;
                position: absolute;
                right: 0;
                text-align: center;
                transition: background-color speed() easing();
            }
        }

        input:checked + & {
            background-color: color('green-dark');
            color: color('white');

            #{$block}__optionContentSmall {
                color: color('white');
            }
        }

        #{$block}--vertical & {
            align-items: center;
            box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 9rem;
            padding: gutter('xxs') gutter('xs');
        }

        #{$block}--vertical .disabled & {
            cursor: default;
            pointer-events: none;

            #{$block}__optionContentSmall {
                color: color('black');
            }
        }

        #{$block}__options--horizontal & {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 19rem;
            justify-content: center;

            &:first-of-type {
                @include breakpoint('md') {
                    padding: gutter('xs');
                }
            }
        }
    }

    &__optionDay {
        margin-top: 0.5rem;
    }
}
