.o-newsletter {
    $block: &;

    background-color: color('white');
    box-shadow: inset 0 0 0 border-width() color('black');
    color: color('black');
    padding: gutter('sm') gutter();
    width: 100%;

    @include breakpoint('md') {
        padding: gutter('xxl');
    }

    &__stepWrapper {
        display: flex;
        flex-direction: column;

        &--confirmation {
            gap: gutter('xs');

            @include breakpoint('md') {
                align-items: center;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: gutter('xxl');
                height: 100%;
            }
        }
    }

    &__information {
        padding-top: gutter('xs');
    }

    &__submit {
        text-align: center;

        @include breakpoint('md') {
            height: 6.25rem;
            text-align: unset;
        }
    }

    &__inputWrapper {
        display: grid;
        gap: gutter('xs');
        grid-template-columns: 1fr;
        padding-top: gutter('sm');

        @include breakpoint('md') {
            grid-template-columns: repeat(3, 1fr) auto;
        }
    }

    &__input {
        flex-grow: 1;

        &--select {
            margin-bottom: 0;
        }

        &--consent {
            display: grid;
            gap: 2rem;
            grid-template-rows: auto;

            @include breakpoint('md') {
                grid-column: 1/-1;
                grid-row: 2;
            }
        }
    }

    &__inputButton {
        margin-left: auto;
    }

    &__disclaimer {
        display: grid;
        font-size: font-size('small');
        gap: 1rem;
        grid-template-rows: auto;

        a {
            color: color('secondary');
            text-decoration: underline;
        }
    }

    &__confirmation {
        display: flex;
        flex-direction: column;

        @include breakpoint('md') {
            max-width: 70%;
        }
    }

    &__confirmationNotice {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: gutter('xs');
        padding-top: gutter('md');
    }

    &__confirmationCheckmark {
        align-items: center;
        background-color: color('greenCustom');
        border-radius: border-radius('round');
        display: flex;
        height: gutter('xxl');
        justify-content: center;
        padding: 0 gutter('xs');
        width: gutter('xxl');

        @include breakpoint('md') {
            padding: 0;
        }
    }
}
