.o-segmentationStep {
    &__wrapper {
        margin: auto;
        text-align: center;
    }

    &__headline {
        text-align: center;
    }

    &__segments {
        display: grid;
        gap: gutter('xs');
        grid-template-rows: 1fr 1fr 1fr;
        margin: gutter('sm') 0 gutter('xxs');

        @include breakpoint('md') {
            gap: gutter();
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            margin: gutter('lg') 0 gutter();
        }
    }

    &__segment {
        align-items: center;
        border-radius: border-radius('large');
        box-shadow: box-shadow('tiny');
        display: flex;
        flex-direction: column;
        gap: gutter('xs');
        justify-content: space-between;
        padding: gutter() gutter('lg');
        text-align: center;

        @include breakpoint('md') {
            gap: gutter('sm');
            padding: gutter() gutter('lg') gutter('lg');
        }
    }

    &__ctaButton {
        text-align: center;
    }
}
