/**
 * Select molecule
 */

.m-select {
    $block: &;

    border-bottom: 1px solid transparent;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;

    &__wrapper {
        display: flex;
        flex: 1 0 auto;
        justify-content: space-between;
        width: 100%;
    }

    &__field {
        background-color: color('gray-light');
        // Icon "atoms/icons/arrow_drop_down_24px" has been exported as an .svg file from Figma and then encoded to Base64 on https://base64.guru/converter/encode/image/svg
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgOS41TDEyIDE0LjVMMTcgOS41SDdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K');
        background-position: right gutter('xxs') center;
        background-repeat: no-repeat;
        background-size: gutter('sm');
        border-bottom: border-width() solid color('black');
        color: color('black');
        cursor: pointer;
        font-size: font-size('label');
        font-weight: font-weight('medium');
        height: 6.25rem;
        padding: 0 gutter('md') 0 gutter('xs');
        width: 100%;

        [mouse] & {
            visibility: hidden;
        }

        &:focus,
        &:focus-visible {
            border-bottom-color: color('secondary');
        }

        &--visible {
            [mouse] & {
                visibility: visible;
            }
        }

        .o-estimateLegend__item & {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgOS41TDEyIDE0LjVMMTcgOS41SDdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K'),
                url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCI+DQoJPHJlY3QgeD0iNiIgeT0iMTEiIHdpZHRoPSIyIiBoZWlnaHQ9IjIiIC8+DQoJPHJlY3QgeD0iMTEiIHk9IjExIiB3aWR0aD0iMiIgaGVpZ2h0PSIyIiAvPg0KCTxyZWN0IHg9IjE2IiB5PSIxMSIgd2lkdGg9IjIiIGhlaWdodD0iMiIgLz4NCjwvc3ZnPg==');
            background-position:
                right gutter('xxs') center,
                left gutter('xxs') center;
            background-repeat: no-repeat, no-repeat;
            background-size: gutter('sm'), gutter('sm');
            padding: 0 gutter('md') 0 gutter('md');
        }
    }

    &__iconAppend {
        display: none;
        margin-left: gutter('xxs');

        [mouse] & {
            display: block;
            visibility: hidden;
        }
    }

    &__iconPrepend {
        display: none;
        margin-right: gutter('xxs');

        [mouse] & {
            display: block;
            visibility: hidden;
        }
    }

    &__customWrapper {
        background-color: color('gray-light');
        border-bottom: border-width() solid color('black');
        display: none;
        font-size: font-size('label');
        font-weight: font-weight('medium');
        height: 6.25rem;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;

        #{$block}.hasError & {
            border-color: color('logoRed');
        }

        &.isOpen {
            border-color: color('secondary');
            color: color('secondary');
            z-index: 3;
        }

        [mouse] & {
            display: block;
        }

        &:focus-within {
            border-bottom-color: color('secondary');
        }
    }

    &__customTrigger {
        align-items: center;
        display: flex;
        font-size: font-size('label');
        height: 6.25rem;
        justify-content: space-between;
        padding: gutter('xxs') gutter('xs');
        width: 100%;
    }

    &__customTriggerText {
        flex: 1 1 auto;
        margin: 0 gutter('xxs');
        text-align: left;
        white-space: nowrap;

        #{$block}.hasError & {
            color: color('logoRed');
        }
    }

    &__customTriggerIcon {
        flex: 0 0 auto;
        transition: speed() easing();
        transition-property: color, transform;

        #{$block}__customWrapper.isOpen & {
            color: color('secondary');

            &:last-child {
                transform: rotate(180deg);
            }
        }
    }

    &__customOptions {
        display: none;

        &.isOpen {
            background-color: color('white');
            border-radius: 0 0 border-radius('large') border-radius('large');
            box-shadow: box-shadow();
            display: block;
            max-height: 26rem;
            overflow-y: scroll;
        }
    }

    &__customOption {
        font-size: font-size('label');
        padding: gutter('xxs') gutter('xs');
        text-align: left;
        transition: speed() easing();
        transition-property: background-color, color;
        width: 100%;

        &:hover,
        &:focus {
            color: color('secondary');
        }

        &.isSelected {
            background-color: color('secondary');
            color: color('white');
        }
    }
}
