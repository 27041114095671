/**
 * Broker Preview organism
 */

.o-brokerPreview {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    color: color('black');
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: speed() easing();
    transition-property: box-shadow;

    [screen-reader] &:focus-within,
    [mouse] &:hover,
    &.isActive {
        box-shadow: box-shadow('normal:hover');
    }

    &__top {
        display: flex;
        height: 0;
        overflow: hidden;
        position: relative;

        @include aspect-ratio(345, 180);
    }

    &__image {
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__bottom {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        padding: gutter('xs');
    }

    &__address {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-weight: font-weight('medium');
        height: 100%;
        line-height: 1.2;
        margin: 0;
    }

    &__addressElement {
        display: block;
        font-size: font-size('label');
        margin: 1rem 0;
    }

    &__logo {
        display: block;
        height: 2rem;
        margin-bottom: 3px;
        width: auto;
    }

    &__name {
        display: block;
    }

    &__phone {
        display: block;
        margin-top: auto;
    }
}
