/**
 * Property Facts organism
 */

.o-propertyFactsWrapper {
    position: relative;
}

.o-propertyStatusLabels {
    $block: &;

    &__statusLabel {
        align-items: center;
        background: color('black');
        color: color('white');
        display: flex;
        font-size: font-size('label');
        height: gutter('md');
        justify-content: center;
        line-height: 1;
        padding: 0 2rem;
        text-align: center;

        &--luxurious {
            background: color('liebhaver');
            color: color('liebhaver-gold');
        }

        &--undersale {
            background: color('orange');
            color: color('black');

            .luxurious & {
                background-color: var(--liebhaverGold);
            }
        }
    }

    .luxurious & {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        z-index: 1;
    }
}

.o-propertyFacts {
    $block: &;

    background-color: color('green-dark');
    color: color('white');
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: stretch;
    padding: gutter('xs');

    @include breakpoint('md') {
        padding: gutter('md');
    }

    &__labels {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        @include breakpoint('md') {
            margin-bottom: gutter('xs');
        }
    }

    &__label {
        align-items: center;
        display: flex;
    }

    &__labelIcon {
        background-color: color('secondary');
        border-radius: border-radius('round');
        display: flex;
        height: 1rem;
        margin-right: gutter('xxs');
        width: 1rem;
    }

    &__facts {
        --gutter: #{gutter('xs')};

        border-top: border-width() solid color('white');
        display: grid;
        gap: var(--gutter);
        flex: 1 1 auto;
        padding-top: var(--gutter);
        margin-top: var(--gutter);

        .luxurious & {
            @include breakpoint('sm', 'down') {
                margin-top: 0;
            }
        }

        @include breakpoint('md') {
            --gutter: 1.5rem;

            border: none;
            padding: 0;
        }

        li {
            align-items: center;
            display: flex;
            justify-content: space-between;

            .a-anchor.hasIcon {
                align-items: center;
                display: flex;
                gap: gutter('xxs');
            }
        }
    }

    &__connectionFees {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &__factsPrice {
        margin-bottom: -#{gutter('xxs') / 2};
    }

    .luxurious & {
        background-color: transparent;
        border-top: solid 2px color('liebhaver-gold');
        color: color('black');
        position: relative;
        padding-block-start: 4rem;

        @include breakpoint('sm', 'down') {
            margin-block-start: 4rem;
            padding-inline: 0;
        }

        @include breakpoint('md') {
            border: solid 2px color('liebhaver-gold');
        }

        #{$block}__facts li .a-anchor {
            color: inherit;
        }

        #{$block}__labelIcon {
            background-color: color('liebhaver-gold');
        }
    }
}

.o-propertyActions {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    gap: gutter('xs');
    margin-top: gutter('sm');

    @include breakpoint('md') {
        flex-direction: row;
    }

    > * {
        justify-content: center;
        width: 100%;

        @include breakpoint('md') {
            flex: 1;
            justify-content: center;
            min-width: fit-content;
            width: auto;

            &[data-showing] {
                flex: 1.75;
            }
        }
    }
}
