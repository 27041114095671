/**
 * Campaign organism
 */

.o-campaign {
    $block: &;

    height: 100%;

    &--paperYellowLight {
        background-color: color('paperYellow-light');
        color: color('black');
    }

    &--green {
        background-color: color('green');
        color: color('white');
    }

    &--greenPastel {
        background-color: color('greenPastel');
        color: color('black');
    }

    &--greenDark {
        background-color: color('green-dark');
        color: color('white');
    }

    &--white {
        background-color: color('white');
        color: color('black');
    }

    &--whiteShadow {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow();
        color: color('black');
    }

    &--whiteBorder {
        background-color: color('white');
        border: border-width('small') solid color('black');
        color: color('black');
    }

    &__buttons {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: gutter('sm');
    }

    &__content {
        padding: 0 gutter('sm') gutter('lg');
        text-align: center;

        @include breakpoint('md') {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 gutter() gutter('xxl');
            text-align: center;
        }

        @include breakpoint('lg') {
            padding: 0 gutter('xxxl') gutter('xxl');
        }

        &--withoutPicture {
            height: 100%;
        }
    }

    &__image {
        padding: gutter('sm');

        @include breakpoint('md') {
            padding: gutter();
        }
    }

    &__graphic {
        display: block;
        height: 24rem;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
    }

    &__title {
        margin-bottom: gutter('xs');
    }

    &__row {
        height: 100%;
    }
}
