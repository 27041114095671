.o-buyerAdviceForm {
    background-color: color('green');
    color: color('white');
    display: grid;
    gap: 4rem;
    padding: 7rem 4rem;

    @include breakpoint('md') {
        grid-template-columns: repeat(12, 1fr);
        padding: 10rem 0;
    }

    &__stepWrapper {
        display: grid;
        gap: 3rem;
        grid-column: 1/-1;

        @include breakpoint('md') {
            grid-column: 2/-2;
        }

        @include breakpoint('lg') {
            grid-column: 3/-3;
        }

        &--confirmation {
            text-align: center;
        }
    }

    &__heading {
        grid-column: 1/-1;
        text-align: center;
    }

    &__introText {
        grid-column: 1/-1;
        text-align: center;

        a {
            text-decoration: underline;
        }
    }

    &__disclaimer {
        grid-column: 1/-1;
        text-align: center;
    }

    &__inputButton {
        grid-column: 1/-1;
        text-align: center;

        @include breakpoint('md') {
            margin-inline: auto;
        }
    }

    &__form {
        display: grid;
        gap: 2rem;
        grid-column: 1/-1;
        grid-template-columns: 1fr;

        @include breakpoint('md') {
            gap: 3rem;
            grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint('lg') {
            gap: 4rem;
        }

        .full-width {
            grid-column: 1/-1;
            max-width: none;
        }
    }

    &__confirmationCheckmark {
        align-items: center;
        background-color: color('greenCustom');
        border-radius: border-radius('round');
        display: flex;
        height: gutter('xxl');
        justify-content: center;
        margin: 0 auto;
        padding: 0;
        width: gutter('xxl');
    }
}
