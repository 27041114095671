/**
 * Search List organism
 */

.o-searchList {
    width: 100%;

    &__filters {
        margin-bottom: gutter();

        .u-container {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }

    &__intersectionTarget {
        height: 1px;
    }

    &__noResults {
        margin-top: gutter('sm');
    }

    &__noResultsTitle {
        margin-bottom: gutter('sm');
    }

    &__propertyPreview {
        margin-bottom: gutter();
    }

    &__promoBox {
        height: calc(100% - #{gutter()});
        margin-bottom: gutter();
    }
}
