/**
 * Modal organism
 */

.o-modal {
    $block: &;

    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: transform speed('slow') easing();
    z-index: 12;

    &__background {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: speed('slow') easing();
        transition-property: backdrop-filter, opacity;
        z-index: 11;

        #{$block}--popup & {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    &__box {
        background-color: color('white');
        bottom: 0;
        box-shadow: box-shadow();
        color: color('black');
        display: flex;
        overflow: auto;
        padding: gutter();
        position: fixed;
        right: 0;
        top: 0;
        transition: transform speed('slow') easing();
        width: 100%;
        z-index: 12;

        @include breakpoint('md') {
            max-width: 83rem;
            padding: 7.5rem 14rem;
        }

        #{$block}--secondary & {
            background-color: color('secondary');
        }

        #{$block}--primary & {
            background-color: color('primary');
            color: color('white');
        }

        #{$block}--fullWidth & {
            max-width: 100%;
        }

        #{$block}--curtain & {
            padding: 0;
        }

        #{$block}-enter-active &,
        #{$block}-leave-active & {
            transform: translateX(0);
        }

        #{$block}-enter &,
        #{$block}-leave-to & {
            transform: translateX(100%);
        }

        #{$block}--popup & {
            bottom: initial;
            left: 4rem;
            margin: auto;
            max-width: 166rem;
            right: 4rem;
            top: 50%;
            transform: translate(0%, -50%);
            width: auto;
        }
    }

    &__buttons {
        align-items: center;
        display: flex;
        font-size: 0;
        height: 13rem;
        padding: 0 $grid-gutter;
        position: absolute;
        right: 0;
        top: 0;
        transition: top speed() easing();
        z-index: 10;

        @include breakpoint('md') {
            height: auto;
            padding: 0;
            position: fixed;
            right: #{gutter('xxl') - $grid-gutter / 2};
            top: #{gutter('xxl') - $grid-gutter / 2};

            .isScrolled &,
            .isSearchPage & {
                top: #{gutter('lg') - $grid-gutter / 2};
            }
        }
    }

    &__close {
        align-items: center;
        background-color: color('black');
        border-radius: border-radius('round');
        display: flex;
        height: 5rem;
        justify-content: center;
        width: 5rem;

        #{$block}--primary & {
            background-color: color('secondary');
        }

        #{$block}--popup & {
            background-color: color('secondary');
        }

        svg {
            fill: color('white');
        }
    }

    &__content {
        position: relative;
        width: 100%;
    }
}
