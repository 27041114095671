/**
 * Fields organism
 */

.o-fields {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin: -#{gutter('xxs')} 0;

    &__field {
        flex: 1 1 auto;
        margin: gutter('xxs') 0;
    }
}
