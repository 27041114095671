.skeleton-loader {
    background-color: #dddbdd;
    display: block;
    height: 1em;
    overflow: hidden;
    position: relative;

    &--gallerySkeletonLoader {
        align-items: center;
        bottom: 0;
        display: flex;
        height: auto;
        justify-content: center;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        user-select: none;
        z-index: 5;
    }

    &::after {
        animation: shimmer 2s infinite;
        background-image:
            linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
            );
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-100%);
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
