/**
 * Header organism
 */

.o-header {
    --headerGap: 1.5rem;

    align-items: center;
    background-color: color('white');
    box-shadow: box-shadow('tiny');
    display: flex;
    height: 10rem;
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: speed() easing();
    transition-property: height;
    z-index: 10;

    @include breakpoint('md') {
        --headerGap: 2rem;

        box-shadow: box-shadow('small');
        height: 14.75rem;
        padding: 0 #{gutter('xl') - $grid-gutter / 2};
        position: fixed;

        .isScrolled & {
            height: 10rem;
        }
    }

    &.isSearchPage {
        height: 10rem;
        position: fixed;
    }

    &.hasFilters {
        box-shadow: none;

        .o-searchHeader__content {
            box-shadow: none;
        }
    }

    &__row {
        align-items: center;
    }

    &__logo {
        display: block;
        height: 3.5rem;
        width: auto;

        @include breakpoint('md') {
            height: 3.75rem;
        }
    }

    &__collaboration {
        display: inline-block;
        height: 2rem;
        margin-left: gutter('xs');
        width: auto;

        @include breakpoint('sm', 'down') {
            display: none;
        }
    }

    &__toggle {
        cursor: pointer;
        margin-left: -1rem;
        margin-right: -1.5rem;
        user-select: none;

        @include breakpoint('sm', 'down') {
            margin-right: -3rem;
        }
    }

    &__column {
        align-items: center;
        display: grid;
        gap: var(--headerGap);
        grid-template-columns: auto 1fr auto;

        @include breakpoint('sm', 'down') {
            padding: 0 gutter('sm');
        }
    }

    &__buttons {
        display: grid;
        gap: var(--headerGap);
        grid-template-columns: auto auto auto;
    }

    &__profileButton {
        border-radius: border-radius('none');
        display: inline-flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__profileWrapper {
        position: relative;

        @include breakpoint('sm', 'down') {
            display: none;
        }
    }

    &__profileDropdown {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow('normal');
        color: color('black');
        left: 50%;
        padding: gutter('sm');
        position: absolute;
        text-align: center;
        top: 100%;
        transform: translateX(-50%);
        visibility: hidden;
        white-space: nowrap;

        @include owl(1.5rem);

        &.isMounted {
            visibility: visible;
        }
    }

    .m-button--global {
        margin: 0;

        // @include hover-focus() {
        //     background-color: var(
        //         --button-bg-active-clr,
        //         #{color('secondary:hover')}
        //     );
        //     color: var(--button-txt-clr, #{color('white')});
        // }

        @include breakpoint('sm', 'down') {
            padding-inline: gutter('xs');
        }
    }
}
