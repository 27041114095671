.o-findBuyer {
    position: relative;

    /**
    * HERO
    **/
    &__images {
        width: 0;
        position: sticky;
        z-index: -1;
        top: 0;
        left: 50%;
    }
    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 11.5vw;
        max-width: 200px;
        height: auto;
        border-radius: border-radius('round');

        &:nth-child(1) {
            left: -52vw;
            top: 5.75rem;
            width: 18.2vw;
            max-width: 310px;

            @include breakpoint('xxl') {
                left: -104rem;
            }
        }
        &:nth-child(2) {
            top: -14.625rem;
            left: -32.5rem;
        }
        &:nth-child(3) {
            top: 3.625rem;
            left: 54.75rem;
        }
        &:nth-child(4) {
            top: 66.875rem;
            left: -82.75rem;
        }
        &:nth-child(5) {
            top: 51.5rem;
            left: 54.5rem;
            width: 18.2vw;
            max-width: 310px;
        }

        @include breakpoint('md', 'down') {
            width: 10.2vw;

            &:nth-child(1) {
                left: -67rem;
                top: -3rem;
                width: 15.2vw;
            }
            &:nth-child(2) {
                top: -4rem;
                left: -11rem;
            }
            &:nth-child(3) {
                top: 4rem;
                left: 42rem;
            }
            &:nth-child(4),
            &:nth-child(5) {
                display: none;
            }
            &--last {
                width: 15.2vw;
                max-width: 200px;
                height: auto;
                border-radius: border-radius('round');
            }
        }

        @include breakpoint('sm', 'down') {
            width: 100px;

            &:nth-child(1) {
                left: -52vw;
                top: 40px;
                width: 150px;
            }
            &:nth-child(2) {
                top: -8px;
                left: -10vw;
            }
            &:nth-child(3) {
                top: 112px;
                left: 17vw;
            }
            &:nth-child(4),
            &:nth-child(5) {
                display: none;
            }
            &--last {
                width: 150px;
                margin-right: 20px;
                transform: translateY(-25px);
            }
        }
    }
    &__image-below {
        display: none;

        @include breakpoint('md', 'down') {
            display: flex;
            justify-content: end;
            margin-bottom: 30px;
        }
    }
    &__heroText {
        padding: 18.25rem 0 25rem;

        @include breakpoint('md', 'down') {
            padding-bottom: 0;
        }
        @include breakpoint('sm', 'down') {
            padding-top: 240px;
        }
    }
    &__title {
        @include lora();
        font-size: clamp(42px, 7vw, 80px);
    }
    &__scrollButton {
        height: 5rem;
        min-height: 0;
        width: 5rem;
        margin-top: gutter('md');

        @include breakpoint('md', 'down') {
            margin-top: gutter('xs');
        }
        @include breakpoint('md') {
            height: 7rem;
            width: 7rem;
        }
        @include breakpoint('lg') {
            height: 9rem;
            width: 9rem;
        }
    }

    /**
    * FORM
    **/
    &__form {
        &__intro {
            margin-bottom: gutter('md');
        }
        &__headline {
            margin-bottom: gutter('xs');
        }
    }

    &__input {
        margin-bottom: gutter('xs');
    }
    &__input--select {
        z-index: 9;
        margin-bottom: gutter('md');

        .m-select__customTriggerText {
            margin: 0;
        }
    }
    &__sliderHeadline {
        font-size: font-size('label');
    }
    &__sliderPrice {
        font-size: font-size('lead');
        margin-bottom: gutter('xs');
    }
    &__slider {
        margin-bottom: gutter('md');
    }
    .m-rangeSlider__slider {
        margin-bottom: gutter('xxs');
    }
    .vue-slider-dot-tooltip-top {
        visibility: visible;
        top: -3px;

        .vue-slider-dot-tooltip-inner {
            transform: none;
            opacity: 1;

            .vue-slider-dot-tooltip-text {
                width: auto;
                height: auto;
                font-size: 20px;
                color: black;
                display: block;
                transform: none;
            }
        }
    }
    .m-rangeSlider__value {
        font-size: font-size('lead');
        line-height: line-height('lead');
    }
}
