/**
 * Quote atom
 */

.a-quote {
    @include lora();

    font-size: font-size('quote') - 1rem;
    line-height: line-height('quote');

    @include breakpoint('md') {
        font-size: font-size('quote');
    }

    &--white {
        color: color('white');
    }

    &__wrapper {
        &--small {
            border-left: border-width('small') solid color('sand');
            padding-left: gutter('xs');

            .luxurious & {
                border-color: color('liebhaver-gold');
                border-width: border-width('base');
            }
        }
    }
}
