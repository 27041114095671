/**
 * Estimate template
 */

.t-estimate {
    margin-top: gutter();

    @include breakpoint('sm', 'down') {
        margin-top: gutter('xs');
    }
}
