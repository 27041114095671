.o-projectSaleProjectsList {
    display: flex;
    filter: drop-shadow(box-shadow('small'));
    flex-direction: column;
    font-size: font-size('small');
    gap: 12px;
    margin-top: 2rem;

    @include breakpoint('md') {
        gap: 2rem;
    }

    &__link {
        align-items: center;
        background-color: #fff;
        border-radius: border-radius('large');
        display: flex;
        gap: 1em;
        overflow: hidden;

        @include breakpoint('md') {
            font-size: 12px;
            gap: 1.5em;
        }

        @include breakpoint('lg') {
            font-size: font-size('label');
            gap: 2em;
        }

        &__Text {
            display: block;
            flex: 1 1 0%;

            &:nth-child(1) {
                flex: none;
                height: 50px;
                padding: 0;
                position: relative;
                width: 72px;

                @include breakpoint('md') {
                    height: 78px;
                    width: 120px;
                }

                img {
                    display: block;
                    height: inherit;
                    object-fit: cover;
                    width: inherit;
                }

                span {
                    align-items: center;
                    background-color: rgba(0, 0, 0, 0.35);
                    color: color('white');
                    display: flex;
                    font-size: 1.25em;
                    inset: 0;
                    justify-content: center;
                    position: absolute;
                    text-align: center;
                }
            }

            &:nth-child(2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:nth-child(3) {
                max-width: 9em;
            }

            &:nth-child(4) {
                flex: none;
                text-align: right;
                width: 4em;

                @include breakpoint('md') {
                    width: 6em;
                }

                @include breakpoint('lg') {
                    width: 8em;
                }
            }

            &:nth-child(5) {
                max-width: 9em;
                padding-right: 1em;
                text-align: right;

                @include breakpoint('md') {
                    max-width: 15em;
                    padding-right: 0;
                }
            }

            &:nth-child(6) {
                max-width: 10em;
                padding-right: 2em;
                text-align: right;

                @include breakpoint('lg') {
                    max-width: 13em;
                    padding-right: 5em;
                }
            }

            &:nth-child(3),
            &:nth-child(6) {
                display: none;

                @include breakpoint('md') {
                    display: block;
                }
            }
        }

        &:focus {
            outline: solid 2px #3fa9f5;
        }
    }
}
