/**
 * Broker card organism
 */

.o-employeeCard {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    padding: gutter('sm') gutter('xs');
    position: relative;

    @include breakpoint('md') {
        max-width: 36rem;

        &--panel {
            max-width: unset;
            padding: gutter('sm');
        }
    }

    &__wrapper {
        --portraitSize: 13rem;

        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 2rem;

        @include breakpoint('md') {
            --portraitSize: 20rem;

            flex-direction: column;

            #{$block}--panel & {
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }
    }

    &__portrait {
        flex: none;
        height: var(--portraitSize);
        position: relative;
        width: var(--portraitSize);

        // @include breakpoint('md') {
        //     margin-bottom: gutter('xs');

        //     #{$block}--panel & {
        //         margin-bottom: unset;
        //     }
        // }
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: calc(100% - 2rem - var(--portraitSize));

        @include breakpoint('md') {
            text-align: center;
            width: 100%;

            #{$block}--panel & {
                text-align: unset;
                width: calc(100% - 2rem - var(--portraitSize));
            }
        }

        .email {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__image {
        border-radius: border-radius('round');
        display: block;
        height: inherit;
        object-fit: cover;
        width: inherit;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: gutter('xxs');
        margin-bottom: auto;

        @include breakpoint('md') {
            gap: gutter('xs');

            #{$block}--panel & {
                margin-top: gutter('xxs');
            }
        }

        // @include owl(4px);
    }
}
