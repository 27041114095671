/**
 * Property Floorplan organism
 */

.o-propertyFloorplan {
    display: flex;
    flex-direction: column-reverse;
    margin-left: -#{gutter('xs')};
    margin-right: -#{gutter('xs')};
    margin-top: gutter('xxl');
    min-height: 40rem;
    position: relative;

    @include breakpoint('md') {
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint('lg') {
        display: grid;
        gap: 32px;
        grid-gap: 32px;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        margin-top: 0;
    }

    &__content {
        background-color: transparent;
        border: solid 2px #000;
        color: inherit;
        font-size: font-size('label');
        padding-top: 17rem;
        position: relative;

        @include breakpoint('lg') {
            grid-column: 1/10;
            grid-row: 1;
            padding: 0;
        }

        @include breakpoint('xl') {
            grid-column: 2/11;
            grid-row: 1;
        }

        .luxurious & {
            border: none;
        }
    }

    &__options {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow('normal');
        margin-bottom: -17rem;
        margin-left: gutter('xxs');
        margin-right: gutter('xxs');
        padding: gutter('sm');
        position: relative;
        z-index: 1;

        @include breakpoint('sm') {
            margin-left: gutter('xs');
            margin-right: gutter('xs');
        }

        @include breakpoint('lg') {
            align-self: self-start;
            grid-column: 9/13;
            grid-row: 1;
            margin: 0;
            position: relative;
            top: gutter('lg');
        }

        @include breakpoint('xl') {
            grid-column: 9/12;
            grid-row: 1;
        }

        > * {
            margin: 0;
        }

        > * + * {
            margin-top: gutter('sm');
        }
    }

    &__headline {
        font-size: font-size('lead');
        font-weight: font-weight('medium');
    }

    &__floors {
        &:empty {
            display: none;
        }

        > li {
            label {
                cursor: pointer;
                padding: gutter('xxs') 0;
                position: relative;

                [mouse] &:hover {
                    text-decoration: underline;
                }

                input[type='radio'] {
                    position: absolute;
                }

                input[type='radio']:checked + span {
                    color: color('blue');
                    text-decoration: underline;

                    .luxurious & {
                        color: color('liebhaver-gold');
                    }
                }
            }
        }
    }

    &__floorplanImage {
        display: block;
        overflow: hidden;
        padding: gutter('sm');

        @include breakpoint('lg') {
            padding-right: 11.4%;
        }

        @include breakpoint('xl') {
            padding-right: 23%;
        }

        &__container {
            position: relative;

            img {
                display: block;
            }

            button {
                align-items: center;
                background: linear-gradient(
                    180deg,
                    color('blue') 34.16%,
                    rgba(color('blue'), 0) 100%
                );
                border: none;
                border-radius: 100%;
                cursor: pointer;
                display: flex;
                height: 30px;
                justify-content: center;
                left: 0%;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0%;
                transform: translate(-50%, -50%);
                width: 30px;

                &::before {
                    background: color('blue');
                    border-radius: 100%;
                    content: '';
                    height: 20px;
                    margin: auto;
                    position: absolute;
                    width: 20px;
                    z-index: 1;
                }

                &::after {
                    background: color('white');
                    border-radius: 100%;
                    content: '';
                    height: 4px;
                    margin: auto;
                    position: absolute;
                    width: 4px;
                    z-index: 2;
                }

                .outerangle {
                    border-radius: inherit;
                    height: inherit;
                    pointer-events: none;
                    position: relative;
                    transform: rotate(var(--deg));
                    transform-origin: center;
                    width: inherit;
                }

                .innerangle {
                    background: linear-gradient(
                        0deg,
                        rgba(color('blue'), 1) 50%,
                        rgba(color('blue'), 0) 100%
                    );
                    bottom: 100%;
                    height: 120px;
                    left: 50%;
                    margin-bottom: -80px;
                    margin-left: -15px;
                    position: absolute;
                    transform: perspective(70px) rotateX(-70deg);
                    width: 30px;
                }

                &.isActive {
                    background: rgba(color('blue'), 0.3);
                    height: 40px;
                    width: 40px;

                    &::before {
                        background: color('white');
                        height: 30px;
                        width: 30px;
                    }

                    &::after {
                        background: color('blue');
                    }
                }
            }
        }
    }

    &__hotspotImages {
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        overflow-x: auto;
        pointer-events: none;
        scrollbar-width: none; /* for Firefox */
        width: 100%;

        &::-webkit-scrollbar {
            /* Hide scrollbar for Chrome, Safari and Opera */
            display: none;
        }
    }

    &__hotspotImageList {
        display: flex;
        flex-wrap: nowrap;
        list-style: none;
        margin: 0;
        padding: 0;

        > li:not(:first-child) {
            margin-left: gutter('md');
        }

        img {
            // display: block;
            // max-width: 554px;
            // width: 70vw;

            display: block;
            height: 40vw;
            max-height: 369px;
            object-fit: contain;
            width: auto;
        }
    }

    &__hotspotButtonsWrapper {
        margin-left: auto;
        margin-right: auto;
        // width: clamp(0px, 70vw, 554px); /* stylelint-disable-line */
        width: 100%;
    }

    &__hotspotButtons {
        align-items: center;
        display: flex;
        justify-content: center;
        // transform: translateX(1.5rem);

        button {
            padding: gutter('xs') gutter('xxs');

            svg {
                display: block;
            }
        }
    }

    figure.zoom {
        background-position: 50% 50%;
        background-size: 250%;
        cursor: zoom-in;
        overflow: hidden;
        position: relative;

        &.is-zoomed {
            cursor: zoom-out;

            img {
                opacity: 0;
            }
        }

        &.no-image {
            cursor: auto;
            opacity: 0;
        }

        img {
            display: block;
            transition: opacity speed('fast');
            width: 100%;
        }
    }
}
