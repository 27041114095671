/**
 * Body atom
 */

body {
    background-color: color('white');
    color: color('black');
    display: flex;
    flex-direction: column;
    font-family: font-family();
    font-size: font-size();
    font-weight: font-weight('medium');
    line-height: line-height();
    margin: 0;
    min-height: 100%;
}
