/**
 * Article Preview organism
 */

.o-articlePreview {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    color: color('black');
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: speed() easing();
    transition-property: box-shadow;
    width: 100%;

    [screen-reader] &:focus-within,
    [mouse] &:hover {
        box-shadow: box-shadow('normal:hover');
    }

    &__imageWrapper {
        @include aspect-ratio(448, 240);

        position: relative;
    }

    &__mediaTag {
        align-items: center;
        background-color: color('blue');
        border-radius: border-radius('round');
        bottom: -#{gutter('xs')};
        display: flex;
        height: gutter('md');
        padding: 0 2rem;
        position: absolute;
        right: gutter('xxl');
        z-index: 1;
    }

    &__image {
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__content {
        background-color: color('white');
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: gutter('sm');
    }

    &__badge {
        background-color: color('secondary');
        border-radius: border-radius('round');
        bottom: 0;
        color: color('white');
        font-size: font-size('label');
        padding: gutter('xxs') gutter('xs');
        position: absolute;
        right: gutter('xxxl');
        transform: translateY(50%);
    }

    &__date {
        display: flex;
        flex: 0 0 auto;
        margin-bottom: gutter('xxs');
        order: -1;
    }

    &__title {
        flex: 1 1 auto;
    }

    &__link {
        &::after {
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }

    &__meta {
        align-items: center;
        display: flex;
        flex: 0 0 auto;
        margin-top: gutter('sm');
    }

    &__metaData {
        margin-right: gutter('sm');

        &:last-child {
            margin-right: 0;
        }
    }

    &__metaIcon {
        margin-right: gutter('xxs');
    }
}
