/**
 * Custom variables
 */

$scale: 8px;

// -------------------------------------------------------------------------- //

/**
 * Design Tokens
 */

$colors: (
    'black': #000,
    'black:hover': lighten(#000, 25%),
    'blue': #3fa9f9,
    'buyerAdviceYellow': #fcf4e3,
    'gray-light': #f3f3f3,
    'gray': #ccc,
    'gray-dark': #a5a5a5,
    'green': #006664,
    'green:hover': lighten(#006664, 10%),
    'green-dark': #024042,
    'greenPastel': #9ed7cb,
    'greenPastel-light': #ade4d9,
    'greenCustom': #32da61,
    'liebhaver': #333,
    'liebhaver-gold': #bfa266,
    'liebhaver-gold:hover': darken(#bfa266, 10%),
    'logoBlue': #006975,
    'logoRed': #bf5659,
    'orange': #ffaa74,
    'orange:hover': darken(#ffaa74, 5%),
    'paperYellow': #ffe6cb,
    'paperYellow:hover': darken(#ffe6cb, 5%),
    'paperYellow-light': #ffe6cb,
    'pink': #fdd7d1,
    'pink-dark': #f5968b,
    'primary': #024849,
    'sand': #d7c6ad,
    'sand-light': #d7c6ad,
    'secondary': #3fa9f9,
    'secondary:hover': darken(#3fa9f9, 10%),
    'yellow': #ffe6b0,
    'white': #fff,
    'white:hover': darken(#fff, 12.5%),
    'favoriteOrange': #ff931e,
);

$border-radiuses: (
    'none': 0,
    'small': 0.25rem,
    // 2px
    'normal': 0.5rem,
    // 4px
    'large': 1.25rem,
    // 10px
    'xlarge': 2.5rem,
    // 20px
    'round': 100rem,
);

$box-shadows: (
    'tiny': 0 0 50px rgba(#000, 0.1),
    'small': 0 0.5rem 3.5rem rgba(#000, 0.1),
    'small:hover': 0 1rem 3.5rem rgba(#000, 0.15),
    'normal': 0 0 6rem rgba(#000, 0.1),
    'normal:hover': 0 1.25rem 6rem rgba(#000, 0.2),
);

$border-widths: (
    'small': 1px,
    // 1px
    'base': 0.25rem,
    // 2px
);

$font-families: (
    'primary': #{'Poppins',
    sans-serif},
    'secondary': #{'Lora',
    serif},
);

$font-sizes: (
    'base': 2rem,
    // 16px
    'display': 10rem,
    // 80px
    'h1': 6rem,
    // 48px
    'h2': 4rem,
    // 32px
    'h3': 3rem,
    // 24px
    'label': 1.75rem,
    // 14px
    'lead': 2.5rem,
    // 20px
    'quote': 5rem,
    // 40px
    'small': 1.25rem,
    // 10px
);

$font-weights: (
    'regular': 400,
    'medium': 500,
    'bold': 700,
);

$gutters: (
    'xxs': 1rem,
    // 8px
    'xs': 2rem,
    // 16px
    'sm': 3rem,
    // 24px
    'md': 4rem,
    // 32px
    'lg': 5rem,
    // 40px
    'xl': 6rem,
    // 48px
    'xxl': 7rem,
    // 56px
    'xxxl': 9rem,
    // 72px
);

$letter-spacings: (
    'base': 0,
);

$line-heights: (
    'base': 1.625,
    // 26px
    'display': 1.2,
    // 96px
    'h1': 1.166666667,
    // 56px
    'h2': 1.25,
    // 40px
    'h3': 1.333333333,
    // 32px
    'label': 1.25,
    // 20px
    'lead': 1.3,
    // 26px
    'quote': 1.4,
    // 56px
    'small': 1.4,
    // 14px
);

$transition-durations: (
    'base': 0.25s,
    'slow': 0.5s,
    'fast': 0.125s,
);

$transition-timing-functions: (
    'base': ease-in-out,
);

// -------------------------------------------------------------------------- //

/**
 * Grid & Breakpoints
 */

$grid-columns: 12;

$grid-gutter: gutter(); // 32px

$grid-containers: (
    'xs': 47.75rem,
    // 382px
    'sm': 47.75rem,
    // 382px
    'md': 120rem,
    // 960px
    'lg': 172rem,
    // 1376px
    'xl': 176rem,
    // 1376px
    'xxl': 180rem,
    // 1600px
);

$breakpoints: (
    'xs': 0,
    'sm': 414px,
    'md': 768px,
    'lg': 1024px,
    'xl': 1440px,
    'xxl': 1600px,
);
