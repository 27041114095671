/**
 * Filter molecule
 */

.m-filter {
    $block: &;

    align-items: center;
    background-color: color('gray-light');
    border-radius: border-radius();
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-right: gutter('xs');
    position: relative;

    &__label {
        display: inline-flex;
        flex: 1 1 auto;
        padding: gutter('xxs');
    }

    &__close {
        display: inline-flex;
        flex: 0 0 auto;
        transition: opacity speed() easing();

        #{$block}:hover & {
            opacity: 0.5;
        }
    }
}
