/**
 * Article hero organism
 */

.o-articleHero {
    $block: &;

    display: flex;
    flex-direction: column;

    &__date {
        display: block;
        margin-bottom: gutter('xxs');
        order: -1;
    }

    &__title {
        @include lora();

        margin-bottom: gutter('sm');
    }

    &__metaData {
        &:not(:last-child) {
            margin-right: gutter('sm');
        }
    }

    &__metaIcon {
        vertical-align: bottom;
    }

    &__description {
        margin-top: gutter('sm');

        // &--small {
        //     margin-bottom: 0;
        // }

        // @include breakpoint('md') {
        //     margin-bottom: gutter('xxxl');

        //     &--small {
        //         margin-bottom: 0;
        //     }
        // }
    }

    &__figure {
        margin-inline: -#{gutter('sm')};
        margin-top: gutter();
        position: relative;

        @include breakpoint('md') {
            margin-inline: -30rem;
            margin-top: gutter('xxxl');
        }

        @include breakpoint('lg') {
            margin-inline: -43rem;
        }

        @include breakpoint('xl') {
            margin-inline: -44rem;
        }

        @include breakpoint('xxl') {
            margin-inline: -45rem;
        }
    }

    &__button {
        height: 7rem;
        position: absolute;
        right: gutter('sm');
        top: 0;
        transform: translateY(-50%);
        width: 7rem;
        z-index: 1;

        @include breakpoint('md') {
            right: 30rem;
        }

        @include breakpoint('lg') {
            right: 43rem;
        }
    }

    &__figureImageWrapper {
        margin-bottom: gutter('xxs');
        overflow: hidden;
        position: relative;

        @include breakpoint('md') {
            margin-bottom: gutter('sm');
        }

        img {
            display: block;
        }
    }

    &__figureCaption {
        margin: 0 gutter('sm');
        overflow: initial;
        text-overflow: initial;
        white-space: initial;

        @include breakpoint('md') {
            margin: 0 30rem;
        }

        @include breakpoint('lg') {
            margin: 0 43rem;
        }
    }
}
