/**
 * Hero With Splash organism
 */

.o-heroWithSplash {
    &__contentContainer {
        position: relative;
        z-index: 1;
    }

    &__content {
        padding-top: 4rem;

        @include breakpoint('md') {
            padding-top: 10rem;
        }
    }

    &__title {
        font-size: clamp(32px, 5vw, 48px);
        font-weight: font-weight('medium');
        max-width: 17ch;
    }

    &__media {
        left: -3rem;
        right: -3rem;
        width: calc(100% + 6rem);

        @include breakpoint('lg') {
            left: 0;
            right: 0;
            width: auto;
        }

        img {
            display: block;

            @include breakpoint('sm', 'down') {
                aspect-ratio: 398/187;
                object-fit: cover;
            }
        }
    }

    &__splashContainer {
        @include breakpoint('md') {
            align-items: flex-start;
            display: flex;
            justify-content: flex-start;
        }
    }

    .o-heroSplash {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow('normal');
        display: flex;
        gap: 1.5rem;
        margin-top: -4rem;
        min-height: 140px;
        padding: 2.5rem;

        @include breakpoint('md') {
            margin-top: -7rem;
            padding: gutter('sm');
        }

        @include breakpoint('lg') {
            gap: 3rem;
            padding: gutter('xs');
            padding-left: gutter('lg');
        }

        &__content {
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            gap: gutter('xxs');

            @include breakpoint('lg') {
                gap: 1.25rem;
            }
        }

        &__trumpet {
            color: color('blue');
            font-size: font-size('label');
            font-weight: font-weight('medium');
            line-height: 20px;

            @include breakpoint('lg') {
                margin-top: 10px;
            }
        }

        &__headline {
            font-size: clamp(20px, 4vw, 24px);
            line-height: line-height('h3');

            @include breakpoint('lg') {
                margin-bottom: 3px;
            }
        }

        &__illustration {
            align-self: flex-start;
            aspect-ratio: 1/1;
            background-color: color('gray-light');
            flex: none;
            overflow: hidden;
            position: relative;
            width: clamp(120px, 20vw, 136px);

            img {
                display: block;
            }
        }
    }
}
