/**
 * ABC Overview template
 */

.t-abcOverview {
    margin-top: gutter();

    @include breakpoint('sm', 'down') {
        margin-top: gutter('xs');
    }
}
