/**
 * Styleguide template
 */

.t-styleguide {
    overflow: hidden;
    padding: gutter('xs') 0;

    &__column {
        padding-bottom: gutter('xs');
        padding-top: gutter('xs');
    }
}

.a-color {
    border-radius: border-radius('round');
    display: inline-block;
    height: gutter('lg');
    margin: gutter('xxs');
    width: gutter('lg');

    &--black { background-color: color('black'); }
    &--blue { background-color: color('blue'); }
    &--buyerAdviceYellow { background-color: color('buyerAdviceYellow'); }
    &--green { background-color: color('green'); }
    &--greenDark { background-color: color('green-dark'); }
    &--greenPastel { background-color: color('greenPastel'); }
    &--greenPastelLight { background-color: color('greenPastel-light'); }
    &--logoBlue { background-color: color('logoBlue'); }
    &--logoRed { background-color: color('logoRed'); }
    &--orange { background-color: color('orange'); }
    &--paperYellow { background-color: color('paperYellow'); }
    &--paperYellowLight { background-color: color('paperYellow-light'); }
    &--pink { background-color: color('pink'); }
    &--pinkDark { background-color: color('pink-dark'); }
    &--sand { background-color: color('sand'); }
    &--sandLight { background-color: color('sand-light'); }
    &--yellow { background-color: color('yellow'); }
    &--white { background-color: color('white'); }
}

.a-box {
    background-color: color('white');
    font-size: 0;
    padding: gutter('xs');

    &--buyerAdviceYellow {
        background-color: color('buyerAdviceYellow');
        color: color('black');
    }

    &--green {
        background-color: color('green');
        color: color('white');
    }

    &--greenDark {
        background-color: color('green-dark');
        color: color('white');
    }

    &--greenPastelLight {
        background-color: color('greenPastel-light');
        color: color('black');
    }

    &--paperYellowLight {
        background-color: color('paperYellow-light');
        color: color('black');
    }

    &--pink {
        background-color: color('pink');
        color: color('black');
    }

    &--white {
        background-color: color('white');
        border-radius: border-radius();
        box-shadow: box-shadow();
    }
}
