/**
 * Estimate Broker organism
 */

.o-estimateBroker {
    $block: &;

    margin-bottom: gutter('xl');

    @include breakpoint('md') {
        margin-bottom: 8rem;
    }

    &__title {
        margin-bottom: gutter('sm');
    }

    &__button {
        margin-top: gutter('sm');
    }

    &__card {
        background-color: color('white');

        @include breakpoint('md') {
            flex: 0 0 35rem;
            margin-bottom: 0;
            margin-right: 4rem;
            transform: none;
        }

        @include breakpoint('lg') {
            margin-right: 8rem;
        }

        &--logo {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: gutter('xl');

            @include breakpoint('md') {
                padding: gutter('xxxl') gutter('xl');
            }

            .o-brokerCard__wrapper {
                background-color: color('white');
                border-radius: border-radius('round');
                justify-content: center;
                min-height: 24rem;
                min-width: 24rem;
                padding: gutter('md');
            }
        }
    }

    &__box {
        left: -#{gutter('sm')};
        padding: gutter('sm') gutter('sm') gutter('xxl');
        position: relative;
        width: calc(100% + #{gutter('sm') * 2});

        @include breakpoint('md') {
            align-items: center;
            display: flex;
            left: 0;
            padding: 4rem;
            right: 0;
            width: 100%;
        }

        @include breakpoint('lg') {
            padding: 8rem;
        }

        &--top {
            background-color: color('primary');
            color: color('white');
            display: flex;
            flex-direction: column;
            gap: gutter('xl');
            padding: gutter('xs') gutter('sm') 16rem;

            @include breakpoint('md') {
                gap: gutter('xs');
                padding: 16rem;
                text-align: center;
            }
        }

        &--bottom {
            margin-bottom: -16rem;
            transform: translateY(-16rem);
        }
    }

    &__address {
        @include breakpoint('md') {
            margin: 4rem 20rem 0;
        }
    }

    &__image {
        border-radius: border-radius('round');
        max-width: 13rem;
    }

    &__content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: gutter('sm');

        @include breakpoint('md') {
            height: 100%;
            margin-top: 0;
        }
    }
}
