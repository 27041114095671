/**
 * Neighborhood Page Contact Broker organism
 */

.o-neighborhoodPageContactBroker {
    $block: &;

    margin-bottom: gutter('xl');

    @include breakpoint('md') {
        margin-bottom: 8rem;
    }

    &__title {
        margin-bottom: gutter('sm');
    }

    &__description {
        @include lora();

        margin-bottom: gutter('sm');
    }

    &__row {
        padding: gutter('sm') 0 gutter('xxl');

        @include breakpoint('md', 'down') {
            margin: 0 -#{gutter('md')};
        }

        @include breakpoint('md') {
            align-items: center;
            padding: 4rem;
        }

        @include breakpoint('lg') {
            padding: 8rem;
        }
    }

    &__card {
        background-color: color('white');
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            flex-basis: 35rem;
            margin-bottom: 0;
            margin-right: 4rem;
            transform: none;
        }

        @include breakpoint('lg') {
            margin-right: 8rem;
        }

        &--logo {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: gutter('xl');

            @include breakpoint('md') {
                padding: gutter('xxxl') gutter('xl');
            }

            .o-brokerCard__wrapper {
                background-color: color('white');
                border-radius: border-radius('round');
                justify-content: center;
                min-height: 24rem;
                min-width: 24rem;
                padding: gutter('md');
            }
        }
    }

    &__image {
        border-radius: border-radius('round');
        max-width: 13rem;
    }

    &__content {
        margin-top: gutter('xs');

        @include breakpoint('md') {
            margin-top: 0;
        }
    }
}
