/**
 * Module Sticky Anchors organism
 */

.o-moduleStickyAnchors {
    $block: &;

    background-color: color('white');
    filter: drop-shadow(box-shadow('small'));
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.25s ease-in-out;
    z-index: -1;

    &.show {
        pointer-events: all;
        opacity: 1;
        z-index: 10;
    }

    @include breakpoint('md') {
        display: none;
    }

    &__buttons {
        align-items: center;
        flex-wrap: nowrap;
        height: 7rem;
        overflow-x: auto;
        overscroll-behavior: contain;
        padding: 0 gutter('sm');
        scroll-padding: 0 gutter('sm');
        scroll-snap-type: x mandatory;
    }

    &__button {
        background-color: transparent;
        border: none;
        box-shadow: none;
        flex: 0 0 auto;
        min-height: 0;
        scroll-snap-align: center;

        &.active {
            color: color('secondary');
        }
    }
}
