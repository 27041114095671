/**
 * Neighborhood Page Learn More organism
 */

.o-neighborhoodPageLearnMore {
    padding: gutter('xxl') 0;

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include breakpoint('md') {
            flex-wrap: nowrap;
        }
    }

    &__button {
        text-align: center;

        @include breakpoint('sm', 'down') {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: gutter('lg');
        text-align: center;
    }
}
