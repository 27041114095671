.o-neighborhoodPageLocal {
    background-color: color('pink');
    padding: gutter('xxl') gutter('lg');

    &__title {
        margin-bottom: gutter();
    }

    &__actions {
        align-items: center;
        display: flex;
        margin-top: gutter('lg');
    }

    &__button {
        &.isVoted {
            background-color: color('black');
            color: color('white');
        }
    }

    &__icon {
        background-color: color('pink-dark');
        border-radius: border-radius('round');
        color: color('white');
        height: gutter('lg');
        margin-right: gutter('xs');
        padding: gutter('xxs');
        width: gutter('lg');
    }

    &__pollTitle {
        margin-bottom: gutter('xxs');

        @include breakpoint('md') {
            margin-bottom: gutter('sm');
        }
    }

    &__pollDescription {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: gutter('sm');
        }

        @include breakpoint('lg') {
            margin-bottom: gutter('md');
        }
    }

    &__receipt {
        align-items: center;
        display: flex;
        justify-content: flex-start;
    }

    &__sourceText {
        align-items: center;
        display: flex;
        gap: gutter('xxs');
        margin-top: gutter('xxs');
    }
}
