/**
 * Text CTA organism
 */

.o-textCTA {
    $block: &;

    &__title {
        margin-bottom: gutter('sm');
    }

    &__lead {
        margin-bottom: gutter('sm');
    }

    &__buttons {
        &:not(:first-child) {
            margin-top: #{gutter('sm') - gutter('xxs')};
        }
    }
}
