/**
 * Tag molecule
 */

.m-tag {
    @extend button;

    align-items: center;
    background-color: transparent;
    border: border-width() solid color('secondary');
    border-radius: border-radius('round');
    color: color('secondary');
    display: inline-flex;
    font-family: font-family();
    font-size: font-size('label');
    font-weight: font-weight('medium');
    gap: 1rem;
    height: 3.5rem;
    justify-content: stretch;
    line-height: line-height('label');
    outline: none;
    padding: 0 gutter('xs');
    text-align: center;
    text-decoration: none;
    transition: speed() easing();
    transition-property: background-color, border-color, color;
    white-space: nowrap;

    [screen-reader] &:focus,
    [mouse] &:hover {
        background-color: color('secondary');
        color: color('white');
    }

    &.isChecked {
        border-color: color('black');
        color: color('black');

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: color('black');
            color: color('white');
        }
    }

    &__text {
        flex: 1 1 auto;
        text-align: left;
        white-space: nowrap;
    }

    &__icon {
        color: inherit;
        flex: 0 0 auto;

        &--hug-border {
            border-radius: 100%;
            height: 2.5rem;
            margin-right: calc(-2rem + #{border-width()});
            width: 2.5rem;

            &:first-of-type {
                display: block;
            }
            &:last-of-type {
                display: none;
            }
        }

        &--unset {
            height: unset;
            width: unset;
        }
    }

    &--black {
        border-color: color('black');
        color: color('black');

        [mouse] &:hover {
            background-color: color('black');
        }
    }

    &--dark.isChecked {
        border-color: color('white');
        color: color('white');

        [mouse] &:hover {
            border-color: color('white:hover');
            color: color('white:hover');
        }
    }

    &--light {
        border-color: color('white');
        color: color('white');

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: color('white');
            border-color: color('white');
            color: color('black');

            .m-tag__icon--hug-border:first-of-type {
                display: none;
            }
            .m-tag__icon--hug-border:last-of-type {
                display: block;
            }
        }
    }

    &--light.isChecked {
        background-color: color('white');
        border-color: color('white');
        color: color('black');

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: color('white');
            border-color: color('white');
            color: color('black');
        }

        .m-tag__icon--hug-border:first-of-type {
            display: none;
        }
        .m-tag__icon--hug-border:last-of-type {
            display: block;
        }
    }

    &.isDisabled {
        border-color: color('gray-dark');
        color: color('black');
        cursor: default;

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: color('white');
            color: color('black');
        }
    }
}
