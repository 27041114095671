/**
 * Project Sale Hero organism
 */

.o-projectSaleHero {
    $block: &;

    margin-bottom: gutter('xs');
    position: relative;

    @include breakpoint('md') {
        margin-bottom: gutter('xxl');
    }

    // &::before {
    //     background-color: color('primary');
    //     bottom: 10.25rem;
    //     content: '';
    //     left: 0;
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    //     z-index: -1;
    // }

    &--no-bg {
        &::before {
            display: none;
        }

        #{$block}__column {
            margin-top: 0;
        }
    }

    &__column {
        margin-top: gutter();
        position: relative;

        @include breakpoint('md') {
            margin-top: gutter('xl');
        }
    }

    &__content {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow();
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;

        @include breakpoint('md') {
            flex-direction: row;
            min-height: 65.75rem;
        }
    }

    &__info {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        justify-content: stretch;
        padding: gutter('xs');

        @include breakpoint('md') {
            flex: 0 0 calc(33.33% - #{$grid-gutter / 2} + 1px);
            padding: gutter('sm');
        }

        @include breakpoint('lg') {
            flex: 0 0 calc(25% - #{$grid-gutter / 2} + 1px);
        }
    }

    &__connectionFees {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &__labels {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: gutter('xxs');

        @include breakpoint('md') {
            margin-bottom: gutter('sm');
        }
    }

    &__label {
        align-items: center;
        display: flex;
    }

    &__labelIcon {
        background-color: color('secondary');
        border-radius: border-radius('round');
        display: flex;
        height: 1rem;
        margin-right: gutter('xxs');
        width: 1rem;
    }

    &__facts {
        border-top: border-width() solid color('black');
        flex: 1 1 auto;
        margin-top: gutter('xxs');

        @include breakpoint('md') {
            margin-top: gutter('sm');
        }

        li {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: gutter('xxs') 0 0;

            @include breakpoint('md') {
                margin: gutter('sm') 0 0;
            }
        }
    }

    &__factsPrice {
        margin-bottom: -#{gutter('xxs') / 2};
    }

    &__views {
        align-items: center;
        display: flex;
        flex: 0 0 auto;

        @include breakpoint('md') {
            align-items: center;
            display: flex;
            flex: 0 0 auto;
            margin-top: gutter('sm');
        }
    }

    &__images {
        $element: &;

        flex: 0 0 auto;
        min-height: 35rem;

        @include breakpoint('md') {
            flex: 0 0 calc(66.66% + #{$grid-gutter / 2});
            min-height: 0;
        }

        @include breakpoint('lg') {
            flex: 0 0 calc(75% + #{$grid-gutter / 2});
        }
    }

    &__imageContainer {
        position: relative;
    }

    &__imageLink {
        border-radius: 0;
        bottom: 0;
        cursor: pointer;
        display: flex;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity speed() easing();
        width: 100%;

        [screen-reader] &:focus,
        [mouse] &:hover {
            opacity: 0.9;
        }

        &.noHover {
            cursor: default;
            opacity: 1 !important;
        }
    }

    &__imageLinkOverlay {
        background: rgba(0, 0, 0, 0.5);
        bottom: 0;
        color: color('white');
        display: grid;
        left: 0;
        place-content: center;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__image {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    &__highlight {
        background-color: rgba(color('white'), 0.7);
        border-radius: border-radius();
        color: color('black');
        font-size: font-size('small');
        left: 2rem;
        max-width: 35ch;
        overflow: hidden;
        padding: gutter('xxs');
        pointer-events: none;
        position: absolute;
        text-overflow: ellipsis;
        top: 2rem;
        white-space: nowrap;
    }

    &__openHouse {
        align-items: center;
        background-color: color('paperYellow');
        bottom: 0;
        color: color('black');
        display: flex;
        gap: 2rem;
        height: gutter('xxl');
        justify-content: space-between;
        left: 0;
        padding: gutter('xs');
        position: absolute;
        right: 0;
        text-align: left;

        @include breakpoint('md') {
            height: 10rem;
            left: gutter('lg');
            max-width: 440px;
            right: auto;
        }

        [screen-reader] &:focus,
        [mouse] &:hover {
            #{$block}__openHouseButton {
                background-color: color('secondary:hover');
                border-color: color('secondary:hover');
                color: color('white');
            }
        }
    }

    &__openHouseTextIntro {
        font-size: font-size('small');
    }

    &__openHouseTextDate {
        margin-top: gutter('xxs');

        @include breakpoint('md') {
            font-size: font-size('h3');
        }
    }

    &__imagesButton {
        background-color: color('secondary');
        border-radius: border-radius('round');
        bottom: 0;
        color: color('white');
        height: 7rem;
        position: absolute;
        right: 7rem;
        transform: translateY(50%);
        transition: background-color speed() easing();
        width: 7rem;

        @include breakpoint('md') {
            height: 9rem;
            right: 9rem;
            width: 9rem;
        }

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: color('secondary:hover');
        }
    }
}

.o-imageGrid {
    $block: &;

    display: grid;
    gap: border-width();
    grid-auto-flow: column;
    grid-gap: border-width();
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    &.hasTwo {
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr;

        @include breakpoint('lg') {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }

    &.hasThree {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 1fr;

        @include breakpoint('lg') {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }

    &__image {
        grid-column: 1;
        grid-row: 1;
        position: relative;

        #{$block}.hasTwo & {
            @include breakpoint('lg') {
                grid-column: 1;
                grid-row: 1 / 3;
            }

            &:nth-of-type(2) {
                grid-row: 2;

                @include breakpoint('lg') {
                    grid-column: 2;
                    grid-row: 1 / 3;
                }
            }
        }

        #{$block}.hasThree & {
            &:nth-of-type(1) {
                grid-column: 1 / span 2;

                @include breakpoint('lg') {
                    grid-column: 1;
                    grid-row: 1 / span 2;
                }
            }

            &:nth-of-type(2) {
                grid-row: 2;

                @include breakpoint('lg') {
                    grid-column: 2;
                    grid-row: 1;
                }
            }

            &:nth-of-type(3) {
                grid-column: 2;
                grid-row: 2;

                @include breakpoint('lg') {
                    grid-column: 2;
                    grid-row: 2;
                }
            }
        }
    }
}
