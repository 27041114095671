/**
 * Accordion organism
 */

.o-accordion {
    $block: &;

    position: relative;

    &__item {
        margin: gutter('xs') 0;
    }

    &__itemHeader {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin: gutter('xxs') 0;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: color speed() easing();
        transition-property: color, text-decoration-color;

        [screen-reader] &:focus,
        [mouse] &:hover {
            text-decoration-color: color('black');
        }
    }

    &__itemIcon {
        pointer-events: none;
        transition-property: fill, transform;

        .isOpen & {
            transform: rotate(180deg);
        }
    }

    &__itemTitle {
        margin: 0;
    }

    &__itemContent {
        display: none;
        padding: gutter('xxs') 0 gutter('sm');

        .isOpen & {
            display: block;
        }
    }
}
