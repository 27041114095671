.o-neighborhoodPageStats {
    $block: &;

    &__row {
        @include breakpoint('md') {
            margin-bottom: gutter('md');
        }
    }

    &__column {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: 0;
        }
    }

    &__wrapper {
        background-color: color('primary');
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: gutter('lg') gutter();

        &--find {
            justify-content: unset;
        }

        @include breakpoint('lg') {
            padding: gutter('lg') gutter('xxxl');
        }
    }

    &__find {
        background-color: color('primary');
        height: 100%;
    }

    &__findRow {
        border-bottom: border-width('small') solid color('green');
    }

    &__findTable {
        border: none;
        margin: 0;
    }

    &__title {
        color: color('white');
        margin-bottom: gutter('sm');
        text-align: center;
    }

    &__findBody {
        border-top: border-width('small') solid color('green');
    }

    &__source {
        margin-top: gutter('sm');
        text-align: end;
    }
}
