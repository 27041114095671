/**
 * Column utility
 */

@for $i from 1 through $grid-columns {
    .u-col-#{$i} {
        flex: 0 0 #{100% / $grid-columns * $i};
        max-width: #{100% / $grid-columns * $i};
        padding: 0 #{$grid-gutter / 2};
        position: relative;
        width: 100%;
    }
}

@each $key, $value in $grid-containers {
    @for $i from 1 through $grid-columns {
        @include breakpoint($key) {
            .#{$key}\:u-col-#{$i} {
                flex: 0 0 #{100% / $grid-columns * $i};
                max-width: #{100% / $grid-columns * $i};
            }
        }
    }
}
