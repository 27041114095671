/**
 * Property Gallery Interactive organism
 */

#krpanoSWFObject {
    z-index: 9999;
}

.o-propertyGalleryInteractive {
    --w: 300px;
    $block: &;

    background-color: color('white');
    bottom: 0;
    color: color('black');
    display: grid;
    gap: 1rem;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    left: 0;
    overflow: hidden;
    padding: gutter() gutter('xs');
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 900;

    @media (orientation: landscape) {
        padding: 0 gutter('sm');
    }

    @include breakpoint('md', 'down') {
        @media (orientation: landscape) {
            gap: 0;
            grid-template-rows: auto;
        }
    }

    @include breakpoint('md') {
        @media (orientation: landscape) {
            padding: 0 8rem; // gutter('sm');
        }
    }

    @include breakpoint('lg') {
        padding: gutter('xxl') 11rem;
        padding: 6rem 11rem 7rem 11rem;
    }

    &__centered {
        align-items: center;
        display: flex;
    }

    &__buttons {
        align-items: center;
        display: grid;
        gap: 2rem;
        grid-auto-flow: column;
        position: absolute;
        right: gutter('sm');
        top: gutter('sm');
        transition: top speed() easing();
        z-index: 10;

        @include breakpoint('sm', 'down') {
            @media (orientation: landscape) {
                right: gutter('xxs');
                top: gutter('xxs');
            }
        }

        @include breakpoint('md') {
            grid-auto-flow: row;

            > button:nth-of-type(1) {
                grid-row: 2;
            }

            > button:nth-of-type(2) {
                grid-row: 1;
            }
        }
    }

    &__close {
        height: gutter('lg');
        justify-content: center;
        padding: 0;
        width: gutter('lg');

        @include breakpoint('sm', 'down') {
            @media (orientation: landscape) {
                height: gutter('sm');
                min-height: gutter('sm');
                width: gutter('sm');
            }
        }
    }

    &__closeIcon {
        @include breakpoint('sm', 'down') {
            @media (orientation: landscape) {
                height: gutter('xs');
                width: gutter('xs');
            }
        }
    }

    &__tagWrapper {
        display: flex;
        justify-content: center;
        position: absolute;
        top: gutter('xs');
        width: 100%;
        z-index: 1;

        @include breakpoint('sm', 'down') {
            @media (orientation: landscape) {
                top: gutter('xxs');
            }
        }

        @include breakpoint('lg') {
            width: calc(100% - #{gutter()});
        }
    }

    &__tag {
        background-color: rgba(color('black'), 0.5);
        border-radius: border-radius();
        color: rgba(color('white'), 0.5);
        padding: #{gutter('xxs') / 2} gutter('xxs');

        @include breakpoint('sm', 'down') {
            @media (orientation: landscape) {
                font-size: #{font-size('base') / 2};
            }
        }
    }

    &__navigation {
        align-items: center;
        bottom: 3rem;
        display: none;
        justify-content: space-between;
        left: 3rem;
        pointer-events: none;
        position: absolute;
        right: 3rem;
        top: 3rem;
        z-index: 1;

        @media (orientation: landscape) {
            @include breakpoint('lg', 'down') {
                display: flex;
            }
        }
    }

    &__navigationButton {
        cursor: pointer;
        margin-right: gutter('sm');
        pointer-events: all;
        transition: color speed() easing();

        svg {
            display: block;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            color: color('secondary');
        }
    }

    &__navigationButtonLandscape {
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        padding: 1rem;
        pointer-events: all;
        transition: color speed() easing();

        &:hover {
            color: color('secondary');
        }
    }

    &__topNavigation {
        display: flex;
        flex-flow: row nowrap;
        margin-left: auto;
        margin-right: gutter('xxxl');
        padding: 0;

        @include breakpoint('lg') {
            margin-right: 0;
        }

        @media (orientation: landscape) {
            display: none;

            @include breakpoint('lg') {
                display: flex;
            }
        }
    }

    &__navigationIcon {
        @include breakpoint('sm', 'down') {
            @media (orientation: landscape) {
                height: gutter('xs');
                width: gutter('xs');
            }
        }
    }

    &__topRow {
        @include breakpoint('lg') {
            margin: 0;
            width: 100%;
        }

        @include breakpoint('md', 'down') {
            @media (orientation: landscape) {
                display: none;
            }
        }
    }

    &__top {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        position: relative;
        //transform: translateY(3rem);

        @media (min-width: 500px) {
            //transform: translateY(2rem);
        }

        @include breakpoint('lg') {
            margin-right: 29rem;
            //transform: translateY(3rem);
        }

        @media (min-width: 768px) and (min-height: 1024px) and (orientation: portrait) {
            //transform: translateY(4rem);
        }
    }

    &__progress {
        margin-right: gutter('xs');
        white-space: nowrap;
    }

    &__danboligLogo {
        bottom: 100%;
        display: block;
        height: auto;
        max-width: 100px;
        width: 20rem;
        z-index: 7;

        @include breakpoint('md', 'down') {
            @media (orientation: landscape) {
                display: none;
            }
        }
    }

    &__galleryRow {
        display: grid;
        gap: gutter();
        grid-template-rows: auto 18rem;

        @include breakpoint('lg') {
            grid-template-columns: auto 25rem;
            grid-template-rows: auto;
        }

        @include breakpoint('md', 'down') {
            @media (orientation: landscape) {
                grid-template-rows: auto;
                height: 100%;
            }
        }
    }

    &__galleryImageWrapper {
        height: 100%;
        position: relative;
        user-select: none;

        &--interactive {
            @include breakpoint('lg') {
                max-height: calc(100vh - 22rem);
            }
        }
    }

    &__galleryImage {
        bottom: 0;
        display: block;
        height: 100vh;
        left: 0;
        margin: auto;
        max-height: 100%;
        object-fit: contain;
        object-position: center center;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity speed() easing();
        user-select: none;
        width: 100%;
        z-index: 6;

        &.transparent {
            opacity: 0;
        }

        &.opaque {
            opacity: 1;
        }
    }

    &__galleryImageListColumn {
        position: relative;

        @include breakpoint('lg') {
            height: auto;
        }

        @include breakpoint('md', 'down') {
            @media (orientation: landscape) {
                display: none;
            }
        }
    }

    &__galleryImageListWrapper {
        bottom: 0;
        left: -2rem;
        position: absolute;
        right: -2rem;
        top: 0;

        @include breakpoint('lg') {
            bottom: -#{gutter('xxl')};
            left: 0;
            right: 0;
            top: -#{gutter('xxl') * 2};
        }
    }

    &__galleryImageList {
        &::-webkit-scrollbar {
            display: none;
        }

        display: grid;
        gap: gutter('xxs');
        grid-auto-flow: column;
        height: 100%;
        -ms-overflow-style: none;
        overflow-x: scroll;
        overflow-y: hidden;
        overscroll-behavior: contain;
        padding: 0 2rem;
        scroll-padding: 0 2rem;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;

        @include breakpoint('lg') {
            display: flex;
            flex-direction: column;
            gap: 0;
            overflow-x: hidden;
            overflow-y: scroll;
            padding: 0;
            scroll-padding: 0;
            scroll-snap-type: y mandatory;
        }
    }

    &__galleryImageListItem {
        cursor: pointer;
        position: relative;
        scroll-snap-align: center;
        transition: opacity speed() easing();

        [screen-reader] &:not(.isActive):focus,
        [mouse] &:not(.isActive):hover {
            opacity: 0.9;
        }

        @include breakpoint('lg') {
            margin-top: 1rem;
        }

        &::before {
            border: border-width() solid transparent;
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: border-color speed() easing();
            z-index: 7;
        }

        &.isActive {
            cursor: default;

            &::before {
                border-color: color('black');
            }
        }

        &:first-of-type {
            //margin-left: gutter('xs');

            @media (orientation: landscape) {
                //margin-left: 0;
                margin-top: gutter('sm');
            }

            @include breakpoint('lg') {
                //margin-left: 0;
                margin-top: #{gutter('xxl') * 2};
            }
        }

        &:last-of-type {
            &::after {
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                right: -#{gutter('xs')};
                top: 0;
                width: gutter('xs');
            }

            // @include breakpoint('lg') {
            //     margin-bottom: gutter('xxl');
            // }
        }

        &.interactive {
            &::before {
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0.295) 3%,
                    rgba(0, 0, 0, 0) 379%
                );
            }
        }
    }

    &__galleryImageListImage {
        display: block;
        height: 18rem;
        position: relative;
        width: auto;
        z-index: 6;

        @include breakpoint('lg') {
            height: auto;
            width: 100%;
        }
    }

    &__galleryImageListIconWrapper {
        align-items: center;
        color: color('white');
        display: flex;
        font-size: font-size('label');
        left: 0.75rem;
        position: absolute;
        top: 0.75rem;
        z-index: 8;
    }

    &__galleryImageListIcon {
        margin-right: 1rem;
    }

    &__fullscreenWrapper {
        left: 3rem;
        position: absolute;
        top: 3rem;
        z-index: 10;
    }

    &__danboligLogoFullscreen {
        display: block;
        height: auto;
        margin-bottom: 1.5rem;
        max-width: 100px;
        width: 20rem;
    }

    &__fullscreenLabel {
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 5px;
        color: color('black');
        font-size: 12px;
        padding: 0.25rem 1.5rem;
    }

    .inheritHeight {
        height: inherit;
        width: 100%;

        video {
            height: 100%;
            object-fit: cover;
            width: 100%;

            @include breakpoint('md', 'down') {
                @media (orientation: landscape) {
                    max-height: none;
                }
            }

            &.contain {
                object-fit: contain;
            }
        }
    }

    &.fullscreen {
        gap: 0;
        grid-template-columns: auto;
        grid-template-rows: auto;
        padding: 0;

        .o-propertyGallery__danboligLogo {
            display: none;
            position: absolute;
        }

        .o-propertyGallery__topRow,
        .o-propertyGallery__galleryImageListColumn {
            display: none;
        }

        .o-propertyGallery__galleryRow {
            gap: 0;
            grid-template-columns: auto;
            grid-template-rows: auto;
            height: 100%;
        }

        .o-propertyGallery__galleryImage {
            object-fit: cover;
        }
    }
}

.o-propertyGalleryInteractive.fullscreen:not(
        .o-propertyGalleryInteractive--interactive
    )
    .o-propertyGalleryInteractive__fullscreenButtons {
    align-items: center;
    bottom: 10rem;
    display: none;
    gap: 2rem;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    z-index: 10;

    button {
        align-items: center;
        border: solid 2px;
        border-radius: 100%;
        color: color('white');
        display: flex;
        height: 5rem;
        justify-content: center;
        width: 5rem;
    }
}

@media (pointer: fine) {
    .o-propertyGalleryInteractive.fullscreen:not(
            .o-propertyGalleryInteractive--interactive
        )
        .o-propertyGalleryInteractive__fullscreenButtons {
        display: flex;
    }
}
