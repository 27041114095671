/**
 * Map Interface organism
 */

.o-mapInterface {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;

    &__bottom {
        align-items: center;
        bottom: gutter('xs');
        display: flex;
        justify-content: center;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        z-index: 2;

        @include breakpoint('md') {
            bottom: gutter('sm');
        }
    }

    &__bottomLeft {
        bottom: gutter('xs');
        left: gutter('xs');
        position: absolute;
        z-index: 2;

        @include breakpoint('md') {
            bottom: gutter('sm');
            left: gutter('sm');
        }
    }

    &__bottomRight {
        bottom: gutter('xs');
        position: absolute;
        right: gutter('xs');
        z-index: 2;

        @include breakpoint('md') {
            bottom: gutter('sm');
            right: gutter('sm');
        }
    }

    &__drawConfirm {
        background-color: color('white');
        border-radius: border-radius('round');
        box-shadow: 0 0 0 2rem rgba(color('secondary'), 0.3);
        color: color('black');
        height: 10rem;
        margin: gutter('xs') gutter('xs');
        pointer-events: all;
        transition: speed() easing();
        transition-property: background-color, box-shadow, color;
        width: 10rem;

        [screen-reader] &:focus,
        [mouse] &:hover {
            box-shadow: 0 0 0 1.5rem rgba(color('secondary'), 0.4);
            color: color('secondary');
        }
    }

    &__drawCancel {
        background-color: color('white');
        border-radius: border-radius('round');
        box-shadow: box-shadow('small');
        height: 6.25rem;
        margin: 0 gutter('xs');
        pointer-events: all;
        transition: speed() easing();
        transition-property: background-color, box-shadow, color;
        width: 6.25rem;

        [screen-reader] &:focus,
        [mouse] &:hover {
            box-shadow: box-shadow('small:hover');
            color: color('secondary');
        }
    }

    &__message {
        align-items: center;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        border-radius: border-radius('large');
        box-shadow: box-shadow('small');
        display: inline-flex;
        font-size: font-size('label');
        max-width: 30rem;
        padding: gutter('xs');
        pointer-events: none;
        transition: opacity speed() easing();
        z-index: 2;
    }

    &__topRight {
        position: absolute;
        right: gutter('xs');
        top: gutter('xs');
        z-index: 2;

        @include breakpoint('md') {
            right: gutter('sm');
            top: gutter('sm');
        }
    }

    &__button {
        pointer-events: all;
        transition: speed() easing();
        transition-property: background-color, box-shadow, color, opacity;
    }

    &__terrainButton {
        border: solid 2px #3fa9f5;
        display: block;
        filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.2));
        pointer-events: all;
        position: relative;
        transition: 0.25s ease-in-out;
        transition-property: background-color, box-shadow, color, opacity;

        img {
            display: block;
            height: 7rem;
            width: 7rem;
        }

        span {
            bottom: 0.5rem;
            color: #000;
            display: block;
            font-size: 1.5rem;
            left: 0;
            position: absolute;
            right: 0;
            text-align: center;
            text-shadow: 1px 1px 0 white, -1px -1px 0 white, -1px 1px 0 white, 1px -1px 0 white;
        }
    }

    &__zoom {
        background-color: color('white');
        border-radius: border-radius('round');
        box-shadow: box-shadow('small');
        cursor: pointer;
        pointer-events: all;
        position: relative;

        @include breakpoint('sm', 'down') {
            display: none;
        }
    }

    &__zoomIn,
    &__zoomOut {
        align-items: center;
        display: flex;
        height: gutter('lg');
        justify-content: center;
        transition: color speed() easing();
        width: gutter('lg');

        [screen-reader] &:focus,
        [mouse] &:hover {
            color: color('secondary');
        }
    }

    &__zoomIn {
        border-bottom: border-width('small') solid color('gray-light');
    }
}
