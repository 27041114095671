/**
 * Simple card atom
 */

.a-card {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    //  TODO: Edit box-shadow
    box-shadow:
        0 10px 80px rgba(0, 0, 0, 0.06),
        0 22.3363px 17.869px rgba(0, 0, 0, 0.0317),
        0 12.5216px 10.0172px rgba(0, 0, 0, 0.015),
        0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    padding: gutter('sm') 2.25rem;
    position: relative;
}
