/**
 * Cta Widget
 */

.m-ctaWidget {
    $block: &;

    background: color('green-dark');
    color: color('white');
    display: flex;
    font-size: font-size('label');
    width: 100%;

    @include breakpoint('md') {
        max-width: 414px;
        position: fixed;
        top: 14.75rem;
        transform: translateX(-100%) translateX(-8rem);
        transition: all 0.25s ease-in-out;
        z-index: 9;

        .isScrolled & {
            top: 10rem;
        }
    }

    @include breakpoint('md') {
        left: calc((100% - #{map-get($grid-containers, 'md')}) / 2);
        margin-left: #{map-get($grid-containers, 'md')};
    }

    @include breakpoint('lg') {
        left: calc((100% - #{map-get($grid-containers, 'lg')}) / 2);
        margin-left: #{map-get($grid-containers, 'lg')};
    }

    &__dismiss {
        align-items: center;
        color: inherit;
        display: flex;
        flex: none;
        padding: gutter('xxs');
    }

    &__illustration {
        // border-radius: border-radius('round');
        display: block;
        flex: none;
        height: gutter('xxl');
        width: gutter('xxl');
    }

    &__content {
        flex-grow: 1;
    }

    &__contentHeadline {
        font-size: font-size('label');
        line-height: 1.42;
    }

    &__contentDescription {
        font-size: font-size('small');
        line-height: 1.2;
    }

    &__link {
        align-items: center;
        display: flex;
        flex: 1 1 0%;
        gap: gutter('sm') / 2;
        justify-content: center;
        overflow: hidden;
        padding: gutter('xxs') gutter('xxs') gutter('xxs') 0;
        width: 100%;

        &__illustration {
            border-radius: 100%;
            flex: none;
            height: calc(#{gutter('lg')} - 0.75rem);
            margin-left: -0.5rem;
            width: calc(#{gutter('lg')} - 0.75rem);
        }
    }

    &__iconWrapper {
        align-items: center;
        background: color('secondary');
        border-radius: border-radius('round');
        color: color('white');
        display: flex;
        flex: none;
        justify-content: center;
        padding: gutter('xxs') / 2;

        &__text {
            padding: 0 gutter('xs');
        }

        &--static {
            height: gutter('lg');
            padding: 0;
            width: gutter('lg');
        }
    }

    &--green-dark {
        background: color('green-dark');
        color: color('white');
    }

    &--green {
        background: color('green');
        color: color('white');
    }

    &--greenPastel-light {
        background: color('greenPastel-light');
        color: color('black');
    }

    &--paperYellow-light {
        background: color('paperYellow-light');
        color: color('black');
    }

    &--sand-light {
        background: color('sand-light');
        color: color('black');
    }

    &--yellow {
        background: color('yellow');
        color: color('black');
    }

    &--orange {
        background: color('orange');
        color: color('black');
    }

    &--pink {
        background: color('pink');
        color: color('black');
    }

    .isSearchPage & {
        position: fixed;
        top: 10rem;
        z-index: 1;
    }
}
