/**
 * Table molecule
 */

.m-table {
    $block: &;

    border-radius: border-radius('large');
    box-shadow: box-shadow();
    margin-bottom: gutter('sm');
    padding: gutter('xs');
    word-break: break-word;

    &__title {
        font-size: font-size('h3');
        margin-bottom: gutter('sm');
        margin-top: gutter('xs');

        @include breakpoint('md') {
            margin-left: gutter('sm');
        }
    }

    &__tableText {
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
    }

    &__tableContent {
        display: inline-block;
        min-width: 100%;

        &--simple {
            white-space: nowrap;
        }
    }

    &__tableShadow {
        position: relative;

        &::before,
        &::after {
            bottom: 0;
            content: '';
            display: block;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: opacity speed() easing();
            width: gutter();
            z-index: 1;
        }

        &::before {
            background:
                linear-gradient(
                    to right,
                    rgba(color('white'), 1),
                    rgba(color('white'), 0)
                );
            left: 0;
        }

        &::after {
            background:
                linear-gradient(
                    to left,
                    rgba(color('white'), 1),
                    rgba(color('white'), 0)
                );
            right: 0;
        }

        &.isOverflownLeft {
            &::before {
                opacity: 1;
            }
        }

        &.isOverflownRight {
            &::after {
                opacity: 1;
            }
        }
    }

    &__tableWrapper {
        display: block;
        overflow-x: auto;
    }

    //Advanced
    &__advancedTable {
        &::before,
        &::after {
            top: 0;
        }

        th {
            min-width: 280px;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        tr {
            .a-tableRow {
                &:nth-child(odd) {
                    background-color: color('gray-light');
                }
            }
        }
    }

    table {
        background-color: color('white');
        border: none;
    }

    td {
        @extend .a-tableCell;

        vertical-align: top;
    }

    th {
        @extend .a-tableCell;

        font-weight: font-weight('medium');
    }

    thead {
        @extend .a-tableHead;
    }

    tr {
        @extend .a-tableRow;
    }
}
