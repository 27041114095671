/**
 * Property - Properties for sale Organism
 */

.o-propertyPropertiesForSale {
    border: solid 2px color('black');
    display: flex;
    flex-direction: column;
    gap: gutter('md');
    padding: 8rem;
    text-align: center;

    &__buttons {
        justify-content: center;
    }
}
