/**
 * Info button atom
 */

.a-infoButton {
    color: color('secondary');
    cursor: pointer;
    display: inline-flex;
    transition:
        opacity speed() easing(),
        color speed() easing();

    &:hover {
        opacity: 0.75;
    }

    &:focus-visible {
        color: color('black');
    }

    svg {
        display: block;
    }
}
