/**
 * Search Filters organism
 */

.o-searchAgent {
    $block: &;

    --distance: #{gutter('xs')};
    position: fixed;
    bottom: var(--distance);
    left: var(--distance);
    z-index: 2;

    @include breakpoint('md') {
        --distance: #{gutter('sm')};
    }

    &__tooltip {
        -webkit-backdrop-filter: blur(13px);
        backdrop-filter: blur(13px);
        background-color: rgba(255, 255, 255, 0.65);
        border-radius: border-radius('large');
        bottom: 100%;
        box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.2);
        display: none;
        gap: gutter('xs');
        left: 0;
        margin-bottom: gutter('xs');
        width: 30rem;
        padding: gutter('md') gutter('sm') gutter('sm') gutter('sm');
        position: absolute;

        &.open {
            display: grid;
        }
    }

    &__tooltipText {
        display: grid;
        gap: gutter('xs');
    }

    &__button {
        &.invalid {
            background-color: #f3f3f3;
            box-shadow: 0px 8px 16px 0px #0000001f;
            color: #494949;
            &:hover ~ #{$block}__tooltip {
                @media (hover: hover) {
                    display: grid;
                }
            }
            &:focus-visible ~ #{$block}__tooltip {
                display: grid;
            }
        }
    }

    &__closeButton {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

body:has(.o-mapInterface__message) .o-searchAgent {
    bottom: 16rem;
}
