/**
 * Offset utility
 */

@for $i from 0 through $grid-columns {
    .u-offset-#{$i} {
        margin-left: #{100% / $grid-columns * $i};
    }
}

@each $key, $value in $grid-containers {
    @for $i from 0 through $grid-columns {
        @include breakpoint($key) {
            .#{$key}\:u-offset-#{$i} {
                margin-left: #{100% / $grid-columns * $i};
            }
        }
    }
}
