/**
 * HTML atom
 */

html {
    box-sizing: border-box;
    font-size: get-vw($scale);
    height: 100%;
    margin: 0;
    -ms-overflow-style: scrollbar;
    scroll-behavior: smooth;
    scroll-padding-top: 4rem;

    @media (prefers-reduced-motion) {
        scroll-behavior: auto;
    }

    @include breakpoint('sm') {
        font-size: get-vw($scale, 'sm');
    }

    @include breakpoint('md') {
        font-size: get-vw($scale, 'lg');
        scroll-padding-top: 14.75rem;
    }

    @include breakpoint('lg') {
        font-size: get-vw($scale, 'xl');
    }

    @include breakpoint('xl') {
        font-size: $scale;
    }

    // @include breakpoint('xxl') {
    //     font-size: get-vw($scale, 'xxl');
    // }
}
