/**
 * SpeechBubble organism
 */

.o-speechBubble {
    background-color: color("white");
    border: 2px solid color("black");
    padding: 2rem;
    position: relative;

    &__content {
        position: relative;
        z-index: 3;
    }

    &__backgroundEnforce {
        background-color: color('white');
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }

    &::after {
        background-color: color('white');
        border-bottom: 2px solid color('black');
        border-left: 2px solid color('black');
        bottom: -8px;
        content: " ";
        height: 20px;
        left: 15%;
        position: absolute;
        transform: rotate(-45deg) skew(6deg, 6deg);
        width: 20px;
    }
}
