/**
 * Input molecule
 */

.m-input {
    $block: &;

    display: flex;
    flex-direction: column;

    &:not(.o-searchHeader__input) {
        position: relative;
    }

    &.isDisabled {
        cursor: default;
        pointer-events: none;
    }

    &__wrapper {
        position: relative;
    }

    &__label {
        color: color('secondary');
        font-size: font-size('small');
        left: gutter('xs');
        pointer-events: none;
        position: absolute;
        top: gutter('xxs');
        transform: translateY(0);
        transition: speed() easing();
        transition-property: color, font-size, top, transform;

        #{$block}.isEmpty & {
            color: color('black');
            font-size: font-size('label');
            top: 50%;
            transform: translateY(-50%);
        }

        #{$block}.isFocused & {
            color: color('secondary');
            font-size: font-size('small');
            top: gutter('xxs');
            transform: translateY(0);
        }

        #{$block}.hasError & {
            color: color('logoRed');
            font-size: font-size('small');
            top: gutter('xxs');
            transform: translateY(0);
        }

        #{$block}.isDisabled & {
            color: rgba(color('black'), 0.2);
        }
    }

    &__error {
        #{$block}--dark & {
            color: color('white');
        }
    }

    &__field {
        #{$block}--dark & {
            background-color: color('white');
            border-color: color('secondary');

            &:-webkit-autofill {
                box-shadow: inset 0 0 0 gutter() color('white');
            }
        }

        #{$block}.search & {
            border-bottom: unset;
            border-bottom-left-radius: border-radius('round');
            border-top-left-radius: border-radius('round');
            height: gutter('lg');
        }

        #{$block}.searchSimple & {
            border-bottom: unset;
            border-radius: border-radius('round');
            height: gutter('lg');
        }

        #{$block}.hasLabel & {
            padding: gutter('xs') gutter('xs') gutter('xxs');
            padding-bottom: 0.2rem;
        }

        #{$block}.isFocused & {
            border-color: color('secondary');
        }

        #{$block}.hasError & {
            border-color: color('logoRed');
        }

        #{$block}.hasIcon & {
            padding-right: 7rem;
        }

        #{$block}.isDisabled & {
            border-color: rgba(color('black'), 0.2);
        }
    }

    &__searchButton {
        align-items: center;
        -webkit-appearance: none;
        appearance: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        display: flex;
        justify-content: center;
    }

    &__button {
        background-color: color('gray-light');
        bottom: 2px;
        cursor: pointer;
        padding: 0 gutter('xs');
        position: absolute;
        right: 0;
        top: 0;
        transition: all speed() easing();

        #{$block}--dark & {
            background-color: color('white');
        }

        #{$block}.searchSimple & {
            background-color: var(--button-bg-clr, var(--secondary));
            border-bottom-right-radius: border-radius('round');
            border-top-right-radius: border-radius('round');
            bottom: 0;
            color: var(--button-txt-clr, var(--white));

            [screen-reader] &:focus,
            [mouse] &:hover,
            &:active {
                background-color: var(--white);
                border: border-width() solid
                    var(--button-bg-clr, var(--secondary));
                color: var(--button-txt-clr, var(--secondary));
            }
        }

        #{$block}.search & {
            background-color: color('secondary');
            border-radius: unset;
            bottom: 0;
            color: color('white');
            justify-content: center;
            padding: 0 gutter('xxs');
            position: absolute;
            right: 0;
            top: 0;
            width: 5rem;
            z-index: 1;

            @include breakpoint('sm', 'down') {
                .m-input__buttonText {
                    display: none;
                }
            }

            @include breakpoint('md') {
                display: flex;
                flex-flow: row nowrap;
                gap: 1rem;
                width: 20rem;
            }
        }

        [screen-reader] &:focus,
        [mouse] &:hover {
            color: color('secondary');
        }

        #{$block}.isDisabled & {
            color: rgba(color('black'), 0.2);
        }
    }
}
