/**
 * Search Selections organism
 */

.o-searchSelections {
    align-items: flex-start;
    border-bottom: 1px solid color('gray');
    display: flex;
    justify-content: stretch;
    margin: 0 0 gutter();
    padding-bottom: gutter();
    position: relative;

    &__tags {
        display: inline-flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        margin: 0;

        @include breakpoint('sm', 'down') {
            display: none;
        }
    }

    &__resetButton {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        height: 3.5rem;
        margin: 0.25rem 0;
    }
}
