/**
 * Figure atom
 */

figure {
    margin: 0;
}

.a-figure {
    @extend figure;
}
