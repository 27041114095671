/**
 * Main atom
 */

main {
    flex: 1 0 auto;
    padding-top: 10rem;

    @include breakpoint('md') {
        padding-top: 14.75rem;
    }
}
