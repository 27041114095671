/**
 * Wildcard atom
 */

.a-editorContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ul,
    ol {
        all: revert;
        margin: 0;
        padding-left: 2rem;
    }
}
