/**
 * Property Presentation Map organism
 */

.o-propertyPresentationMap {
    $block: &;

    background-color: color('green');
    margin: 0 -#{gutter()};

    .luxurious & {
        background-color: color('liebhaver');
    }

    @include breakpoint('md') {
        margin: 0;
        padding: gutter();
    }

    &__content {
        margin-bottom: gutter();
        max-width: 86rem;
        padding: gutter() gutter() 0;

        & + & {
            padding-top: 0;
        }

        @include breakpoint('md') {
            margin-bottom: gutter('xl');
            padding: #{gutter('xxl') - gutter()} #{gutter('xxxl') - gutter()} 0;
            & + & {
                margin-top: -#{gutter('xxs')};
            }
        }

        &--fullWidth {
            max-width: none;
            @include breakpoint('md') {
                padding-block: 0;
            }
        }
    }

    &__title {
        color: color('white');
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: gutter('xs');
        }
    }

    &__description {
        color: color('white');
    }

    &__map {
        height: 60.875rem;

        @include breakpoint('md') {
            height: 69.75rem;
        }
    }
}
