.o-property-presentation-nordea {
    flex-direction: column;

    @include breakpoint('md') {
        flex-direction: row;
    }

    .o-propertyNordeaCampaign {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: gutter('xs');
        height: 100%;
        justify-content: center;
        padding: 10rem 4rem;
        text-align: center;

        @include breakpoint('sm', 'down') {
            flex-direction: row-reverse;
            gap: gutter('xs');
            margin-bottom: 3rem;
            padding: 4rem 3rem;
            text-align: start;
        }

        &__image {
            object-fit: cover;
            object-position: center 0%;
            width: 50%;

            @include breakpoint('sm', 'down') {
                width: 26%;
            }
        }

        &__contentWrapper {
            display: flex;
            flex-direction: column;
            gap: gutter('md');

            @include breakpoint('md') {
                align-items: center;
            }
        }

        .a-heading2 {
            &__heading {
                font-size: font-size('h2');
                font-weight: font-weight('medium');
                // margin-bottom: gutter('md');
                line-height: line-height('h2');

                @include breakpoint('sm', 'down') {
                    font-size: font-size('lead');
                    line-height: line-height('lead');
                }
            }
        }
    }

    .o-propertyNordeaLinksWrapper {
        display: flex;
    }

    .o-propertyNordeaLinks {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        padding: 10rem;
        text-align: start;

        @include breakpoint('sm', 'down') {
            align-items: flex-start;
            padding: 6rem 3rem;
            text-align: start;
        }

        .a-heading1 {
            margin-bottom: gutter('md');
        }

        .a-paragraph {
            margin-bottom: gutter('md');
            max-width: 600px;
        }

        .o-buttons {
            &__button {
                width: 100%;
            }
        }
    }
}
