/**
 * Range Slider molecule
 */

$bgColor: color('black');
$disabledColor: rgba(color('black'), 0.2);
$dotBorderRadius: border-radius('round');
$dotShadowColor: transparent;
$labelFontsize: font-size();
$railBorderRadius: border-radius('round');
$railColorDisabled: rgba(color('black'), 0.2);
$stepBorderRadius: border-radius('round');
$themeColor: color('secondary');
// $tooltipFontSize: 2.5rem;
$tooltipFontSize: 0;
// $tooltipSize: 2.5rem;
$tooltipSize: 0;
// $tooltipColor: color('black');
$tooltipColor: transparent;

@import './node_modules/vue-slider-component/lib/theme/material.scss';

.m-rangeSlider {
    $block: &;

    &__values {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0 0 gutter('xxs');

        #{$block}--dark & {
            color: color('white');
        }
    }

    &__slider {
        .vue-slider-dot-tooltip-inner {
            background-color: transparent;
            border-radius: border-radius('none');
        }

        .vue-slider-rail {
            #{$block}--dark & {
                background-color: color('white');
            }
        }

        .vue-slider-dot-handle {
            background-color: color('secondary');
            transition: background-color speed() easing();

            &::after {
                background-color: color('white');
                border: 1.5rem solid color('secondary');
                height: 4rem;
                transform: translate(-50%, -50%) scale(1);
                width: 4rem;
                z-index: 0;
            }
        }
    }
}
