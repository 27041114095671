.o-brokerAreaDescription {
    box-shadow: inset 0 0 0 2px;
    display: grid;
    gap: 0 4rem;
    grid-template-columns: repeat(10, 1fr);
    padding: clamp(5rem, 10vw, 7rem) 0;

    > * {
        grid-column: 2/-2;
    }

    @include breakpoint('sm', 'down') {
        h2.o-brokerAreaDescription__headline {
            font-size: font-size('h3');
            line-height: line-height('h3');
        }
    }
}
