/**
 * Video atom
 */

video {
    display: block;
    object-fit: cover;
    overflow: hidden;
    width: 100%;

    &:focus {
        outline: none;
    }

    &[data-aspect="16/9"] {
        aspect-ratio: 16/9;
    }
}

.a-video {
    @extend video;
}
