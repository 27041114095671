/**
 * Mobile Scroll Container
 */

[data-mobile-scroll-grid-shadow] {
    filter: drop-shadow(0 0 6rem rgba(0, 0, 0, 0.1));
}

[data-mobile-scroll-grid] {
    @include breakpoint('sm', 'down') {
        --gap: 3rem;

        display: grid;
        gap: var(--gap);
        grid-auto-columns: calc(94% - (var(--gap) / 2));
        grid-auto-flow: column;
        grid-template-columns: none !important;
        margin: 0 -4rem;
        overflow-x: auto;
        overscroll-behavior: contain;
        padding: 0 4rem;
        scroll-padding: 0 4rem;
        scroll-snap-type: x mandatory;
    }

    > [data-mobile-scroll-grid-item] {
        @include breakpoint('sm', 'down') {
            scroll-snap-align: center;
        }
    }
}
