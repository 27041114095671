/**
 * Broker Search List organism
 */

.o-brokerSearchList {
    $block: &;

    width: 100%;

    &__filters {
        margin-bottom: gutter();

        .u-container {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }

    &__intersectionTarget {
        height: 1px;
    }

    &__noResults {
        margin-top: gutter('sm');
    }

    &__noResultsTitle {
        margin-bottom: gutter('sm');
    }

    &__grid {
        display: grid;
        gap: gutter('xs');
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        margin-bottom: gutter('md');

        @include breakpoint('md') {
            gap: gutter('md');
        }
    }

    &__brokerLinks {
        color: color('secondary');
        margin-bottom: gutter('xxxl');
        width: 100%;

        [screen-reader] &:focus,
        [mouse] &:hover {
            color: color('black');
        }
    }
}
