/**
 * Buttons organism
 */

.o-buttons {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    // font-size: 0;
    justify-content: flex-start;
    // margin: -#{gutter('xxs')};
    gap: gutter('xs');

    &__breaker {
        flex-basis: 100%;
        height: 0;
    }

    &--centered {
        justify-content: center;
    }

    &--stacked {
        align-items: center;
        flex-direction: column;
    }

    &--centered-mobile-only {
        @include breakpoint('md', 'down') {
            justify-content: center;
        }

        .m-button + .m-button--no-min-height {
            margin-top: 2rem;
        }
    }

    &--centered-desktop-only {
        @include breakpoint('md') {
            justify-content: center;
        }
    }
}
