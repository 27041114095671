.o-orderAssessment {
    &__wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        text-align: center;
    }

    &__text {
        margin: gutter('sm') 0 gutter();
    }
}
