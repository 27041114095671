.o-brokerInfoCards {
    display: flex;
    flex-direction: column;
    gap: gutter('xs');

    @include breakpoint('md') {
        gap: gutter();
    }

    &__infoCard {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 26rem;
        text-align: center;
        width: 100%;

        &--whiteShadow {
            background-color: color('white');
            border-radius: border-radius('large');
            box-shadow: box-shadow();
        }

        &--whiteBorder {
            background-color: color('white');
            border: border-width() solid color('black');
        }

        &--green {
            background-color: color('green');
            color: color('white');
        }

        &--greenDark {
            background-color: color('green-dark');
            color: color('white');
        }

        &--paperYellow-light {
            background-color: color('paperYellow-light');
            color: color('black');
        }

        &--hideForMobile {
            display: none;

            @include breakpoint('md') {
                display: flex;
            }
        }
    }

    &__infoCardStars {
        color: orange;

        svg path:not([fill="none"]) {
            fill: currentColor;
        }
    }

    &__infoCardContent {
        padding: gutter() gutter();

        @include owl();
    }

    &__infoCardPropertyDistribution {
        @include owl(1rem);
    }

    &__soMeButtons {
        display: flex;
        justify-content: space-evenly;
    }

    &__soMeButton {
        border-radius: border-radius('round');
        color: color('white');
        height: gutter('lg');
        position: relative;
        width: gutter('lg');

        svg {
            height: inherit;
            width: inherit;
        }
    }
}
