/**
 * Button molecule
 */

.m-button {
    @extend button;

    $block: &;

    --_bgColor: var(--button-bg-clr, var(--secondary));
    --_txtColor: var(--button-txt-clr, var(--white));
    --_bgHoverColor: var(--button-bg-active-clr, var(--secondaryDark));

    align-items: center;
    background-color: var(--_bgColor);
    border: border-width() solid transparent;
    border-radius: border-radius('round');
    color: var(--_txtColor);
    display: inline-flex;
    font-family: font-family();
    font-size: font-size('label');
    font-weight: font-weight('medium');
    gap: 0 gutter('xxs');
    justify-content: space-between;
    line-height: line-height('label');
    min-height: 5rem;
    padding: 0 gutter('sm');
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: speed() easing();
    transition-property: background-color, border-color, box-shadow, color,
        opacity;

    [screen-reader] &:focus,
    [mouse] &:hover {
        background-color: var(--_bgHoverColor);
    }

    &.isLoading,
    &[disabled],
    &.isDisabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &::after {
        animation: buttonLoader 2s ease-in-out infinite;
        animation-fill-mode: both;
        animation-play-state: paused;
        border: border-width() solid currentColor;
        border-color: transparent currentColor transparent currentColor;
        border-radius: border-radius('round');
        content: '';
        display: block;
        height: gutter('xs');
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transition: opacity speed() easing();
        width: gutter('xs');

        @keyframes buttonLoader {
            0% {
                transform: translate(-50%, -50%) rotate(0deg);
            }

            100% {
                transform: translate(-50%, -50%) rotate(360deg);
            }
        }
    }

    &.isLoading::after {
        animation-play-state: running;
        opacity: 1;
    }

    &--large {
        border-radius: border-radius('large');
        min-height: gutter('xxl');
        padding: 0 gutter('xs');
    }

    &--transparent {
        background-color: transparent;
        border: 0;
        color: inherit;
        padding: 0;

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: transparent;
            color: color('secondary:hover');
        }
    }

    &--icon {
        flex: 0 0 auto;
        justify-content: center;
        padding: 0;
        width: 5rem;

        &#{$block}--large {
            padding: 0;
            width: gutter('xxl');
        }
    }

    &--dark {
        background-color: color('black');
        color: color('white');
        overflow: hidden;

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: color('secondary');
        }
    }

    &--link {
        background-color: transparent;
        border: 0;
        border-color: transparent;
        color: color('secondary');
        padding: 0;

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: transparent;
            border-color: transparent;
            color: color('secondary:hover');
        }
    }

    &--no-min-height {
        min-height: auto;
    }

    &--underlined {
        text-decoration: underline;
    }

    &--fullWidth {
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    &--black {
        background-color: color('black');
        color: color('white');
    }

    &--outlined {
        background-color: transparent;
        border-color: currentColor;
        color: inherit;

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: var(--_bgHoverColor);
            border-color: transparent;
            color: var(--_txtColor);
        }
    }

    &--rounded {
        border-radius: border-radius('round');
    }

    &--shadowed {
        background-color: color('white');
        border-color: transparent;
        box-shadow: box-shadow('small');
        color: color('black');
        overflow: hidden;

        [screen-reader] &:focus,
        [mouse] &:hover {
            background-color: color('white');
            box-shadow: box-shadow('small:hover');
            color: color('secondary');
        }
    }

    &__text {
        flex: 1 1 auto;
        padding: 1rem 0;
        text-align: inherit;
        transition: opacity speed() easing();

        #{$block}.isLoading & {
            opacity: 0;
        }

        @include breakpoint('md') {
            white-space: nowrap;
        }

        &--no-padding {
            padding: 0;
        }
    }

    &__textMobileOnly {
        display: block;

        @include breakpoint('md') {
            display: none;
        }
    }

    &__textDesktopOnly {
        display: none;

        @include breakpoint('md') {
            display: block;
        }
    }

    &__icon {
        flex: 0 0 auto;
        transition: opacity speed() easing();

        #{$block}.isLoading & {
            opacity: 0;
        }
    }

    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        flex: 0 0 auto;
        height: 100%;
        margin: 0 gutter('xs');
        width: 7rem;

        @include breakpoint('lg') {
            width: 7.5rem;
        }

        &:first-child {
            margin-left: -#{gutter() / 2};
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--fadeIn {
        opacity: 0;
        animation: buttonFadein 500ms ease-out forwards;
    }
}

@keyframes buttonFadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
