.o-homeEstimateBrokerIntro {
    $imageHeight: 96px;

    &__content {
        &-mapContainer {
            height: 35rem;
            margin: 0 -4rem;

            &--inner {
                height: 100%;
                position: relative;
                width: 100%;
            }

            @include breakpoint('md') {
                background-color: color('green');
                margin: 0;
                padding: 2rem;
            }
        }

        &-broker {
            display: grid;
            gap: 3rem 4rem;

            @include breakpoint('md') {
                grid-template-columns: repeat(12, 1fr, minmax(20px, 1fr));
            }

            @include breakpoint('lg') {
                grid-template-columns: repeat(6, 1fr);
            }

            &--text {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                text-align: center;

                @include breakpoint('md') {
                    grid-column: span 12;
                }

                @include breakpoint('lg') {
                    grid-column: span 6;
                }
            }

            .o-employeeCard--panel {
                margin-top: calc((-3rem * 2 - #{$imageHeight}) / 4);

                .o-employeeCard__wrapper {
                    --portraitSize: #{$imageHeight};

                    column-gap: 3rem;
                }

                @include breakpoint('md') {
                    grid-column: 2 / 12;
                    margin-top: calc((-3rem * 2 - #{$imageHeight}) / 2);
                }

                @include breakpoint('lg') {
                    grid-column: 2 / 6;
                }
            }
        }
    }
}
