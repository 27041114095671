.o-propertyPresentationInNumbers {
    background-color: color('paperYellow-light');
    display: flex;
    justify-content: center;
    margin: 0 -#{$grid-gutter};
    padding-block: gutter('md');
    padding-inline: gutter('md');

    @include breakpoint('md') {
        margin: 0;
        padding: gutter('lg');
    }

    @include breakpoint('lg') {
        padding: gutter('xxxl') gutter('lg');
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $grid-gutter;
        justify-content: center;

        @include breakpoint('lg') {
            max-width: 86rem;
        }
    }

    &__buttons {
        display: flex;
        flex: 0 0 100%;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__button {
        @include breakpoint('sm', 'down') {
            justify-content: center;
            width: 100%;
        }
    }

    &__buttonIcon {
        margin-left: gutter('xxs');
    }

    &__table {
        background-color: color('white');
        flex: 0 0 100%;
        margin: 0;
        word-break: initial;

        @include breakpoint('lg') {
            &--small {
                flex: 0 0 calc(50% - #{$grid-gutter / 2});
            }
        }
    }

    &__tagList {
        display: flex;
        flex-wrap: wrap;
        margin: -#{gutter('xxs') / 2};
    }

    &__tag {
        border: border-width() solid color('gray-light');
        border-radius: border-radius('round');
        height: 3.5rem;
        margin: #{gutter('xxs') / 2};
        padding: 0 gutter('xs');
        text-align: center;
        white-space: nowrap;
    }

    .luxurious & {
        background-color: transparent;

        @include breakpoint('sm', 'down') {
            padding-inline: gutter('xs');
        }

        .m-button--link {
            color: color('black');
        }
    }
}

.luxurious-line {
    display: none;
    position: relative;

    &::before {
        content: '';
        --borderColor: #{color('liebhaver-gold')};

        display: block;
        border-top: solid 2px var(--borderColor);
        position: absolute;
        left: 2rem;
        right: 2rem;
    }

    .luxurious & {
        display: block;
    }
}
