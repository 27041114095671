/**
 * Estimate Buyers organism
 */

.o-estimateBuyers {
    $block: &;

    margin-bottom: 5rem;
    overflow: hidden;

    &__count {
        font-size: 12rem;
        line-height: 1;
        margin-top: 16rem;

        &--zero {
            margin-top: 28rem;
        }

        @include breakpoint('md') {
            margin-top: 26rem;

            &--zero {
                margin-top: 38rem;
            }
        }
    }

    &__heading {
        margin-bottom: gutter('sm');
        margin-top: gutter('xxs');
    }

    &__button {
        margin-bottom: 28rem;
        margin-top: gutter('sm');

        @include breakpoint('md') {
            margin-bottom: 30rem;
        }

        @include breakpoint('lg') {
            margin-bottom: 50rem;
        }
    }

    &__imagesWrapper {
        position: relative;
    }

    &__images {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__image {
        border-radius: 100%;
        position: absolute;

        &:nth-child(1) {
            bottom: 0;
            right: 6rem;

            @include breakpoint('md') {
                bottom: 3rem;
                right: 21rem;
            }

            @include breakpoint('lg') {
                bottom: 0;
            }
        }

        &:nth-child(2) {
            right: -4rem;
            top: 2rem;

            @include breakpoint('md') {
                right: 8rem;
                top: 8rem;
            }

            @include breakpoint('lg') {
                right: -7rem;
                top: 0;
            }
        }

        &:nth-child(3) {
            display: none;

            @include breakpoint('md') {
                bottom: 13rem;
                display: block;
                left: -10rem;
            }

            @include breakpoint('lg') {
                left: -22rem;
                top: 60rem;
            }
        }

        &:nth-child(4) {
            left: -4rem;
            top: 0;

            @include breakpoint('md') {
                left: 3rem;
                top: 20rem;
            }

            @include breakpoint('lg') {
                left: -5rem;
                top: 14rem;
            }
        }

        &:nth-child(5) {
            bottom: 20rem;
            right: -8rem;

            @include breakpoint('md') {
                right: 53rem;
                top: 18rem;
            }

            @include breakpoint('lg') {
                right: 74rem;
                top: 7rem;
            }
        }

        &:nth-child(6) {
            bottom: 8rem;
            left: -6rem;

            @include breakpoint('md') {
                bottom: 46rem;
                left: 77rem;
            }

            @include breakpoint('lg') {
                bottom: 55rem;
                left: 105rem;
            }
        }

        &:nth-child(7) {
            display: none;

            @include breakpoint('md') {
                display: block;
                left: 43rem;
                top: 76rem;
            }

            @include breakpoint('lg') {
                left: 52rem;
                top: 76rem;
            }
        }

        &:nth-child(8) {
            display: none;

            @include breakpoint('md') {
                display: block;
                right: -7rem;
                top: 60rem;
            }

            @include breakpoint('lg') {
                right: -22rem;
                top: 56rem;
            }
        }

        &--small {
            width: 12.5rem;

            @include breakpoint('lg') {
                width: 25rem;
            }
        }

        &--large {
            width: 19.375rem;

            @include breakpoint('lg') {
                width: 38.75rem;
            }
        }
    }
}
