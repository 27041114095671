/**
 * ABC Article Hero organism
 */

.o-abcArticleHero {
    $block: &;

    &__image {
        background-color: color('green');
        margin-bottom: gutter('lg');

        img {
            display: block;
        }

        @include breakpoint('lg') {
            margin-bottom: 0;
        }
    }

    &__content {
        background-color: color('white');
        position: relative;

        @include breakpoint('lg') {
            margin-top: -17rem;
            padding-top: gutter('xxxl');
        }

        &::before {
            @include breakpoint('lg') {
                background-color: color('white');
                bottom: 0;
                box-shadow:
                    -15rem 0 0 0 color('white'),
                    15rem 0 0 0 color('white');
                content: '';
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 0;
            }
        }
    }

    &__title {
        @include lora();

        margin-bottom: gutter('sm');
        position: relative;
    }

    &__description {
        position: relative;
    }
}
