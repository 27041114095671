.o-promoBox {
    $block: &;

    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    padding: gutter('md');
    text-align: center;
    width: 100%;

    @include breakpoint('md') {
        padding: gutter('xs') gutter();
    }

    &--Default {
        background-color: color('white');
        border: border-width() solid color('black');
        color: color('black');
    }

    &--PaperYellowLight {
        background-color: color('paperYellow-light');
        color: color('black');
    }

    &--DarkGreen {
        background-color: color('green-dark');
        color: color('white');
    }

    &--MediumGreen {
        background-color: color('green');
        color: color('white');
    }

    &--PastelGreen {
        background-color: color('greenPastel');
        color: color('black');
    }

    &--Yellow {
        background-color: color('yellow');
        color: color('black');
    }

    &--Orange {
        background-color: color('orange');
        color: color('black');
    }

    &--LightSand {
        background-color: color('sand-light');
        color: color('black');
    }

    &--whiteShadow {
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow('normal');
        color: color('black');
        transition: box-shadow speed() easing();

        &:has(a:hover),
        &:has(a:focus-visible) {
            box-shadow: box-shadow('normal:hover');
        }
    }

    &__illustrationWrapper {
        flex: none;
        width: 100%;
    }

    &__illustration {
        display: block;
        flex: none;
        height: 80px;
        margin: 0 auto;
        max-width: 100%;
        object-fit: cover;
        width: auto;

        @include breakpoint('md') {
            height: 120px;
        }
    }

    &--fullCta {
        position: relative;

        .m-button {
            position: static;
            &::after {
                display: none;
            }
        }
        .m-button::before {
            content: '';
            position: absolute;
            inset: 0;
        }
    }
}
