/**
 * Select atom
 */

select {
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    line-height: normal;
    outline: none;

    &:focus {
        outline: inherit;
    }
}

.a-select {
    @extend select;
}
