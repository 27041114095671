/**
 * Search Filters organism
 */

.o-searchFilters {
    $block: &;

    align-items: flex-start;
    color: color('black');
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin: gutter('xs') 0 0;
    padding-bottom: 13rem;
    position: relative;
    z-index: 8;

    @include breakpoint('md') {
        align-items: center;
        flex-direction: row;
        margin: 0 0 gutter();
        padding-bottom: 0;
        position: sticky;
    }

    &__title {
        margin-bottom: gutter('sm');
    }

    &__groups {
        @include breakpoint('sm', 'down') {
            margin: 0;
        }
    }

    &__group {
        position: relative;

        @include breakpoint('sm', 'down') {
            display: inline-flex;
            margin-bottom: gutter();
            width: 100%;
        }

        &.isOpen {
            z-index: 10;
        }
    }

    &__groupButton {
        @include breakpoint('sm', 'down') {
            display: none;
        }

        #{$block}__group.isOpen & {
            box-shadow: box-shadow('small:hover');
            color: color('secondary');
        }
    }

    &__groupIcon {
        #{$block}__group.isOpen & {
            transform: rotate(180deg);
        }
    }

    &__groupContent {
        align-items: flex-start;
        background-color: color('white');
        border-radius: border-radius('large');
        box-shadow: box-shadow('small');
        cursor: default;
        padding: gutter('sm') gutter() gutter();
        text-align: left;
        width: 100%;

        @include breakpoint('md') {
            display: none;
            left: #{gutter('xxs')};
            position: absolute;
            top: 100%;
            width: 40rem;

            &.isOpen {
                display: block;
            }

            &.openLeft {
                left: auto;
                right: gutter('xxs');
            }

            &.openCenter {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__filter {
        margin: gutter() 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__filterTitle {
        margin: 0 0 gutter('xs');
    }

    &__energyLabels {
        display: flex;
        justify-content: space-between;
        margin-bottom: gutter('xs');
    }
}

.o-searchFiltersButtons {
    align-items: center;
    background-color: color('white');
    bottom: 0;
    box-shadow: box-shadow('small');
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    left: 0;
    margin: 0;
    padding: gutter();
    position: fixed;
    right: 0;
    z-index: 100;

    @include breakpoint('md') {
        display: none;
    }

    &__resetButton {
        transition: opacity speed() easing();

        &.isDisabled {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__approveButton {
        margin: 0;
    }
}
